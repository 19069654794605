import { Span, TableCell } from 'Components'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculatAtip: React.FC<IProps> = ({ products }) => {
	const rootStore = useContext(RootStoreContext)
	const Atip = products?.find(element => element.contratationProductId === "159")
	
	const { familyMembers, setAtip, getAtip, principalUsed } = rootStore.familyStore
	const auxPrice = 400

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []

	familyMembers.map((member) => {

	
		if ( 
		member.wantsAtip === true  
		) {
		 {
				
	
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca david")
				arrayOfMembers.push(memberInfo)
			}
		}
		if (member.wantsAtip === true  ) {
			if (Atip !== undefined ) {
			
                setAtip({
						id: Atip.id,
						productContratationId: Atip.contratationProductId,
						description: Atip.description,
						totalFee: auxPrice,
						name: Atip.name,
						quantity: 1,
						fee: Atip.fee,
						memberInfo: arrayOfMembers,
						type: 'pf'
					})
					console.log("pasa por aca david")
				}
			}
			
	})

	
	

	return (
		<>
			{getAtip().quantity > 0 && (<>
				<TableCell style={{}}>
					<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
					{getAtip().name}
				</TableCell>
				<TableCell style={{ display: 'flex' }}>
					<Span>Descripcion</Span>{' '}<Description>{getAtip().description}</Description>
				</TableCell>
				<TableCell style={{}}>
					{' '}
					<Span>Cantidad </Span>
					{getAtip().quantity}
				</TableCell>
				<TableCell className='border-b border-indigo-500'>
					{' '}
					<Span>Precio </Span>
					CAD {formatter.format(getAtip().totalFee)}
				</TableCell>
				{console.log("pasa por aca david")}
			</>)}
		</>
	)
}

export default observer(CalculatAtip)