/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCAQStudentGov: React.FC<IProps> = ({
	products
}) => {
	const caqGovernmentFee = products.find(element => element.contratationProductId == "59")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed, getCAQStudentGovernmentFee, setCAQStudentGovernmentFee } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (principalUsed()?.inCanada === "no" &&
		member.type == FamilyTypes.principal &&
		member.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.inCanada === "no" &&
			member.type == FamilyTypes.children &&
			(principalUsed()?.purposeVisit === "Trabajo" ||
			principalUsed()?.purposeVisit === "Estudios") &&
			(principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"	||
			principalUsed()?.province === 'Quebec')
			&& member.ageForCAQMinor === false
			&& member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si" &&
			member.type == FamilyTypes.children &&
			(principalUsed()?.typeOfApplication === "Work Visa Extension" ||
				principalUsed()?.typeOfApplication === "Study Visa Extension") &&
			(principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"	||
			principalUsed()?.province === 'Quebec')
			&& member.ageForCAQMinor === false
			&& member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si" &&
			member.type == FamilyTypes.principal &&
			member.wayChooseOnStudy !== 'StudyLessSix' &&
			member.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)" &&
			member.typeOfApplication === "Study Visa Extension") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (caqGovernmentFee !== undefined) {
		setCAQStudentGovernmentFee({
			id: caqGovernmentFee.id,
			productContratationId: caqGovernmentFee.contratationProductId,
			description: caqGovernmentFee.description,
			totalFee: countCAQ * caqGovernmentFee.fee,
			name: caqGovernmentFee.name,
			quantity: countCAQ,
			fee: caqGovernmentFee.price,
			memberInfo: arrayOfMembers,
			type: 'gf'

		})
	}

	return (
		<>
			{getCAQStudentGovernmentFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCAQStudentGovernmentFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCAQStudentGovernmentFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCAQStudentGovernmentFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCAQStudentGovernmentFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCAQStudentGov)
