/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'


interface IProps {
	products: Product[]
}
const CalculateVisitorRecordFederal: React.FC<IProps> = ({
	products
}) => {
	const visitorRecordFederal = products.find(element => element.contratationProductId === "66")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed, getVisitorRecordFederal, setVisitorRecordFederal } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countVisitorRecordFederal = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.type === FamilyTypes.principal &&
			principalUsed()?.typeOfApplication === "Visitor Visa Extension") {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.type === FamilyTypes.children &&
			principalUsed()?.typeOfApplication === "Visitor Visa Extension") {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.type === FamilyTypes.conyuge &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			principalUsed()?.typeOfApplication === "Visitor Visa Extension") {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge &&
			(principalUsed()?.languageRequired === "no" || principalUsed()?.languageRequired === undefined)) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si" &&
			principalUsed()?.languageRequired === "no" &&
			member.type === FamilyTypes.conyuge &&
			(ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined)) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		//caso para niño visitor ETA
		if (member.type === FamilyTypes.children &&
			principalUsed()?.inCanada === "si" &&
			member.ageIndependentChildren === true &&
			(member.appliedBefore === "no" ||
				(member.appliedBefore === "si" &&
					member.validBiometrics === "no")) &&
			principalUsed()?.purposeVisit === "Visitar o transito" &&
			member.typeOfApplication !== "Electronic Travel Authorization - eTA" &&
			ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			member.wantsTemporaryResidenceService === "si" &&
			(principalUsed()?.languageRequired === 'si') &&
			member.type === FamilyTypes.conyuge) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


		if (principalUsed()?.typeOfApplication === "PGWP" &&
			member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.conyuge) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "PGWP" &&
			member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.children) {
			countVisitorRecordFederal++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "PGWP" &&
		member.wantsTemporaryResidenceService === "si" &&
		member.type === FamilyTypes.principal ) {
		countVisitorRecordFederal++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
	}

	})

	if (visitorRecordFederal !== undefined) {
		setVisitorRecordFederal({
			id: visitorRecordFederal.id,
			productContratationId: visitorRecordFederal.contratationProductId,
			description: visitorRecordFederal.description,
			totalFee: visitorRecordFederal.fee * countVisitorRecordFederal,
			name: visitorRecordFederal.name,
			quantity: countVisitorRecordFederal,
			fee: visitorRecordFederal.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getVisitorRecordFederal().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getVisitorRecordFederal().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getVisitorRecordFederal().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getVisitorRecordFederal().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getVisitorRecordFederal().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorRecordFederal)
