
import tw from 'tailwind-styled-components'

interface InputProps {
  size?: any
  hiddenLabel?: boolean
  variant?: string
  defaultValues?: any
  label?: string
}
const Input = tw.input<InputProps>`
  w-full
  text-xs
  rounded
  bg-rosa-100
  text-red-500
  p-2
  shadow-sm
  no-underline
  mb-1
  focus:outline-none



`
export {Input}
