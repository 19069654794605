
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'

import { Description } from 'Components/Tables/description'


const cost = 0.13

const CalculateCanadaTaxFee = () => {
	const rootStore = useContext(RootStoreContext)
	const {
		setTotalCanadaTaxFee,
		getTotalCanadaTaxFee,
		familyMembers,
		getTotalProfessionalFeesClean,
		getDiscount
	} = rootStore.familyStore
	

	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})
	familyMembers.map((member) => {
		if(member.inCanada==="si"){
		
			setTotalCanadaTaxFee({
				product: 'Canada tax fee',
				description: [
					{
						members: 'Canada tax fee',
						price: (getTotalProfessionalFeesClean()-getDiscount().totalFee  )* cost,
					},
				],
				price:  (getTotalProfessionalFeesClean()-getDiscount().totalFee)  * cost,
			})
		}
		})



	return (
		<>
			{getTotalCanadaTaxFee() > 0 ? (
				<>
					{' '}
					<TableCell style={{}}>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>Impuesto
						Canadiense
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>
						<Description>Tarifa adicional del 14.975% si el solicitante principal se encuentra en Canada</Description>
					</TableCell>
					<TableCell style={{}}></TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span>
						CAD {formatter.format(getTotalCanadaTaxFee())}
					</TableCell>{' '}
				</>
			) : null}
		</>
	)
}

export default observer(CalculateCanadaTaxFee)
