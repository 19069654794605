/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateSpouseOpenWorkPermitExtentionON: React.FC<IProps> = ({
	products
}) => {
	const spouseOpentWorkPermit = products.find(element => element.contratationProductId === "28")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSpousePartnerOpenWorkPermitExtentionON, principalUsed, getSpousePartnerOpenWorkPermitExtentionON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.inCanada === 'si' &&
			principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge &&
			principalUsed()?.languageRequired === "si") {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === 'si' &&
			member.type === FamilyTypes.conyuge &&
			member.wantsBP !== "si" &&
			principalUsed()?.typeOfApplication === "PGWP") {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.inCanada === 'si' &&
			member.type === FamilyTypes.principal &&
			principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' &&
			(principalUsed()?.wantsBP === 'si' ||
				principalUsed()?.wantsBP === 'no' ||
				principalUsed()?.wantsBP === undefined)) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.conyuge &&
				principalUsed()?.typeOfApplication === "Work Visa Extension" &&
				member.wantsBP !== "si") {
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})
	if (spouseOpentWorkPermit !== undefined) {
		setSpousePartnerOpenWorkPermitExtentionON({
			id: spouseOpentWorkPermit.id,
			productContratationId: spouseOpentWorkPermit.contratationProductId,
			description: spouseOpentWorkPermit.description,
			totalFee: counter * spouseOpentWorkPermit.fee,
			name: spouseOpentWorkPermit.name,
			quantity: counter,
			fee: spouseOpentWorkPermit.price,
			memberInfo: arrayOfMembers

		})
	}

	return (
		<>
			{getSpousePartnerOpenWorkPermitExtentionON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSpousePartnerOpenWorkPermitExtentionON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSpousePartnerOpenWorkPermitExtentionON().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getSpousePartnerOpenWorkPermitExtentionON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSpousePartnerOpenWorkPermitExtentionON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSpouseOpenWorkPermitExtentionON)
