/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculateDependantPlusFive: React.FC<IProps> = ({
	products
}) => {
	const dependant = products.find(element => element.contratationProductId === "15")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setDependantVisitorPlusFive, principalUsed, getDependantVisitorPlusFive } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countDependantPlusFive = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.purposeVisit === "Residencia Permanente") {
			return
		}
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.inCanada === "no" &&
		principalUsed()?.purposeVisit !== "Trabajo" &&
		member.type == FamilyTypes.children &&
		member.ageForVisitorDependant === false &&
		member.ageIndependentChildren === false) {
			if (principalUsed()?.typeOfApplication !== "Visa de tránsito por Canadá" &&
			principalUsed()?.typeOfApplication !== "Visa de visitante" &&
			principalUsed()?.typeOfApplication !== "Electronic Travel Authorization - eTA") {
					countDependantPlusFive++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada === "no" &&
		principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type == FamilyTypes.children
				/* && member.wantsBP === "no" */
				&& member.ageForVisitorDependant === false
				&& member.ageIndependentChildren === false
				/* &&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) */)
				|| (
					member.type == FamilyTypes.children
					&& member.ageForVisitorDependant === false
					&& member.ageIndependentChildren === false
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined
				)
				|| (member.type == FamilyTypes.children
					&& member.ageForVisitorDependant === false
					&& member.ageIndependentChildren === false
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === principalUsed()?.countryOfCitizenship?.toLocaleLowerCase())) === undefined
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()))
				)) {
					console.log('el console log')
				countDependantPlusFive++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

	/* 	if (principalUsed()?.inCanada==="no" &&
	member.type === FamilyTypes.children &&
	ember.ageIndependentChildren === false &&
	member.wantsBP !== "si" &&
	principalUsed()?.typeOfApplication === "PGWP" &&
	member.ageForVisitorDependant === false) {
			countDependantPlusFive++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
 */
	})
	if (dependant !== undefined) {
		setDependantVisitorPlusFive({
			id: dependant.id,
			productContratationId: dependant.contratationProductId,
			description: dependant.description,
			totalFee: countDependantPlusFive * dependant.fee,
			name: dependant.name,
			quantity: countDependantPlusFive,
			fee: dependant.price,
			memberInfo: arrayOfMembers

		})
	}

	return (
		<>
			{getDependantVisitorPlusFive().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getDependantVisitorPlusFive().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getDependantVisitorPlusFive().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getDependantVisitorPlusFive().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getDependantVisitorPlusFive().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantPlusFive)
