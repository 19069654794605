/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateWorkPermitExemptONInsideCanada: React.FC<IProps> = ({
	products
}) => {
	const wpEx = products.find(element => element.contratationProductId === "12")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed, setWorkPermitExemptONDetailsInsideCanada, getWorkPermitExemptONDetailsInsideCanada } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if ((member.type === FamilyTypes.principal &&
			principalUsed()?.inCanada === "si" &&
			principalUsed()?.typeOfClosedWorkPermit	=== "REQUIERE de una evaluación de impacto del mercado laboral"	
			)) {

			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (wpEx !== undefined) {
		setWorkPermitExemptONDetailsInsideCanada({
			id: wpEx.id,
			productContratationId: wpEx.contratationProductId,
			description: wpEx.description,
			totalFee: countwp * wpEx.fee,
			name: wpEx.name,
			quantity: countwp,
			fee: wpEx.price,
			memberInfo: arrayOfMembers

		})
	}
	return (
		<>
			{getWorkPermitExemptONDetailsInsideCanada().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getWorkPermitExemptONDetailsInsideCanada().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getWorkPermitExemptONDetailsInsideCanada().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getWorkPermitExemptONDetailsInsideCanada().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getWorkPermitExemptONDetailsInsideCanada().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateWorkPermitExemptONInsideCanada)
