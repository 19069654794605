/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateOpenWorkPermitVulnerable: React.FC<IProps> = ({
	products
}) => {
	const openWorkPermit = products.find(element => element.contratationProductId === "133")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setOpenWorkPermitVulnerable, principalUsed, getOpenWorkPermitVulnerable } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let count = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo Trabajador Vulnerable' && member.type===FamilyTypes.principal) {
			count++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})

	if (openWorkPermit !== undefined) {
		setOpenWorkPermitVulnerable({
			id: openWorkPermit.id,
			productContratationId: openWorkPermit.contratationProductId,
			description: openWorkPermit.description,
			totalFee: count * openWorkPermit.fee,
			name: openWorkPermit.name,
			quantity: count,
			fee: openWorkPermit.price,
			memberInfo: arrayOfMembers,
			type: 'pf'
		})
	}

	return (
		<>
			{getOpenWorkPermitVulnerable().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getOpenWorkPermitVulnerable().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getOpenWorkPermitVulnerable().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getOpenWorkPermitVulnerable().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getOpenWorkPermitVulnerable().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateOpenWorkPermitVulnerable)
