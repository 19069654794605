/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateVisitorFederalFee: React.FC<IProps> = ({
	products
}) => {
	const visitorVisaFederal = products.find(element => element.contratationProductId === "18")
	const rootStore = useContext(RootStoreContext)
	const { familyMembers, setVisitorFederal, principalUsed, getVisitorFederal } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []
	let countVisitorVisas = 0
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (member.typeOfApplication === "Visa de visitante" &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)
		) {
			{
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
		if (principalUsed()?.typeOfApplication === "Visa de visitante" ||
				principalUsed()?.typeOfApplication === "Electronic Travel Authorization - eTA") {
				if (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
					&&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
					member.type === FamilyTypes.conyuge) {
					countVisitorVisas++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
				}
				if (member.type === FamilyTypes.children &&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
					member.ageIndependentChildren === false &&
					(member.ageForVisitorDependant === false) &&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					countVisitorVisas++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
				}
			}

		if (member.type === FamilyTypes.children &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			member.ageIndependentChildren === false &&
			(ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined &&
			member.ageForVisitorDependant === true &&
			principalUsed()?.purposeVisit === "Estudios") {
			{
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)") {
			if ((ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined
				&& principalUsed()?.inCanada === "no" &&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
				member.type === FamilyTypes.conyuge) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.scenery === "Escenario 2" ||
			principalUsed()?.scenery === "Escenario 3") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
				&&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
				member.type === FamilyTypes.conyuge) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type == FamilyTypes.children &&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)
				&& member.ageIndependentChildren === false
				&& member.wantsBP === "no"
				&& (member.ageForVisitorDependant || member.ageForVisitorDependant === undefined)
				&&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()))
				||
				(member.type == FamilyTypes.children &&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
					member.ageIndependentChildren === false
					&& (member.ageForVisitorDependant || member.ageForVisitorDependant === undefined)
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined))) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			member.type === FamilyTypes.children &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			member.wantsTemporaryResidenceService === 'si') {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			member.type === FamilyTypes.principal &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			principalUsed()?.wantsTemporaryResidenceService === 'si') {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


		if (principalUsed()?.typeOfApplication === 'Visa Renovation' &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		
		if (principalUsed()?.inCanada === "no" &&
			principalUsed()?.languageRequired === "no" &&
			member.type === FamilyTypes.conyuge &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			(ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined)) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			member.type === FamilyTypes.conyuge &&
			principalUsed()?.languageRequired === 'si' &&
			member.wantsTemporaryResidenceService === 'si') {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


		if (member.type === FamilyTypes.children &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			member.ageIndependentChildren === false &&
			principalUsed()?.inCanada === "si" &&
			member.wantsBP !== "si" &&
			principalUsed()?.typeOfApplication === "PGWP" &&
			member.ageForVisitorDependant === true) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.children &&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
				member.ageIndependentChildren === false &&
				principalUsed()?.typeOfApplication === "Work Visa Extension" &&
				member.wantsBP !== "no" &&
				member.ageForVisitorDependant === true) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.typeOfApplication === "Work Visa Extension" &&
			member.wantsTemporaryResidenceService === "si") {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (visitorVisaFederal !== undefined) {
		setVisitorFederal({
			id: visitorVisaFederal.id,
			productContratationId: visitorVisaFederal.contratationProductId,
			description: visitorVisaFederal.description,
			totalFee: countVisitorVisas < 5 ? countVisitorVisas * visitorVisaFederal.fee : 500,
			name: visitorVisaFederal.name,
			quantity: countVisitorVisas,
			fee: visitorVisaFederal.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}


	return (
		<>
			{getVisitorFederal().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getVisitorFederal().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getVisitorFederal().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getVisitorFederal().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getVisitorFederal().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorFederalFee)
