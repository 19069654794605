/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{ useState,useEffect } from 'react';
import { Select } from 'Components/Select';


interface IProps {
	label: string;
	value: string;
	handleChange: (event: any) => void;
	className?: string;
}

interface IProvinces {
	name: string;
}

const ProvinceSelector: React.FC<IProps> = ({ value,label,handleChange }) => {
	const provinces: IProvinces[] = [
		{ name: 'Alberta' },
		{ name: 'British Columbia' },
		{ name: 'Manitoba' },
		{ name: 'Newfoundland and Labrador' },
		{ name: 'New Brunswick' },
		{ name: 'Nova Scotia' },
		{ name: 'Nunavut' },
		{ name: 'Ontario' },
		{ name: 'Prince Edward Island' },
		{ name: 'Quebec' },
		{ name: 'Saskatchewan' },
		{ name: 'Yukon' },
	];

	const [showPopup,setShowPopup] = useState(false);

	useEffect(() => {
		if (value === 'Quebec') {
			setShowPopup(true);
		} else {
			setShowPopup(false);
		}
	},[value]);





	return (
		<>
			<div>
				<Select
				className='custom-input'
					id="Provincia"
					name="Provincia"
					defaultValues={value}
					style={{ backgroundColor: '#fff2f3 ' }}
					labelId="demo-simple-select-label"
					value={value}
					label={label}
					onChange={handleChange}
				>
					<option value="">Seleccione</option>
					{provinces.map((provinces: IProvinces) => (
						<option key={provinces.name} value={provinces.name}>
							{provinces.name}
						</option>
					))}
				</Select>


			</div>
		</>
	);
};

export { ProvinceSelector };