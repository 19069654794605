/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculatePostGraduateWorkPermitExtention: React.FC<IProps> = ({
	products
}) => {
	const PGWPEx = products.find(element => element.contratationProductId == "35")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setPGWPEx,getPGWPEx } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let count = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (member.casePGWP==="caso 2" && member.type===FamilyTypes.principal) {
			count++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	})
	if (PGWPEx !== undefined) {
		setPGWPEx({
			id: PGWPEx.id,
			productContratationId: PGWPEx.contratationProductId,
			description: PGWPEx.description,
			totalFee: count * PGWPEx.fee,
			name: PGWPEx.name,
			quantity: count,
			fee: PGWPEx.price,
			memberInfo:arrayOfMembers
		})
	}

	return (
		<>
			{getPGWPEx().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPGWPEx().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getPGWPEx().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getPGWPEx().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPGWPEx().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePostGraduateWorkPermitExtention)
