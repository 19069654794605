/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, Input} from 'Components'
import React, {useEffect} from 'react'

import ListProduct from './ListProducts'
import {Product} from '../../../modules/products/_models'
import {getProducts} from '../../../modules/products/_requests'
import {useQuery} from '@tanstack/react-query'

interface IProps {
  product: Product | null
  setProduct: (product: Product) => void
}

const ProductSearch: React.FC<IProps> = ({product, setProduct}) => {
  //console.log('🚀 ~ file: ProductSearch.tsx ~ line 16 ~ product', product)
  const [name, setName] = React.useState('')

  const {data, isLoading, refetch} = useQuery(['listProducts'], () => getProducts(`name=${name}`))

  useEffect(() => {
    refetch()
  }, [name])

  const handleProductelected = (price: Product) => {
    setProduct(price)
  }

  return (
    <div>
      <Box>
        <Input
          hiddenLabel
          id='filled-hidden-label-small'
          variant='filled'
          size='small'
          value={name}
          onChange={(event: any) => setName(event.target.value)}
        />
        {data && (
          <ListProduct
            product={data}
            onProductClick={handleProductelected}
            defaultProduct={product}
          />
        )}
      </Box>
    </div>
  )
}

export default ProductSearch
