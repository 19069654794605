/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import {action, makeObservable, observable} from 'mobx'
import {saveFlow, updateFlow} from '../core/_requests'

import {Flow} from '../core/_models'
import {RootStore} from '../../../stores/rootStore'
import Swal from 'sweetalert2'

export default class FlowStore {
  rootStore: RootStore

  @observable flow: Flow = {
    nodes: [{id: 'node_0', type: 'start', position: {x: 150, y: 25}}],
    data: {node_0: {id: 'node_0', type: 'start', data: {}}},
    name: '',
  }
  @observable currentFlow: {} | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action saveFlow = async (flow: Flow) => {
    try {
      const result = await saveFlow(flow)
      if (result) {
        this.flow = result
        this.saveOk()
      }
    } catch (error) {
      this.saveError('')
    }
  }

  @action updateFlow = async (flow: Flow) => {
    try {
      const result = await updateFlow(flow)

      if (result) {
        this.saveOk()
      }
    } catch (error) {
      this.saveError('')
    }
  }

  @action setCurrentFlow = (currentFlow: Flow) => {
    this.currentFlow = currentFlow
  }

  @action setFlow = (flow: Flow) => {
    this.flow = flow
  }

  @action saveOk = () => {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Guardado correctamente',
      showConfirmButton: false,
      timer: 1500,
    })
  }

  @action saveError = (error: any) => {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Error al guardar',
      showConfirmButton: false,
      timer: 1500,
    })
  }
}
