import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import translationEN from './Locales/en/translationEN.json'
import translationES from './Locales/es/translationES.json'
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

const searchLanguage = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)

  const lang = params.get('language')
  if (lang != undefined) {
    return lang
  } else {
    return 'es'
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: searchLanguage(),
  keySeparator: false,
  interpolation: {escapeValue: false},
})

export default i18n
