/* eslint-disable @typescript-eslint/no-empty-function */
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import FormBuilder from '../FormBuilder'
import IfPropertyEditor from '../ReactFlow/PropertyPanel/IfPropertyEditor'
import PricePropertyEditor from '../ReactFlow/PropertyPanel/PricePropertyEditor'
import Shopping from 'FormBuilder/InfoDisclaimer/Shopping'
import PreEvaluation from 'pre-evaluation/preEvaluation'
import EmbedContainer from 'pre-evaluation/reviewsEmbed'




const AppRoutes = () => {
	return (
		<BrowserRouter>


			<Routes>
				{/* <Route path='/' element={<App />} /> */}
				<Route
					path='/if'
					element={
						<IfPropertyEditor
							codeData={{ id: 'node_8A_16AgEcJOo4a6gcSjUf',type: 'if',data: {} }}
							updateData={() => { }}
							nodes={[
								{ id: 'node_0',type: 'start',position: { x: 150,y: 25 } },
								{
									id: 'node_8A_16AgEcJOo4a6gcSjUf',
									type: 'if',
									position: { x: 71.41249084472656,y: 127.5999984741211 },
									data: {},
								},
							]}
							allCodeData={undefined}
						/>
					}
				/>
				<Route path='/form' element={<FormBuilder />} />
				<Route path='/flow/:id' element={<FormBuilder />} />
				<Route path='/employer' element={<Shopping  />} />
				<Route path='/pre-evaluation' element={<PreEvaluation />} />
				<Route path='/embed' element={<EmbedContainer />} />
				<Route 
					path='/uploader'
					element={
						<PricePropertyEditor
							codeData={{
								type: 'price',
							}}
							updateData={() => { }}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default AppRoutes
