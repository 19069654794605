/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateVisitorVisa: React.FC<IProps> = ({
	products
}) => {
	const visitorVisa = products.find(element => element.contratationProductId === "6")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setVisitorVisa, principalUsed, getVisitorVisaDetails } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countVisitorVisas = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (!member.validEta &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			!member.isSuperVisa &&
			!member.isTransitVisa) {
			if ((member.typeOfApplication === "Visa de visitante" && member.lessThanSixMonths === 'no')) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}

			if (principalUsed()?.typeOfApplication === "Visa de visitante" ||
				principalUsed()?.typeOfApplication === "Electronic Travel Authorization - eTA") {
				if (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
					&&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
					member.type === FamilyTypes.conyuge) {
					countVisitorVisas++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
				}
				if (member.type === FamilyTypes.children &&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
					member.ageIndependentChildren === false &&
					(member.ageForVisitorDependant === false) &&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					countVisitorVisas++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
				}
			}

			if (member.type === FamilyTypes.children &&
				member.ageIndependentChildren === true &&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				principalUsed()?.purposeVisit === "Visitar o transito" &&
				member.typeOfApplication !== "Electronic Travel Authorization - eTA" &&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada === "no" &&
			principalUsed()?.languageRequired === "no" &&
			member.type === FamilyTypes.conyuge &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			(ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined)) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "no" &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
			principalUsed()?.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
				&&
				member.type === FamilyTypes.conyuge) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.scenery === "Escenario 2" ||
			principalUsed()?.scenery === "Escenario 3") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
				&&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
				member.type === FamilyTypes.conyuge) {
				countVisitorVisas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})

	if (visitorVisa !== undefined) {
		setVisitorVisa({
			id: visitorVisa.id,
			productContratationId: visitorVisa.contratationProductId,
			description: visitorVisa.description,
			totalFee: countVisitorVisas * visitorVisa.fee,
			name: visitorVisa.name,
			quantity: countVisitorVisas,
			fee: visitorVisa.price,
			memberInfo: arrayOfMembers
		})
	}
	return (
		<>
			{getVisitorVisaDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getVisitorVisaDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getVisitorVisaDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getVisitorVisaDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getVisitorVisaDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorVisa)
