/* eslint-disable @typescript-eslint/ban-types */
import {
    Flow
  } from '../core/_models'
  import axios, {AxiosResponse} from 'axios'
  
  const API_URL = process.env.REACT_APP_API_URL
  
  //const FLOW_URL = `http://localhost:4000/api/v1/contractstemps`
  const FLOW_URL = `${API_URL}/contractstempsemployers`
  const saveEmployer = (body: {}): Promise<Flow> => {
    return axios.post(`${FLOW_URL}`, body).then((response: AxiosResponse) => {
      return response.data as Flow
    })
  }
  
  export {saveEmployer}
  