/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateQCSponsorship: React.FC<IProps> = ({
	products
}) => {
	const QCSponsorship = products.find(element => element.contratationProductId == "134")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setQCsponsorship, getQCsponsorship, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member,index) => {
		if (((member.phase==='fase1' && principalUsed()?.province==="Quebec" ) ||  principalUsed()?.phase==='ambas')  && member.typeOfApplication === 'Patrocinio familiar') {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
     /*    if (((member.phase==='fase1' && principalUsed()?.province==="Quebec" ) ||  principalUsed()?.phase==='ambas')  && principalUsed()?.typeOfApplication === 'Patrocinio familiar' && member.type===FamilyTypes.conyuge) {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	} */
	})
	if (QCSponsorship !== undefined) {
		setQCsponsorship({
			id: QCSponsorship.id,
			productContratationId: QCSponsorship.contratationProductId,
			description: QCSponsorship.description,
			totalFee: countwp * QCSponsorship.fee,
			name: QCSponsorship.name,
			quantity: countwp,
			fee: QCSponsorship.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getQCsponsorship().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getQCsponsorship().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getQCsponsorship().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getQCsponsorship().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getQCsponsorship().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateQCSponsorship)
