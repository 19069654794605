/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, Fragment, useContext } from 'react'


import DialogComponent from 'Components/Dialog'
import { IFamilyGroup, IFamilys } from '../types'

import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'



import VisaQuestions from './VisaQuestions/VisaQuestions'
const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '800px',
	minHeight: '80%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
}

interface IProps {
	familyGroup: IFamilyGroup
	open: boolean
	onClose: () => void
	onSubmit: () => void
	setOpen: (open: boolean) => void
	family: IFamilys
	edit: boolean
	setEdit: React.Dispatch<React.SetStateAction<boolean>>
}

const MembersData: FC<IProps> = ({ open, setOpen, onSubmit, family, familyGroup, edit, setEdit }) => {
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const rootStore = useContext(RootStoreContext)
	const { getVisaTypeFromSurvey } = rootStore.familyStore

	return (
		<>
			<DialogComponent
				isOpen={open}
				closeModal={handleClose}
				title={''}
				closeButtonLabel={'Guardar'}
				closeButton={false}
			>

				<VisaQuestions closeModal={handleClose} edit={edit} setEdit={setEdit} />


			</DialogComponent>{' '}
		</>
	)
}

export default observer(MembersData)
