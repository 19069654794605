const defaultSurveyCSS = {
  header: 'bg-primary text-white p-3',
  body: 'bg-light',
  completedPage: 'p-3',
}

const defaultSurveyDATA = {
  Symptoms: ['item1', 'item3', 'item5'],
  Household: true,
  Notification: true,
  Travel: true,
}
const defaultSurveyJSON = {}

const defaultSurveyConfig = {
  defaultSurveyCSS,
  defaultSurveyDATA,
  defaultSurveyJSON,
}

export default defaultSurveyConfig
