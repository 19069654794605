/* eslint-disable @typescript-eslint/no-unused-vars */


export interface IallSources {
  source: any
  sourceHandle: any
  target: any
  targetHandle: any
  type: any
  complete: boolean
}
export const getStartNode = (flow: any) => {
  const nodes = flow.filter((node: any) => node.source === 'node_0')
  return nodes
}

export const setAllSources = (nodes: any[]) => {
  const allSources: IallSources[] = []
  if (nodes && nodes.length > 0) {
    const sources = nodes.forEach((node: any) => {
      if (node.type === 'step') {
        allSources.push({
          source: node.source,
          sourceHandle: node.sourceHandle,
          target: node.target,
          targetHandle: node.targetHandle,
          type: node.type,
          complete: false,
        })
      }
    })
  } else {
    console.error("El array de nodes es undefined o está vacío.");
  }

  return allSources
}

export const getCurrentNode = (nodes: any[], target: string) => {
  //console.log('🚀 ~ file: index.ts ~ line 35 ~ getCurrentNode ~ nodes', nodes)
  const node = nodes.filter((node: any) => node.id === target)
  return node
}

export const getNextNode = (sources: any, target: string, ifModule: boolean | null) => {
  return sources.filter((source: any) => {
    if (source.source === target) {
      if (ifModule !== null) {
        if (source.sourceHandle === `${ifModule}`) {
          return source
        }
      } else {
        return source
      }
    }
  })
}

export const getFamilyNode = (sources: any) => {
  return sources.find((source: any) => source.type === 'family')
}

export const getFormNode = (sources: any) => {
  return sources.find((source: any) => source.type === 'form')
}

export const getNodeById = (nodes: any, id: string) => {
  return nodes.filter((node: any) => node.id === id)
}

export const getNodeByType = (nodes: any, type: string) => {
  return nodes.filter((node: any) => node.type === type)
}

export const getCodeData = (codedata: any, target: string) => {
  return codedata[target].data
}
