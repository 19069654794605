// EmbedContainer.tsx
import React from 'react';
import GoogleBusinessReviews from './GoogleBussinesReviews';


const EmbedContainer: React.FC = () => {
  return <GoogleBusinessReviews />;
};

export default EmbedContainer;
