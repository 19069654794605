/* eslint-disable no-empty-pattern */
import { FamilyTypes} from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateLMIAExExtentionON: React.FC<IProps> = ({
	products
}) => {
	const lmiaExExON = products.find(element => element.contratationProductId == "10")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setLMIAExExtentionON,getLMIAExExtentionON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let count= 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
       if(member.inCanada==="si"){
		if(member.type===FamilyTypes.principal && member.typeOfClosedWorkPermit==="ESTÁ EXENTA de una evaluación de impacto del mercado laboral"/*  && member.wantsBP==="no" */){
            count++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
        }
	}
	})
	if (lmiaExExON !== undefined) {
		setLMIAExExtentionON({
			id: lmiaExExON.id,
			productContratationId: lmiaExExON.contratationProductId,
			description: lmiaExExON.description,
			totalFee: count * lmiaExExON.fee,
			name: lmiaExExON.name,
			quantity: count,
			fee: lmiaExExON.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getLMIAExExtentionON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getLMIAExExtentionON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getLMIAExExtentionON().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getLMIAExExtentionON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getLMIAExExtentionON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateLMIAExExtentionON)
