/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculatePartnerWorkPermitPOE: React.FC<IProps> = ({
	products
}) => {
	const visitorVisa = products.find(element => element.contratationProductId == "17")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setPartnerOpenWorkPermitPOE,getPartnerOpenWorkPermitPOE,principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
		if(principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' && (principalUsed()?.wantsBP === 'si')){
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type === FamilyTypes.conyuge
				&& ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
				&& member.wantsBP === "si")) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (member.type===FamilyTypes.conyuge && member.wantsBP==="si" && principalUsed()?.typeOfApplication==="PGWP") {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if(principalUsed()?.inCanada==="si"){
			if(member.type===FamilyTypes.conyuge && principalUsed()?.typeOfApplication==="Work Visa Extension" && member.wantsBP==="si"){
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})
	if (visitorVisa !== undefined) {
		setPartnerOpenWorkPermitPOE({
			id: visitorVisa.id,
			productContratationId: visitorVisa.contratationProductId,
			description: visitorVisa.description,
			totalFee: countwp * visitorVisa.fee,
			name: visitorVisa.name,
			quantity: countwp,
			fee: visitorVisa.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getPartnerOpenWorkPermitPOE().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPartnerOpenWorkPermitPOE().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getPartnerOpenWorkPermitPOE().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getPartnerOpenWorkPermitPOE().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPartnerOpenWorkPermitPOE().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePartnerWorkPermitPOE)
