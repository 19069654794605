/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateQCSponsorshipFee: React.FC<IProps> = ({
	products
}) => {
	const QCSponsorshipFee = products.find(element => element.contratationProductId == "125")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setQCsponsorshipFee, getQCsponsorshipFee, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if ((member.phase==='fase1'||member.phase==='ambas') && member.province==="Quebec"    && principalUsed()?.typeOfApplication === 'Patrocinio familiar' ) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
		/* if ((principalUsed()?.phase==='fase1'|| principalUsed()?.phase==='ambas') && principalUsed()?.province==="Quebec"   && member.type===FamilyTypes.conyuge &&  principalUsed()?.typeOfApplication === 'Patrocinio familiar') {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	} */
	})
	if (QCSponsorshipFee !== undefined) {
		setQCsponsorshipFee({
			id: QCSponsorshipFee.id,
			productContratationId: QCSponsorshipFee.contratationProductId,
			description: QCSponsorshipFee.description,
			totalFee: countwp * QCSponsorshipFee.fee,
			name: QCSponsorshipFee.name,
			quantity: countwp,
			fee: QCSponsorshipFee.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}
	return (
		<>
			{getQCsponsorshipFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getQCsponsorshipFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getQCsponsorshipFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getQCsponsorshipFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getQCsponsorshipFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateQCSponsorshipFee)
