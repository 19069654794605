
import tw from 'tailwind-styled-components'

const H3 = tw.h3`
    mb-1
    text-xs
    font-semibold

`
export {H3}
