import { Uploaders } from "modules/uploaders/core/_models"

// create interface FamilyTypes with enum option  father and mother and children
export enum FamilyTypes {
  principal = 'Solicitante principal',
  conyuge = 'conyuge',
  children = 'hija(o)',
  grandchildren = 'nieta(o)',
  otros = 'otros',
}

export interface IFamilys {
  JSONAnswers?: string
  //
  id: string
  type: FamilyTypes
  name: string
  lastname: string
  age: number
  birthday: Date
  nationality: string
  hasBiometrics?: boolean
  biometrics?: File
  visaType: string
  valid?: boolean
  principal?: boolean
  eta?: string
  etaCountry?: boolean | false
  etaRejection?: string
  visaEU?: string
  groupId: string
  etaImmiland?: string
  validEta?: boolean | false
  canadianVisaRejection?: string
  email?: string
  address?: string
  phoneNumber?: string
  birthdayFormatted?: string
  monthDifference: number
  ageForBiometrics?: boolean
  //study
  quebec?: string

  principalHasPathway?: string
  HasCaq?: string
  caqDocument?: File
  workPermit?: boolean | false
  normalVisitorVisaLogic?: boolean | false
  validPrincipalCaq?: boolean | false
  validDependantStudent?: boolean | false
  validAccompainedMinor?: boolean | false
  visaTypeFromSurvey?: string | undefined
  hasLoa?: string
  loaDocument?: File
  //Work
  typeWorkPermit?: string
  partnerHasWorkStudyPermit?: string
  borderPackage?: boolean | false
  dliPGWP?: string
  aplicationType?: string
  typeCloseWorkPermit?: string
  hasLMIAAlready?: string
  ftaCountry?: boolean | false
  //
  typeOfProcedure?: string
  NOC?: string
  //previous client
  isPreviousClient?: string
  previousContractDocument?: File
  previousContractNumber?: string
  //inside Canada Visitor extension
  insideCanada?: string
  wantsVisitorExtension?: string
  previousPermitDocument?: File

  //inside Canada Study extension
  wantsStudyExtension?: string
  studentDirectStream?: string
  optionOfExtension?: string

  //inside canada work extension
	wantsWorkExtension?: string
	documents:Uploaders[]
}

export interface IFamilyGroup {
  id: string
  index: number
  family: IFamilys[] | []
}

export interface IDescription {
  members: string
  price: number
}

export interface IDetails {
  id?: string
  productId?: string
  product: string
  description: IDescription[]
  price: number
}

export interface IEducationSurvey {
  whenarrive?: string
  certifications?: string
  languagelevel?: string
  money?: string
}

export const initialDetailsEducationSurvey = {
  whenarrive: '',
  certifications: '',
  languagelevel: '',
  money: '',
}
export const initialDetails = {
  id: '',
  productId: '',
  product: '',
  description: [
    {
      members: '',
      price: 0,
    },
  ],
  price: 0,
}

export const initialValues: IFamilys = {
  id: '',
  name: '',
  type: FamilyTypes.principal,
  lastname: '',
  nationality: '',
  age: -1,
  hasBiometrics: false,
  birthday: new Date(),
  visaType: '',
  groupId: '',
	monthDifference: -1,
	documents:[{ name: "biometrics",maxFiles: 0,description: "",active: true,nameOfTheDocumentSubmitted:'' }]
}
