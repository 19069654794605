
import tw from 'tailwind-styled-components'

const BoxModal = tw.div`
  sm:w-96
  px-3
  mb-3
  md:mb-3
  w-full
`
export {BoxModal}
