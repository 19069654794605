/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateWorkPermitIncludesExtentionFederalFee: React.FC<IProps> = ({
	products
}) => {
	const workPermitIncludesExtentionFederalFee = products.find(element => element.contratationProductId === "60")
	const rootStore = useContext(RootStoreContext)
	const { familyMembers, setWorkPermitIncludesExtentionFederalFee, principalUsed, getWorkPermitIncludesExtentionFederalFee } = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countWorkPermitIncludesExtentionFederalFee = 0
	const arrayOfMembers: any[] = []

	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.principal &&
			(principalUsed()?.sponsorship === 'spouseOrPartner' || principalUsed()?.sponsorship === 'spousePartnerShildren') &&
			principalUsed()?.addOpenWork === 'si') {
			countWorkPermitIncludesExtentionFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
			principalUsed()?.languageRequired === 'no' &&
			principalUsed()?.scenery === 'Escenario 1') ||
			(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
				principalUsed()?.languageRequired === 'no' &&
				principalUsed()?.scenery === 'Escenario 1')) {
			if (member.type == FamilyTypes.conyuge) {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
			principalUsed()?.languageRequired === 'si') ||
			(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
				principalUsed()?.languageRequired === 'si')) {
			if (member.type == FamilyTypes.conyuge) {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (member.purposeVisit === "Trabajo") {
			if ((member.type === FamilyTypes.principal &&
				member.typeOfClosedWorkPermit === "ESTÁ EXENTA de una evaluación de impacto del mercado laboral"
				/* && (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined */)
				||
				(member.type === FamilyTypes.principal &&
					member.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral"
					/* && (ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined */)
				||
				(member.type === FamilyTypes.principal &&
					principalUsed()?.typeOfApplication === "PGWP")
				||
				((member.type === FamilyTypes.principal
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
					&& member.wantsBP === "no"))
				||
				((member.type === FamilyTypes.principal
					&& member.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá'))
			) {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type === FamilyTypes.conyuge
				&& ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
				||
				(member.type === FamilyTypes.conyuge
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()))
				||
				member.type === FamilyTypes.conyuge && principalUsed()?.typeOfApplication === "PGWP"
				||
				(((member.type === FamilyTypes.conyuge
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
					&& (ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === principalUsed()?.countryOfCitizenship?.toLocaleLowerCase())) === undefined))

				)) {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)

			}
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge &&
			principalUsed()?.languageRequired === "si") {
			countWorkPermitIncludesExtentionFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.conyuge &&
				principalUsed()?.typeOfApplication === "Work Visa Extension") {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.principal &&
				principalUsed()?.typeOfApplication === "PGWP") {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.conyuge &&
				principalUsed()?.typeOfApplication === "PGWP") {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.principal &&
				principalUsed()?.typeOfApplication === "Work Visa Extension") {
				countWorkPermitIncludesExtentionFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})

	if (workPermitIncludesExtentionFederalFee !== undefined) {
		setWorkPermitIncludesExtentionFederalFee({
			id: workPermitIncludesExtentionFederalFee.id,
			productContratationId: workPermitIncludesExtentionFederalFee.contratationProductId,
			description: workPermitIncludesExtentionFederalFee.description,
			totalFee: countWorkPermitIncludesExtentionFederalFee * workPermitIncludesExtentionFederalFee.fee,
			name: workPermitIncludesExtentionFederalFee.name,
			quantity: countWorkPermitIncludesExtentionFederalFee,
			fee: workPermitIncludesExtentionFederalFee.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getWorkPermitIncludesExtentionFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getWorkPermitIncludesExtentionFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>
						<Description>{getWorkPermitIncludesExtentionFederalFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getWorkPermitIncludesExtentionFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getWorkPermitIncludesExtentionFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateWorkPermitIncludesExtentionFederalFee)
