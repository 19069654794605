/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculatePostGraduationWorkPermitPOE: React.FC<IProps> = ({
	products
}) => {
	const caqMinor = products.find(element => element.contratationProductId == "34")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setPGWPPOE,getPGWPPOE } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let count = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
		if (member.wantsBP==="si" && member.type===FamilyTypes.principal && member.typeOfApplication==="PGWP") {
			count++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (caqMinor !== undefined) {
		setPGWPPOE({
			id: caqMinor.id,
			productContratationId: caqMinor.contratationProductId,
			description: caqMinor.description,
			totalFee: count * caqMinor.fee,
			name: caqMinor.name,
			quantity: count,
			fee: caqMinor.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getPGWPPOE().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPGWPPOE().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getPGWPPOE().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getPGWPPOE().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPGWPPOE().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePostGraduationWorkPermitPOE)
