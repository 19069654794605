import {Flow, FlowQueryResponse, IRequestFlowsParams} from './_models'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FLOW_URL = `${API_URL}/flows`

const getFlowById = (id: string): Promise<Flow> => {
  return axios.get(`${FLOW_URL}/find/${id}`).then((d: AxiosResponse<FlowQueryResponse>) => {
    return d.data as unknown as Flow
  })
}

const getFlows = (query: IRequestFlowsParams): Promise<Array<Flow>> | undefined => {
  return axios.post(`${FLOW_URL}/all`, query).then((response: AxiosResponse) => {
    return response.data as Array<Flow>
  })
}

const saveFlow = (flow: Flow): Promise<Flow> => {
  return axios.post(`${FLOW_URL}/save`, flow).then((response: AxiosResponse) => {
    return response.data as Flow
  })
}

const updateFlow = (flow: Flow): Promise<Flow> => {
  return axios.patch(`${FLOW_URL}/${flow.id}`, flow).then((response: AxiosResponse) => {
    return response.data as Flow
  })
}

export {getFlowById, saveFlow, getFlows, updateFlow}
