/* eslint-disable no-empty */
import React, {useEffect} from 'react'

import {Product} from '../../../modules/products/_models'
import ProductSearch from './ProductSearch'

interface IProps {
  codeData: any
  updateData: any
}

const PricePropertyEditor: React.FC<IProps> = ({codeData, updateData}) => {
  const [product, setProduct] = React.useState<Product | null>(null)

  useEffect(() => {
    if (codeData.data) {
      try {
        if (codeData.data.id) setProduct(codeData.data)
      } catch (e) {}
    }
  }, [])

  useEffect(() => {
    if (product) {
      updateData(product)
    }
  }, [product])

  const type = codeData.type


  if (type === 'price') {


    return (
      <div>
        <strong>Price:</strong>
        <ProductSearch product={product} setProduct={setProduct} />
      </div>
    )
  }

  return null
}

export default PricePropertyEditor
