/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty */
import * as Survey from 'survey-react'

import {IIfs, ISelectNames} from './types'
import {TableCell} from 'Components'
import React, {useEffect} from 'react'

import {Forms} from '../../../modules/forms/core/_models'
import OptionIfSelect from './OptionIfSelect'
import defaultSurveyConfig from '../../../FormBuilder/components/SurveyModule/config'

interface IProps {
  forms: any

  ifs: IIfs[]
  setIfs: (ifs: any) => void
  index: number
}

const FormIfSelect: React.FC<IProps> = ({forms, setIfs, index, ifs}) => {
  const [formSelect, setFormSelect] = React.useState<Forms | null>(null)
  const [dataForm, setDataForm] = React.useState<{}>({})
  const [allFields, setAllFields] = React.useState<Array<ISelectNames>>([])

  const habdleChange = (e: any) => {
    const form = forms.find((form: any) => {
      if (form.data.id === e.target.value) return form.data.data
    })
    ifs[index].formId = form.data.id
    ifs[index].variable = ''
    ifs[index].title = 'Seleccione'
    setFormSelect(form.data)

    setIfs([...ifs])
  }

  useEffect(() => {
    try {
      if (ifs[index].formId) {
        setFormSelect(forms.find((form: any) => form.data.id === ifs[index].formId).data)
      }
    } catch (e) {}
  }, [])

  useEffect(() => {
    try {
      if (formSelect) {
        setDataForm(formSelect.data)
      }
    } catch (e) {}
  }, [formSelect])

  return (
    <>
      <TableCell>
        <>
          <select
            id='demo-simple-select'
            onChange={(e) => {
              habdleChange(e)
            }}
            defaultValue={ifs[index].formId}
          >
            <option value={''}>Seleccione</option>

            {forms.map((item: any) => {
              return (
                <option key={item.data.id} value={item.data.id}>
                  {item.data.name}
                </option>
              )
            })}
          </select>
        </>
      </TableCell>
      <div style={{display: 'none'}}>
        {dataForm && (
          <Survey.Survey
            css={defaultSurveyConfig.defaultSurveyCSS}
            json={dataForm}
            onAfterRenderQuestion={(survey: any) => {
              setAllFields([])
              const fields: Array<ISelectNames> = []
              survey.getAllQuestions().forEach((question: any) => {
                fields.push({title: question.title, name: question.name})
              })
              setAllFields(fields)
            }}
          />
        )}
      </div>
      <TableCell>
        <OptionIfSelect index={index} ifs={ifs} setIfs={setIfs} allFields={allFields} />
      </TableCell>
    </>
  )
}

export default FormIfSelect
