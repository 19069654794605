import {Box, Button} from 'Components'
import React, {useContext, useEffect} from 'react'

import Dragger from 'antd/lib/upload/Dragger'
import {RootStoreContext} from '../../../stores/rootStore'


interface IProps {
  nodeId: string
  node: any
  data: any
}

const UploaderModule: React.FC<IProps> = ({nodeId, node}) => {
  const rootStore = useContext(RootStoreContext)
  const { updateDataNode, increaseEstep} =
    rootStore.formBuilderStore

  const [disbleButton, setDisbleButton] = React.useState(true)

  const [files, setFiles] = React.useState<any[]>([])

  useEffect(() => {
    if (files.length > 0) {
      setDisbleButton(false)
    }
  }, [files])

  const handleChange = (info: any) => {
    setFiles([...files, info.file])
    updateDataNode(files, nodeId)
  }

  const onComplete = () => {
    increaseEstep()
  }

  return (
    <Box>
      <>{node.name}</>

      <Dragger
        multiple
        maxCount={node.maxFiles}
        onChange={(info) => handleChange(info)}
        beforeUpload={() => false}
      >
        <p className='ant-upload-drag-icon'>InboxOutlined</p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        <p className='ant-upload-hint'>
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </Dragger>
      <Button disabled={disbleButton} onClick={() => onComplete()}>
        Siguiente
      </Button>
    </Box>
  )
}

export default UploaderModule
