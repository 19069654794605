
import tw from 'tailwind-styled-components'

interface ButtonProps {
  width?: string
  height?: string
  disabled?: boolean
  variant?: string
}

const Button = tw.button<ButtonProps>`
  
  flex
  justify-center
  items-center
  rounded-lg
  content-center
  text-sm
  font-semibold
  text-white
  text-center
  py-2
  px-4

  hover:bg-tomato-400
  focus:border-none
  focus:outline-none

  ${(p: any) => (p.width ? p.width : 'w-full')}
  ${(p: any) => (p.height ? p.height : 'h-10')}
  ${(p: any) =>
    p.disabled
      ? `
    bg-tomato-400
    cursor-not-allowed
    opacity-50
  `
      : 'bg-tomato-500'}
`
export {Button}
