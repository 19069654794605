import React, { Dispatch, SetStateAction, useEffect } from 'react'

interface IProps {
  country: string
  setETA: Dispatch<SetStateAction<boolean>>
}

const ETACountryChecker: React.FC<IProps> = ({ country, setETA }) => {
  const countryList =
    [
      'Andorra',
      'Australia',
      'Austria',
      'Bahamas',
      'Barbados',
      'Belgium',
      'Brunei Darussalam',
      'Bulgaria',
      'Chile',
      'Croatia',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'Ireland',
      'Israel',
      'Italy',
      'Japan',
      'South Korea',
      'Latvia',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Monaco',
      'Netherlands',
      'New Zealand',
      'Norway',
      'Papua New Guinea',
      'Poland',
      'Portugal',
      'Romania',
      'Samoa',
      'San Marino',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Spain',
      'Sweden',
      'Switzerland',
      'Taiwan',
      'United Arab Emirates',
    ]

    useEffect(() => {
      const isCountryValid = countryList.includes(country);
      setETA(isCountryValid);
    }, [country, setETA, countryList]);

    return null; 
  };

  export default ETACountryChecker;
