/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateCoopWorkPermit: React.FC<IProps> = ({
	products
}) => {
	const Coop = products.find(element => element.contratationProductId == "69")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setCoop, getCoop } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.type === FamilyTypes.principal && member.typeOfApplication === "Co-op work permit") {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (Coop !== undefined) {
		setCoop({
			id: Coop.id,
			productContratationId: Coop.contratationProductId,
			description: Coop.description,
			totalFee: counter * Coop.fee,
			name: Coop.name,
			quantity: counter,
			fee: Coop.price,
			memberInfo: arrayOfMembers
		})
	}
	return (
		<>
			{getCoop().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCoop().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCoop().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getCoop().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCoop().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCoopWorkPermit)
