/* eslint-disable no-empty-pattern */

import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateCSQ: React.FC<IProps> = ({
	products
}) => {
	const CSQ = products.find(element => element.contratationProductId == "41")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setCSQ, getCSQ, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (principalUsed()?.inCanada === "si"  && principalUsed()?.province === 'Quebec' && (principalUsed()?.sponsorship === 'spouseOrPartner' || principalUsed()?.sponsorship === 'spousePartnerShildren' ) && principalUsed()?.addCSQ === 'si'   ) {
			countwpFee++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
			arrayOfMembers.push(memberInfo)
}
	})
	if (CSQ !== undefined) {
		setCSQ({
			id: CSQ.id,
			productContratationId: CSQ.contratationProductId,
			description: CSQ.description,
			totalFee: countwpFee * CSQ.fee,
			name: CSQ.name,
			quantity: countwpFee,
			fee: CSQ.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}
	return (
		<>
			{getCSQ().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCSQ().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCSQ().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getCSQ().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCSQ().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCSQ)
