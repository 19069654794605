import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PREEVALUATION_URL = `${API_URL}/preevaluation`;

const sendPreEvaluationData = (data: any): Promise<any> => {
  const requestData = { data }; // Envuelve el objeto data en otro objeto con la propiedad "data"
  
  return axios.post(PREEVALUATION_URL, requestData)
    .then((response: AxiosResponse) => {
      console.log(requestData, 'llamada de la data pre sali');
      return response.data;
    })
    .catch((error) => { 
      throw error;
    });
};

export { sendPreEvaluationData };


