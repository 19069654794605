/* eslint-disable @typescript-eslint/no-unused-vars */


import {Model, StylesManager} from 'survey-core'
import React, { useContext, useEffect, useState} from 'react'

import {RootStoreContext} from '../../../stores/rootStore'
import {Survey} from 'survey-react-ui'
import defaultSurveyConfig from './config'
import {observer} from 'mobx-react'


export interface ISurveyProps {
  css?: any
  json: any
  data?: any
  onComplete: (survey: any, id: string) => void
  edit?: boolean
  id: string
}

StylesManager.applyTheme('defaultV2')

const SurveyForms: React.FunctionComponent<ISurveyProps> = ({
  data,
  json,
  onComplete,
  edit = true,
  id,
}) => {
  //const survey = new Survey.Model(initialJson)
  const rootStore = useContext(RootStoreContext)
  const {currentMember, setVisaTypeFromSurvey} = rootStore.familyStore

  const [loading, setLoading] = useState<boolean>(true)

  const survey = new Model(json)
  useEffect(() => {
    //Survey.StylesManager.applyTheme('defaultV2')
    // Survey.data = initialJson

    setLoading(false)
  }, [])

  const visaInfo = async (questions: any) => {
    setVisaTypeFromSurvey(questions.data.tipodevisa)
  }

  return (
    <Survey
      pagePrevText='Atras'
      pageNextText='Siguiente'
      completeText='Completar'
      json={json ? json : defaultSurveyConfig.defaultSurveyJSON}
      onComplete={(survey: any) => onComplete(survey, id)}
      onPartialSend={visaInfo}
      sendResultOnPageNext={true}
      data={data}
      model={survey}
      locale={'es'}
      focusFirstQuestionAutomatic={false}
    />
  )
}

export default observer(SurveyForms)
