/* eslint-disable no-empty-pattern */

import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'


interface IProps {
	products: Product[]
}
const CalculateVisitorRecord: React.FC<IProps> = ({
	products
}) => {
	const travelDocument = products.find(element => element.contratationProductId === "22")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setTravelDocumentRenovationStickerVisaON, principalUsed, getTravelDocumentRenovationStickerVisaON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.principal) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.children) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			member.wantsTemporaryResidenceService === "si" &&
			(principalUsed()?.languageRequired === 'si') &&
			member.type === FamilyTypes.conyuge) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Work Visa Extension" &&
			member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.conyuge) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "PGWP" &&
			member.wantsTemporaryResidenceService === "si" &&
			member.type === FamilyTypes.conyuge) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === 'Visa Renovation') {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)

		}
	})
	if (travelDocument !== undefined) {
		setTravelDocumentRenovationStickerVisaON({
			id: travelDocument.id,
			productContratationId: travelDocument.contratationProductId,
			description: travelDocument.description,
			totalFee: travelDocument.fee * counter,
			name: travelDocument.name,
			quantity: counter,
			fee: travelDocument.price,
			memberInfo: arrayOfMembers
		})
	}


	return (
		<>
			{getTravelDocumentRenovationStickerVisaON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getTravelDocumentRenovationStickerVisaON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getTravelDocumentRenovationStickerVisaON().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getTravelDocumentRenovationStickerVisaON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getTravelDocumentRenovationStickerVisaON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorRecord)
