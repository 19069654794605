/* eslint-disable no-useless-escape */
//test lmia https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c7707a19-5f49-4089-bfcc-143e997bb9a5&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2
//test service  https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9d8ca94d-4b4f-41eb-8874-1ac86c6aef8d&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2
import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import './shoppingCart.css'
import {useQuery} from '@tanstack/react-query'
import html2canvas from 'html2canvas'
import ProductInfo from './productInfo'
import ComboInfo from './comboInfo'
import {getProductsEmployer} from 'modules/products/_requests'
import Frames from './Frames'
import TableElement from './TableElement'
import {Button, Input} from 'antd'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DocusignInput from './DocusignInput'
import Swal from 'sweetalert2'
import {Link, Select} from 'Components'
import pnglogo from '../../../src/FormBuilder/components/EndModule/download.png'
import pnglogo2 from '../../../src/FormBuilder/components/EndModule/users.svg'
import pnglogo3 from '../../../src/FormBuilder/components/EndModule/checkmark.svg'
import {sendEmailsForEmployer} from '../../../src/modules/uploaders/core/_requests'
import {v4 as uuidv4} from 'uuid'
import {saveEmployer} from 'modules/flows/contractsTempsEmployer/_request'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import frame from '../../Components/Dialog/Frame.svg'
import arrow from '../../Components/Dialog/arrow-left.svg'
import check from '../../Components/Dialog/checkmark.svg'
import group from '../../Components/Dialog/Group.svg'
import youtube from '../../FormBuilder/icons/YouTube.svg'
import instagram from '../../FormBuilder/icons/Instagram.svg'
import facebook from '../../FormBuilder/icons/Facebook.svg'
import twitter from '../../FormBuilder/icons/Twitter.svg'
import tiktok from '../../FormBuilder/icons/Tiktok.svg'
import linkedin from '../../FormBuilder/icons/Linkedin.svg'

interface IProps {
  setInfoDisclaimerStep?: React.Dispatch<React.SetStateAction<number>>
}

interface FormResponses {
  companyName: string
  companyAddress: string
  companyPhone: string
  mainContactName: string
  mainContactEmail: string
  mainContactPhone: string
}

interface IfinalObject {
  props: {
    companyName: string
    companyAddress: string
    companyPhone: string
    mainContactName: string
    mainContactEmail: string
    mainContactPhone: string
    envelopeId: string
    paymentType: string
    combosInfo: any[]
    employersInfo: any[]
  }
  match_id: string
  email: string
}
interface CategoryState {
  [key: string]: boolean
}

const optionStyle: React.CSSProperties = {
  height: '200px',
}
const Shopping: React.FC<IProps> = ({setInfoDisclaimerStep}) => {
  const [hovered, setHovered] = useState(false)
  const {data: products} = useQuery(['gpro'], () => getProductsEmployer(''))
  const [finalSummary, setFinalSummary] = useState<any[]>()
  const [pricesDescriptionIndividual, setPricesDescriptionIndividual] = useState<any[]>()
  const [pricesDescriptionCombo, setPricesDescriptionCombo] = useState<any[]>([])
  const [pricesDescriptionComboClean, setPricesDescriptionComboClean] = useState<any[]>([])
  const [pricesDescriptionFinal, setPricesDescriptionFinal] = useState<any[]>()
  const [groupedProductsIndividual, setGroupedProductsIndividual] = useState<any[]>()
  const [groupedProductsCombo, setGroupedProductsCombo] = useState<any[]>()
  const [sent, setSent] = useState<boolean>(false)
  const [url, seturl] = useState<string>('')
  const [urls, seturls] = useState<string[]>()
  const [total, setTotal] = useState(0) // Total state
  const [numberOfCombos, setnumberOfCombos] = useState<any[]>([])
  const [productsAfterValidation, setProductsAfterValidation] = useState<any[]>([])
  const [productsForDocusign, setProductsForDocusign] = useState<any[]>([])
  const [currentStep, setCurrentStep] = useState(1)
  const [openCategories, setOpenCategories] = useState<CategoryState>({})
  /*     const [pf, setPf] = useState<any>(0); a */
  const [gf, setGf] = useState<any>(0)
  const [lmiapf, setlmiaPf] = useState<any>(0)
  const [lmiagf, setlmiaGf] = useState<any>(0)
  const [bill, setBill] = useState<any>('')
  const [verificationDocusign, setverificationDocusign] = useState<boolean>(false)
  const [discountPriceNoLMIA, setDiscountPriceNoLMIA] = useState<number>(0)
  const [discountPriceLMIA, setDiscountPriceLMIA] = useState<number>(0)
  const [inputEnvelopeID, setInputEnvelopeID] = useState('')
  const [verificationResult, setVerificationResult] = useState(
    'Escriba un numero de contrato existente'
  )
  const [verificationSuccess, setVerificationSuccess] = useState(false)
  const [answerPayment, setAnswerPayment] = useState('')
  const [isComboVisible, setIsComboVisible] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [employees, setEmployees] = useState<{name: string; email: string; phoneNumber: string}[]>(
    []
  )
  const [employeeEmails, setEmployeeEmails] = useState<string>('')
  const [emailError, setEmailError] = useState('')
  const [newName, setNewName] = useState('')
  const [nameError, setNameError] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [phoneError, setPhoneError] = useState<string | null>(null)
  const [answers, setAnswers] = useState<any>()
  const [showtable, setShowTable] = useState<any>(false)
  const [showToast, setShowToast] = useState(false)

  //CATEGORYNAME
  const CLOSEWORKPERMITIEC = '4a4bf193-5a44-4258-9374-4a6251e64cc8'
  const LMIAPOSITIVOEMPLEOENQUEBEC = 'a55e2e98-897a-4bc8-bc2b-565dea0be289'
  const LMIAPOSITIVOEMPLEOFUERADEQUEBEC = '858abd76-0f4e-4ef9-be1c-e95a1afe9b40'
  const JOBOFFERVALIDATION = '21d4657f-139a-4a79-8e4c-fd47ed79612b'
  const JOBADVERTISEMENT = '9d2425ca-de93-4d0f-92df-3cd7b6d54095'
  //Permiso de trabajo cerrado (LMIA aprobado) para trabajador extranjero fuera de Canadá"'
  const LMIAAPROBADOFUERADECANADA = '388fc514-0e36-42d8-8700-3584797a35cd'
  //Permiso de trabajo cerrado (LMIA aprobado) para trabajador extranjero en  Canadá"
  const LMIAAPROBADOFUERAENCANADA = '05546764-07e6-4e5f-85c0-20779ea00d42'
  //Permiso de trabajo cerrado + LMIA positivo para trabajador extranjero fuera de Canadá Empleo fuera de Quebec
  const WORKPLUSLMIAPOSFUERADEQUEBEC = 'e18638d2-aa39-4fb3-98ff-79d96b59a682'
  //Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero fuera de  Canadá Empleo en Quebec
  const WORKPLUSLMIAPOSTENQUEBEC = '107ed93f-6d20-40ad-ac65-a55941303d23'
  //   "Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero en Canadá Empleo fuera de Quebec"
  const WORKPLUSLMIAPOSTENCANADAFUERADEQUEBEC = 'e7c173b8-afa4-4e7a-a600-d1e0a950de78'
  //Permiso de trabajo cerrado + LMIA positivo para trabajador en Canadá empleo en Quebec
  const WORKPLUSLMIAPOSENCANADAENQUEBEC = 'e9826e9c-6c4c-4116-a27f-e65e02285f7d'
  //Permiso de trabajo cerrado  no incluye oferta de empleo validada Trabajador fuera de Canadá
  const WORKNOOFFFUERADECANADA = '03d2606d-f6ca-4473-94c9-107b79a96a18'
  //"Permiso de trabajo cerrado  no incluye oferta de empleo validada Trabajador  extrangero en  Canadá")
  const WORKNOOFFENCANADA = '241976ad-10ae-41fe-8a69-3caef04fdd65'
  //Permiso de trabajo cerrado + Offer of employment (LMIA-exempt) No. para trabajador extranjero fuera de Canadá
  const WORKPLUSOFFERFUERADECANADA = 'af661d21-2078-4092-a714-68835595ff98'
  // "Permiso de trabajo cerrado + Offer of employment (LMIA-exempt) No. para Trabajador extranjero en Canadá"
  const WORKPLUSOFFERENCANADA = '079f2eb8-58a2-44fd-9ac1-56a48806044e'

  const handlePhoneNumberChange = (e: {target: {value: any}}) => {
    const phoneNumber = e.target.value

    // Validación del número de teléfono: solo se permiten dígitos
    const isValidPhoneNumber = /^\d+$/.test(phoneNumber)

    // Validación adicional: no permitir letras
    const containsLetters = /[a-zA-Z]/.test(phoneNumber)

    // Definir el mensaje de error
    let errorMessage = null
    if (!isValidPhoneNumber) {
      errorMessage = 'El número de teléfono debe contener solo dígitos.'
    } else if (containsLetters) {
      errorMessage = 'El número de teléfono no puede contener letras.'
    }

    // Actualizar el estado y el error según la validación
    if (!containsLetters) {
      setNewPhoneNumber(phoneNumber)
    }
    setPhoneError(errorMessage)

    // Llamar a la función para enviar el número de teléfono a la base de datos
  }

  const handleNameChange = (e: {target: {value: any}}) => {
    const name = e.target.value
    const onlyLettersAndSpaces = name.replace(/[^a-zA-Z\s]/g, '') // Filtra caracteres que no sean letras ni espacios
    setNewName(onlyLettersAndSpaces)

    if (onlyLettersAndSpaces.trim() === '') {
      setNameError('Ingrese un nombre válido')
    } else {
      setNameError('')
    }
  }

  const handleEmailChange = (e: {target: {value: any}}) => {
    const email = e.target.value
    setNewEmail(email)

    if (!validateEmail(email)) {
      setEmailError('Ingrese un correo electrónico válido')
    } else {
      setEmailError('')
    }
  }

  const openCombo = () => {
    setIsComboVisible(true)
  }

  const closeCombo = () => {
    setIsComboVisible(false)
  }

  const addEmail = () => {
    if (validateEmail(newEmail) && newName.trim() !== '') {
      setEmployees([...employees, {name: newName, email: newEmail, phoneNumber: newPhoneNumber}])

      // Restablecer los estados y errores después de agregar un empleado
      setNewName('')
      setNewEmail('')
      setNewPhoneNumber('')
      setEmailError('')
      setNameError('')
      setPhoneError('')

      // Mostrar el toast de éxito
      toast.success('Agregados con éxito', {
        position: 'bottom-right', // Correct position for the toast
        autoClose: 3000, // Cerrar automáticamente después de 3 segundos
        hideProgressBar: true,
        className: 'custom-toast', // Asegúrate de que los estilos coincidan con tu diseño
      })
    } else {
      if (!validateEmail(newEmail)) {
        setEmailError('Ingrese un correo electrónico válido')
      } else {
        setEmailError('')
      }

      if (newName.trim() === '') {
        setNameError('Ingrese un nombre válido')
      } else {
        setNameError('')
      }
    }
  }

  const removeEmployee = (index: number) => {
    const updatedEmployees = [...employees]
    updatedEmployees.splice(index, 1)
    setEmployees(updatedEmployees)
  }

  const toggleCategory = (categoryName: string) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }))
  }
  const sendEmails = async () => {
    try {
      console.log('Lista de empleados:', employees)
      // Enviar la lista de empleados al servidor
      await sendEmailsForEmployer(employees)
      console.log('Emails enviados correctamente!')
    } catch (error) {
      console.error('Error enviando correos electrónicos:', error)
    }
  }

  const captureScreen = () => {
    const elementToCapture = document.getElementById('element-to-capture') // Reemplaza 'element-to-capture' con el ID del elemento que deseas capturar
    if (elementToCapture) {
      html2canvas(elementToCapture).then((canvas: any) => {
        // Crea un enlace (link) para descargar la imagen
        const downloadLink = document.createElement('a')
        downloadLink.href = canvas.toDataURL('image/png')
        const date = todaysdate()
        downloadLink.download = `contratacionimmiland${date}.png` // Nombre del archivo para descargar
        // Simula un clic en el enlace para descargar la imagen
        downloadLink.click()
      })
    } else {
      console.error('Element not found or is null.')
    }
  }

  const todaysdate = () => {
    const fechaHoy = new Date()
    const fechaFormateada = `${fechaHoy.getFullYear()}-${(fechaHoy.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${fechaHoy.getDate().toString().padStart(2, '0')}`
    return fechaFormateada
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  const getIconForCategory = (categoryName: string): string => {
    if (categoryName === 'CAQ worker') {
      return '/icons/gov-fee.svg'
    } else if (categoryName === 'International Experience Canada profile creation') {
      return '/icons/prof-fee.svg'
    } else if (
      categoryName === 'Labour Market Impact Assessment - exempt employer compliance federal fee'
    ) {
      return '/icons/gov-fee.svg'
    } else if (categoryName === 'International Experience Canada federal fee') {
      return '/icons/gov-fee.svg'
    } else if (categoryName === 'International Experience Canada work permit ') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'Work permit (includes extention) federal fee') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'Labour Market Impact Assessment Quebec') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'CAQ worker government application fee') {
      return '/icons/gov-fee.svg'
    } else if (categoryName === 'Labour Market Impact Assessment Federal ') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'Labour Market Impact Assessment positive federal fee ') {
      return '/icons/gov-fee.svg'
    } else if (categoryName === 'MIFI review LMIA') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'Labour Market Impact Assessment - exemption ') {
      return '/icons/prof-fee.svg'
    } else if (categoryName === 'Job ads x 2 ') {
      return '/icons/prof-fee.svg'
    } else if (
      categoryName === 'Closed work permit Labour Market Impact Assessment - positive (online) '
    ) {
      return '/icons/prof-fee.svg'
    } else if (
      categoryName ===
      'Closed work permit  Labour Market Impact Assessment positive - Inside Canada (online)'
    ) {
      return '/icons/prof-fee.svg'
    } else if (
      categoryName ===
      'Closed work permit Labour Market Impact Assessment - exempt - Inside Canada (online application)'
    ) {
      return '/icons/prof-fee.svg'
    } else if (
      categoryName === 'Closed work permit  Labour Market Impact Assessment - exempt (online)'
    ) {
      return '/icons/prof-fee.svg'
    } else {
      return '' // You can provide a default icon or an empty string
    }
  }

  const [formResponses, setFormResponses] = useState<FormResponses>({
    companyName: '',
    companyAddress: '',
    companyPhone: '',
    mainContactName: '',
    mainContactEmail: '',
    mainContactPhone: '',
  })

  const [errorMessages, setErrorMessages] = useState<FormResponses>({
    companyName: '',
    companyAddress: '',
    companyPhone: '',
    mainContactName: '',
    mainContactEmail: '',
    mainContactPhone: '',
  })

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setErrorMessages((prevState) => ({...prevState, companyName: ''}))
      setFormResponses((prevState) => ({...prevState, companyName: inputValue}))
    } else {
      setErrorMessages((prevState) => ({
        ...prevState,
        companyName: 'Por favor, ingrese solo letras sin caracteres especiales.',
      }))
    }
  }

  const handleCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (/^[A-Za-z0-9\s]+$/.test(inputValue) || inputValue === '') {
      setErrorMessages((prevState) => ({...prevState, companyAddress: ''}))
      setFormResponses((prevState) => ({...prevState, companyAddress: inputValue}))
    } else {
      setErrorMessages((prevState) => ({
        ...prevState,
        companyAddress: 'Por favor, ingrese solo letras y números sin caracteres especiales.',
      }))
    }
  }

  const handleCompanyPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (/^\+?\d+$/.test(inputValue) || inputValue === '') {
      setErrorMessages((prevState) => ({...prevState, companyPhone: ''}))
      setFormResponses((prevState) => ({...prevState, companyPhone: inputValue}))
    } else {
      setErrorMessages((prevState) => ({
        ...prevState,
        companyPhone: 'Por favor, ingrese solo números en el teléfono.',
      }))
    }
  }

  const handleMainContactNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setErrorMessages((prevState) => ({...prevState, mainContactName: ''}))
      setFormResponses((prevState) => ({...prevState, mainContactName: inputValue}))
    } else {
      setErrorMessages((prevState) => ({
        ...prevState,
        mainContactName: 'Por favor, ingrese solo letras sin caracteres especiales.',
      }))
    }
  }

  const handleMainContactEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setFormResponses((prevState) => ({...prevState, mainContactEmail: inputValue}))
  }

  const handleMainContactPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (/^\+?\d+$/.test(inputValue) || inputValue === '') {
      setErrorMessages((prevState) => ({...prevState, mainContactPhone: ''}))
      setFormResponses((prevState) => ({...prevState, mainContactPhone: inputValue}))
    } else {
      setErrorMessages((prevState) => ({
        ...prevState,
        mainContactPhone: 'Por favor, ingrese solo números en el teléfono.',
      }))
    }
  }

  const handleContinue = () => {
    const errorFields: FormResponses = {
      companyName: '',
      companyAddress: '',
      companyPhone: '',
      mainContactName: '',
      mainContactEmail: '',
      mainContactPhone: '',
    }

    // Validar campos vacíos
    for (const key in formResponses) {
      if (!formResponses[key as keyof FormResponses]) {
        errorFields[key as keyof FormResponses] = 'Por favor, completa este campo.'
      }
    }

    // Validar correo electrónico solo si se ha ingresado
    if (formResponses.mainContactEmail && !validateEmail(formResponses.mainContactEmail)) {
      errorFields.mainContactEmail = 'Por favor, ingrese un correo electrónico válido.'
    }

    setErrorMessages(errorFields)

    // Si no hay errores, avanzar al siguiente paso del formulario
    if (Object.values(errorFields).every((value) => value === '')) {
      setCurrentStep(2) // Avanzar al segundo paso del formulario
    }
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    if (!groupedProductsIndividual && !groupedProductsCombo && products) {
      // Arreglos para almacenar categorías por tipo
      const categoriasIndividuales: any = []
      const categoriasCombo: any = []
      // Recorre tus productos y clasifica las categorías
      products.forEach((producto: any) => {
        const categoriasDelProducto = producto.categories
        categoriasDelProducto.forEach((categoria: any) => {
          if (categoria.type === 'individual') {
            // Verifica si la categoría ya existe en el arreglo de individuales
            const categoriaExistente = categoriasIndividuales.find(
              (c: any) => c.id === categoria.id
            )
            if (!categoriaExistente) {
              // Si no existe, agrégala al arreglo de individuales
              categoriasIndividuales.push({
                id: categoria.id,
                name: categoria.name,
                products: [producto],
              })
            } else {
              // Si ya existe, agrega el producto a esa categoría
              categoriaExistente.products.push(producto)
            }
          } else if (categoria.type === 'combo') {
            // Verifica si la categoría ya existe en el arreglo de combo
            const categoriaExistente = categoriasCombo.find((c: any) => c.id === categoria.id)
            if (!categoriaExistente) {
              // Si no existe, agrégala al arreglo de combo
              categoriasCombo.push({
                id: categoria.id,
                name: categoria.name,
                products: [producto],
              })
            } else {
              // Si ya existe, agrega el producto a esa categoría
              categoriaExistente.products.push(producto)
            }
          }
        })
      })
      // Ahora tienes categorías y productos agrupados por tipo
      setGroupedProductsIndividual(categoriasIndividuales)
      setGroupedProductsCombo(categoriasCombo)
    }
  }, [products])

  useEffect(() => {
    const names = employees.map((employee) => employee.email).join(', ')
    setEmployeeEmails(names)
  }, [employees])

  useEffect(() => {
    const arrayForValidation: any = []

    if (
      numberOfCombos &&
      numberOfCombos[0] &&
      numberOfCombos[0].level1 &&
      numberOfCombos.length > 0 &&
      groupedProductsCombo
    ) {
      numberOfCombos.map((validationArray: any, index: number) => {
        switch (validationArray.level1) {
          case 'Closed work permit (IEC)':
            if (!arrayForValidation.find((product: any) => product.id === CLOSEWORKPERMITIEC)) {
              arrayForValidation.push(
                groupedProductsCombo.find((product) => product.id === CLOSEWORKPERMITIEC)
              )
            }
            break

          case 'LMIA':
            switch (validationArray.level2) {
              case 'Empleo en Quebec':
                if (
                  !arrayForValidation.find(
                    (product: any) => product.id === LMIAPOSITIVOEMPLEOENQUEBEC
                  )
                ) {
                  arrayForValidation.push(
                    groupedProductsCombo.find(
                      (product) => product.id === LMIAPOSITIVOEMPLEOENQUEBEC
                    )
                  )
                }
                break
              case 'Empleo fuera de Quebec':
                if (
                  !arrayForValidation.find(
                    (product: any) => product.id === LMIAPOSITIVOEMPLEOFUERADEQUEBEC
                  )
                ) {
                  const combo = arrayForValidation.push(
                    groupedProductsCombo.find(
                      (product) => product.id === LMIAPOSITIVOEMPLEOFUERADEQUEBEC
                    )
                  )
                }
                break
            }
            break

          case 'Job offer validation':
            if (!arrayForValidation.find((product: any) => product.id === JOBOFFERVALIDATION)) {
              arrayForValidation.push(
                groupedProductsCombo.find((product) => product.id === JOBOFFERVALIDATION)
              )
            }
            break

          case 'Job advertisement':
            if (!arrayForValidation.find((product: any) => product.id === JOBADVERTISEMENT)) {
              arrayForValidation.push(
                groupedProductsCombo.find((product) => product.id === JOBADVERTISEMENT)
              )
            }
            break

          case 'Closed work permit':
            switch (validationArray.level2) {
              case 'Permiso de trabajo cerrado NO incluye LMIA':
                switch (validationArray.level3) {
                  case 'Trabajador extranjero fuera de Canadá':
                    if (
                      !arrayForValidation.find(
                        (product: any) => product.id === LMIAAPROBADOFUERADECANADA
                      )
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find(
                          (product) => product.id === LMIAAPROBADOFUERADECANADA
                        )
                      )
                    }
                    break

                  case 'Trabajador extranjero en Canadá':
                    if (
                      !arrayForValidation.find(
                        (product: any) => product.id === LMIAAPROBADOFUERAENCANADA
                      )
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find(
                          (product) => product.id === LMIAAPROBADOFUERAENCANADA
                        )
                      )
                    }
                    break
                }
                break

              case 'Permiso de trabajo cerrado incluye LMIA':
                switch (validationArray.level3) {
                  case 'Trabajador extranjero fuera de Canadá':
                    switch (validationArray.level4) {
                      case 'Empleo fuera de Quebec':
                        if (
                          !arrayForValidation.find(
                            (product: any) => product.id === WORKPLUSLMIAPOSFUERADEQUEBEC
                          )
                        ) {
                          arrayForValidation.push(
                            groupedProductsCombo.find(
                              (product) => product.id === WORKPLUSLMIAPOSFUERADEQUEBEC
                            )
                          )
                        }
                        break
                      case 'Empleo en Quebec':
                        if (
                          !arrayForValidation.find(
                            (product: any) => product.id === WORKPLUSLMIAPOSTENQUEBEC
                          )
                        ) {
                          arrayForValidation.push(
                            groupedProductsCombo.find(
                              (product) => product.id === WORKPLUSLMIAPOSTENQUEBEC
                            )
                          )
                        }
                        break
                    }
                    break

                  case 'Trabajador extranjero en Canadá':
                    switch (validationArray.level4) {
                      case 'Empleo fuera de Quebec':
                        if (
                          !arrayForValidation.find(
                            (product: any) => product.id === WORKPLUSLMIAPOSTENCANADAFUERADEQUEBEC
                          )
                        ) {
                          arrayForValidation.push(
                            groupedProductsCombo.find(
                              (product) => product.id === WORKPLUSLMIAPOSTENCANADAFUERADEQUEBEC
                            )
                          )
                        }
                        break
                      case 'Empleo en Quebec':
                        if (
                          !arrayForValidation.find(
                            (product: any) => product.id === WORKPLUSLMIAPOSENCANADAENQUEBEC
                          )
                        ) {
                          arrayForValidation.push(
                            groupedProductsCombo.find(
                              (product) => product.id === WORKPLUSLMIAPOSENCANADAENQUEBEC
                            )
                          )
                        }
                        break
                    }
                    break
                }
                break

              case 'Permiso de trabajo cerrado NO incluye oferta de empleo validada':
                switch (validationArray.level3) {
                  case 'Trabajador extranjero fuera de Canadá':
                    if (
                      !arrayForValidation.find(
                        (product: any) => product.id === WORKNOOFFFUERADECANADA
                      )
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find(
                          (product) => product.id === WORKNOOFFFUERADECANADA
                        )
                      )
                    }

                    break
                  case 'Trabajador extranjero en Canadá':
                    if (
                      !arrayForValidation.find((product: any) => product.id === WORKNOOFFENCANADA)
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find((product) => product.id === WORKNOOFFENCANADA)
                      )
                    }

                    break
                }
                break

              case 'Permiso de trabajo cerrado incluye oferta de empleo validada':
                switch (validationArray.level3) {
                  case 'Trabajador extranjero fuera de Canadá':
                    if (
                      !arrayForValidation.find(
                        (product: any) => product.id === WORKPLUSOFFERFUERADECANADA
                      )
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find(
                          (product) => product.id === WORKPLUSOFFERFUERADECANADA
                        )
                      )
                    }
                    break
                  case 'Trabajador extranjero en Canadá':
                    if (
                      !arrayForValidation.find(
                        (product: any) => product.id === WORKPLUSOFFERENCANADA
                      )
                    ) {
                      arrayForValidation.push(
                        groupedProductsCombo.find((product) => product.id === WORKPLUSOFFERENCANADA)
                      )
                    }
                    break
                }
                break
            }
            break
        }
      })
    }
    console.log(numberOfCombos)
    console.log(arrayForValidation, 'este es array for validation')
    // Eliminar elementos en productsAfterValidation que no están en arrayForValidation
    const updatedProducts = productsAfterValidation.filter((product) =>
      arrayForValidation.some((validatedProduct: any) => validatedProduct.id === product.id)
    )

    // Agregar elementos en arrayForValidation que no están en productsAfterValidation
    arrayForValidation.forEach((validatedProduct: any) => {
      if (!updatedProducts.some((product) => product.id === validatedProduct.id)) {
        updatedProducts.push(validatedProduct)
      }
    })

    //si hay algun product con quantity 0 o indefinido , poner quantity 1
    // Suponiendo que arrayForValidation es tu array de elementos con cantidad

    for (let i = 0; i < arrayForValidation.length; i++) {
      // Verificar si la cantidad es falsy o igual a 0
      if (!arrayForValidation[i].quantity || arrayForValidation[i].quantity === 0) {
        // Si es así, establecer la cantidad en 1
        arrayForValidation[i].quantity = 1
      }
    }

    // Actualizar el estado con los productos actualizados
    setProductsAfterValidation(updatedProducts)
  }, [numberOfCombos])

  useEffect(() => {
    // Inicializar la variable para almacenar el total
    let total = 0

    // Iterar sobre cada elemento en productsAfterValidation
    productsAfterValidation.forEach((product) => {
      // Verificar si el elemento tiene una cantidad y productos
      if (product.quantity && product.products) {
        // Iterar sobre cada producto en el elemento
        product.products.forEach((productItem: any) => {
          // Multiplicar la cantidad por el precio de cada producto
          total += productItem.price * product.quantity
        })
      }
    })

    console.log('total', total)
    setTotal(total)
    setPricesDescriptionCombo([])
    productsAfterValidation.forEach((product) => {
      updateSummary(
        product.name,
        groupTotalPrice(product.products),
        product.quantity,
        product.products,
        product
      )
    })
  }, [productsAfterValidation])

  const IwantContract = () => {
    const duration = 10 // Duración en segundos
    let counter = 0

    const intervalId = setInterval(() => {
      counter++
      if (counter >= duration) {
        clearInterval(intervalId)
        Swal.close()
        showSecondPopup()
      } else {
        const progressBar = document.getElementById('progressBar')
        if (progressBar) {
          // Calculamos el porcentaje de progreso
          const progressPercentage = (counter / duration) * 100
          // Actualizamos el ancho de la barra de progreso
          progressBar.style.width = progressPercentage + '%'
        }
      }
    }, 1000)

    Swal.fire({
      title: '<span style="color: #13C296;"><u>¡Excelente!</u></span>',
      icon: 'info',
      html: ` <p>Espere mientras estamos validando su <b>contrato</b>
            <p>Por favor no cierre la pantalla hasta terminar la carga 
            <div class="progress">
            <div id="progressBar" class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
           
            <p>El contrato es válido por un plazo de <b>30 días</b></p>
           
            <br>
            `, // Agrega la barra de progreso
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        clearInterval(intervalId)
      }
    })

    setTimeout(() => {
      const confirmButton = Swal.getConfirmButton()
      if (confirmButton) {
        confirmButton.disabled = false
      }
    }, duration * 1000)
  }

  function showSecondPopup() {
    Swal.fire({
      title: '<span style="color: #13C296;"><u>¡Felicidades!</u></span>',
      icon: 'success',
      html: `<p><b>Hemos enviado su contrato al correo:</b></p><br>
            <p>${formResponses.mainContactEmail}</p><br>
            <p>Tienes problemas? contáctanos a <b>retainer.agreement@immilandcanada.com</b></p>`,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Si se hace clic en "¡Entendido!", recargar la página
        window.location.reload()
      }
    })
  }

  const updateSummary = (
    name: any,
    price: any,
    quantity: any,
    products: any,
    categoryInformation: any
  ) => {
    console.log('entre en update', categoryInformation)
    // Create a copy of finalSummary
    let updatedSummary: any

    if (Array.isArray(finalSummary)) {
      updatedSummary = [...finalSummary]
    } else {
      updatedSummary = []
    }

    // Find the index of the group in finalSummary
    const groupIndex = updatedSummary.findIndex((group: any) => group.name === name)

    if (quantity > 0) {
      // If quantity is greater than 0, add or update the group in finalSummary
      if (groupIndex !== -1) {
        // If the group exists, update its properties
        updatedSummary[groupIndex].quantity = quantity
        updatedSummary[groupIndex].price = price
        updatedSummary[groupIndex].products = products

        // Calculate categoriesTotals based on categories
        const categoriesTotals: {
          id: string
          category: string
          total: number
          type: string
          module: string
        }[] = []

        products.forEach((product: any) => {
          product.categories.forEach((category: any) => {
            const categoryId = category.id // Get the category id
            const categoryName = category.name
            const categoryType = category.type // Get the category type
            const categoryModule = category.module // Get the category module
            const productPrice = product.price
            const existingCategory = categoriesTotals.find((ct) => ct.id === categoryId)

            if (existingCategory) {
              // If the category already exists, update its total
              existingCategory.total += productPrice * quantity
            } else {
              // If the category doesn't exist, add it
              categoriesTotals.push({
                id: categoryId,
                category: categoryName,
                total: productPrice * quantity,
                type: categoryType, // Add the category type
                module: categoryModule, // Add the category module
              })
            }
          })
        })

        updatedSummary[groupIndex].categoriesTotals = categoriesTotals
      } else {
        // If the group doesn't exist, add it
        updatedSummary.push({name, price, quantity, products})

        // Calculate categoriesTotals based on categories
        const categoriesTotals: {
          id: string
          category: string
          total: number
          type: string
          module: string
        }[] = []

        products.forEach((product: any) => {
          product.categories.forEach((category: any) => {
            const categoryId = category.id // Get the category id
            const categoryName = category.name
            const categoryType = category.type // Get the category type
            const categoryModule = category.module // Get the category module
            const productPrice = product.price
            const existingCategory = categoriesTotals.find((ct) => ct.id === categoryId)

            if (existingCategory) {
              // If the category already exists, update its total
              existingCategory.total += productPrice * quantity
            } else {
              // If the category doesn't exist, add it
              categoriesTotals.push({
                id: categoryId,
                category: categoryName,
                total: productPrice * quantity,
                type: categoryType, // Add the category type
                module: categoryModule, // Add the category module
              })
            }
          })
        })

        updatedSummary[updatedSummary.length - 1].categoriesTotals = categoriesTotals
      }
    } else {
      // If quantity is 0, remove the group from finalSummary
      if (groupIndex !== -1) {
        updatedSummary.splice(groupIndex, 1)
      }
    }

    // Call the function to group products by individual categories
    const pricesDescription = groupProductsByIndividualCategories(
      name,
      products,
      quantity,
      categoryInformation
    )

    setPricesDescriptionCombo((prev) => [...prev, ...pricesDescription])

    setFinalSummary(updatedSummary)
  }

  // Calcula el precio total de los productos en este grupo
  const groupTotalPrice = (products: any) => {
    return products.reduce((total: any, product: any) => total + product.fee, 0)
  }

  const groupProductsByIndividualCategories = (
    groupName: string,
    products: any[],
    quantity: any,
    categoryInformation: any
  ) => {
    const groupedProductsByCategory: {categoryName: string; products: any[]; quantity: number}[] =
      []
    products.forEach((product: any) => {
      /*  if (category.type === 'individual') { */
      const categoryName = product.category.name
      const existingCategory = groupedProductsByCategory.find(
        (cat) => cat.categoryName === categoryName
      )
      if (existingCategory) {
        existingCategory.products.push(product)
      } else {
        groupedProductsByCategory.push({
          categoryName: categoryName,
          products: [product],
          quantity,
        })
      }
      /*  } */
    })

    const groupedCategoriesWithTotalPrice = groupedProductsByCategory.map((category) => {
      // Calcular el precio total para la categoría actual
      const totalPrice = category.products.reduce((total, product) => {
        return total + product.fee * quantity // Multiplicar el fee por la cantidad seleccionada
      }, 0)

      return {
        comboName: groupName,
        comboId: categoryInformation.id,
        categoryName: category.categoryName,
        products: category.products,
        quantity: category.quantity,
        totalPrice, // Agregar el precio total calculado
      }
    })

    return groupedCategoriesWithTotalPrice
  }
  const discountDocuNoLmia = (
    totalCalculated: any,
    gfCalculated: any,
    LMIApfCalculated: any,
    LMIAgfCalculated: any
  ) => {
    console.log('total', totalCalculated, 'gf', gfCalculated)
    return (
      (totalCalculated -
        gfCalculated -
        (LMIApfCalculated.totalCategoryPrice ? LMIApfCalculated.totalCategoryPrice : 0) -
        (LMIAgfCalculated.totalCategoryPrice ? LMIAgfCalculated.totalCategoryPrice : 0)) *
      0.3
    )
  }

  const discountDocuLmia = (LMIApfCalculated: any) => {
    return (LMIApfCalculated.totalCategoryPrice ? LMIApfCalculated.totalCategoryPrice : 0) * 0.3
  }

  useEffect(() => {
    const pricesDescription = Object.values(pricesDescriptionCombo).reduce(
      (acumulador: any, array: any) => {
        return acumulador.concat(array)
      },
      []
    )
    setGf([])
    setlmiaPf([])
    setlmiaGf([])
    setPricesDescriptionComboClean(pricesDescription)
    setDiscountPriceNoLMIA(0)
    setDiscountPriceLMIA(0)
    // Array para almacenar elementos agrupados por categoryName y la suma de los totalPrice
    const groupedPricesWithTotal: any = []

    // Función para obtener el índice de un grupo en el array basado en el nombre de la categoría
    const findGroupIndex = (categoryName: any) =>
      groupedPricesWithTotal.findIndex((group: any) => group.categoryName === categoryName)

    // Recorrer el array original y agrupar los elementos por categoryName
    pricesDescription.forEach((item: any) => {
      const index = findGroupIndex(item.categoryName)
      if (index !== -1) {
        // Si el grupo ya existe, actualiza la suma de totalPrice
        groupedPricesWithTotal[index].totalCategoryPrice += item.totalPrice || 0
      } else {
        // Si el grupo no existe, agrégalo al array
        groupedPricesWithTotal.push({
          categoryName: item.categoryName,
          totalCategoryPrice: item.totalPrice || 0,
        })
      }
    })

    let sumgf = 0

    /*    groupedPricesWithTotal.forEach((item: any) => {
               if (item.categoryName !== "Gov Fees Provincial" && item.categoryName !== "Gov Fees Federal items") {
                   sumpf += item.totalCategoryPrice;
               }
           }); */

    groupedPricesWithTotal.forEach((item: any) => {
      if (
        item.categoryName === 'Gov Fees Provincial' ||
        item.categoryName === 'Gov Fees Federal items'
      ) {
        console.log('ITEM GOV', item)
        sumgf += item.totalCategoryPrice
      }
    })

    // Encuentra el objeto con "categoryName" igual a "LMIA government fees"
    const lmiaGovernmentFees = groupedPricesWithTotal.find(
      (item: any) => item.categoryName === 'LMIA government fees'
    )
    const lmiaProfessionalFees = groupedPricesWithTotal.find(
      (item: any) => item.categoryName === 'LMIA professional fees'
    )
    /*   setPf(sumpf) */

    setGf(sumgf)
    if (lmiaProfessionalFees) {
      setlmiaPf(lmiaProfessionalFees)
    }
    if (lmiaGovernmentFees) {
      setlmiaGf(lmiaGovernmentFees)
    }
    // Mostrar los elementos agrupados por categoryName con la suma de precios totales
    console.log('pase por aca', total)
    if (sumgf > 0) {
      setDiscountPriceNoLMIA(
        verificationDocusign === true
          ? Number(
              discountDocuNoLmia(
                total,
                sumgf ? sumgf : 0,
                lmiaProfessionalFees ? lmiaProfessionalFees : 0,
                lmiaGovernmentFees ? lmiaGovernmentFees : 0
              )
            )
          : 0
      )
    }

    setDiscountPriceLMIA(
      verificationDocusign === true
        ? Number(discountDocuLmia(lmiaProfessionalFees ? lmiaProfessionalFees : 0))
        : 0
    )
  }, [finalSummary])

  if (!groupedProductsIndividual || !groupedProductsCombo) {
    return <>no data</>
  }
  const popupDisplayInfo = () => {
    Swal.fire({
      html: `
        <div class="popup-container">
          <div class="popup-header">
            <div class="popup-icon">
              <div class="popup-image-wrapper">
                <img src=${group} alt="" />
              </div>
            </div>
            <div class="popup-title">¿Está seguro que desea salir de su cotización?</div>
            <div class="popup-subtitle">Al hacerlo perderá los datos ingresados hasta ahora</div>
          </div>
          <div class="popup-footer">
            <div class="popup-button-group">
              <div id="backButton" class="popup-button back-button">
                <div class="popup-button-icon">
                  <img src=${arrow} alt="" />
                </div>
                <div class="popup-button-text">VOLVER</div>
              </div>
              <div id="confirmButton" class="popup-button confirm-button">
                <div class="popup-button-text">CONFIRMAR</div>
                <div class="popup-button-icon">
                  <img src=${check} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      `,
      showCloseButton: true,
      buttonsStyling: false,
      showConfirmButton: false,
      didOpen: () => {
        // Add event listeners for the buttons
        const backButton = document.getElementById('backButton')
        const confirmButton = document.getElementById('confirmButton')

        if (backButton) {
          backButton.addEventListener('click', () => {
            console.log('Back button clicked')
            Swal.close() // Close the popup
          })
        }

        if (confirmButton) {
          confirmButton.addEventListener('click', () => {
            console.log('Confirm button clicked')
            Swal.close() // Close the popup
            window.location.reload()
          })
        }
      },
    })
  }

  const handleSubmit = () => {
    setSent(true)
    /*  fetch(url) */
  }

  const handleRemoveProduct = (index: number) => {
    // Obtener el producto a eliminar
    const removedProduct = productsAfterValidation[index]
    console.log('Producto eliminado antes:', removedProduct)
    // Actualizar productsAfterValidation eliminando el producto
    const updatedArray = productsAfterValidation.filter((_, i) => i !== index)
    setProductsAfterValidation(updatedArray)

    // Remove the corresponding item from numberOfCombos
    setnumberOfCombos((prevArray: any) => {
      const newArray = [...prevArray]
      newArray.splice(index, 1)
      return newArray
    })

    const newPricesDescriptionComboClean = pricesDescriptionComboClean.filter((category) => {
      category.comboId !== removedProduct.id
    })
    console.log('Producto description antes:', newPricesDescriptionComboClean)

    const newFinalSumary = finalSummary?.filter((category: {name: any}) => {
      category.name !== removedProduct.name
    })

    setPricesDescriptionComboClean(newPricesDescriptionComboClean)
    setPricesDescriptionCombo(newPricesDescriptionComboClean)

    setFinalSummary(newFinalSumary)
    const newTotal = newPricesDescriptionComboClean.reduce(
      (sum, category) => sum + (category.quantity || 0),
      0
    )
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='container-questions-shopping' style={{fontFamily: 'Lato', width: '100%'}}>
            <div
              style={{
                width: '100%',
                height: '80px',
                marginTop: '30px',
                paddingRight: '40px',
                paddingBottom: '5px',
                background: 'white',
                position: 'fixed',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              <div
                className='flex flex-col'
                style={{
                  display: 'flex',
                  position: 'fixed',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  gap: '30px',
                }}
              >
                <button
                  className='button-exit'
                  onClick={() => {
                    popupDisplayInfo()
                  }}
                >
                  <img src={frame} alt='Exit Icon' className='icon' />
                  SALIR
                </button>

                <div className='step-two-container'>
                  <div
                    className='stepper-container'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      marginLeft: '-25px',
                    }}
                  >
                    {/* Step 1 */}
                    <div className='step selected'>
                      <div className='circle'>1</div>
                      <p className='step-label'>DETALLES DEL<br />EMPLEADOR</p>
                    </div>
                    {/* Step 2 */}
                    <div className='step'>
                      <div className='circle'>2</div>
                      <p className='step-label'>SELECCION DE<br />SERVICIOS</p>
                    </div>
                    {/* Step 3 */}
                    <div className='step'>
                      <div className='circle'>3</div>
                      <p className='step-label'>COTIZACIÓN</p>
                    </div>
                  </div>
                  <div className='progress-line'>
                    <div className='progress-line-completed'>
                      <div className='dot completed-dot'></div>
                      <div className='dot completed-dot1'></div>
                    </div>
                    <div className='dot dot-uncompleted1'></div>
                    <div className='dot dot-uncompleted2'></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '30px',
                  fontFamily: 'Lato',
                }}
              >
                <div style={{marginTop: '60px'}} className='container-input-shopping'>
                  <h3
                    style={{
                      fontSize: '1.3rem',
                      textAlign: 'left',
                      marginBottom: '1rem',
                      marginTop: '60px',
                    }}
                  >
                    <b>Detalles de la compañía</b>
                  </h3>
                  <label className='label_info'>Nombre legal de la compañía</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.companyName}
                    onChange={handleCompanyNameChange}
                  />
                  {errorMessages.companyName && (
                    <p style={{color: 'red'}}>{errorMessages.companyName}</p>
                  )}
                </div>

                <div className='container-input-shopping'>
                  <label className='label_info'>Direccion de la compañía</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.companyAddress}
                    onChange={handleCompanyAddressChange}
                  />
                  {errorMessages.companyAddress && (
                    <p style={{color: 'red'}}>{errorMessages.companyAddress}</p>
                  )}
                </div>

                <div className='container-input-shopping'>
                  <label className='label_info'>Numero de telefono de la compañía</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.companyPhone}
                    onChange={handleCompanyPhoneChange}
                  />
                  <a style={{color: '#4B5563'}}>
                    Con el codigo de su pais, por ejemplo : +56 9 1234567
                  </a>
                  {errorMessages.companyPhone && (
                    <p style={{color: 'red'}}>{errorMessages.companyPhone}</p>
                  )}
                </div>

                <div className='container-input-shopping'>
                  <h3 style={{fontSize: '1.3rem', textAlign: 'left', marginBottom: '1rem'}}>
                    <b>Datos de contacto principal</b>
                  </h3>
                  <label className='label_info'>Nombre de contacto principal</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.mainContactName}
                    onChange={handleMainContactNameChange}
                  />
                  {errorMessages.mainContactName && (
                    <p style={{color: 'red'}}>{errorMessages.mainContactName}</p>
                  )}
                </div>

                <div className='container-input-shopping'>
                  <label className='label_info'>Teléfono de contacto principal</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.mainContactPhone}
                    onChange={handleMainContactPhoneChange}
                  />
                  <a style={{color: '#4B5563'}}>
                    Con el codigo de su pais, por ejemplo: +56 9 1234567
                  </a>
                  {errorMessages.mainContactPhone && (
                    <p style={{color: 'red'}}>{errorMessages.mainContactPhone}</p>
                  )}
                </div>

                <div className='container-input-shopping'>
                  <label className='label_info'>Correo electrónico de contacto principal</label>
                  <input
                    className='input_info'
                    type='text'
                    value={formResponses.mainContactEmail}
                    onChange={handleMainContactEmailChange}
                  />
                  {errorMessages.mainContactEmail && (
                    <p style={{color: 'red'}}>{errorMessages.mainContactEmail}</p>
                  )}
                </div>

                <div className='container-input-shopping docusign-input'>
                  <DocusignInput
                    inputEnvelopeID={inputEnvelopeID}
                    setInputEnvelopeID={setInputEnvelopeID}
                    verificationResult={verificationResult}
                    setVerificationResult={setVerificationResult}
                    verificationSuccess={verificationSuccess}
                    setVerificationSuccess={setVerificationSuccess}
                    setVerificationDocusign={setverificationDocusign}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '5rem',
                  gap: '25rem',
                  paddingBottom: '6rem',
                }}
              >
                <button
                  className='backbutton-disc3'
                  onClick={() => {
                    if (setInfoDisclaimerStep !== undefined) setInfoDisclaimerStep(1)
                  }}
                >
                  SALIR
                </button>
                <button
                  className='startbutton-disc3'
                  style={{
                    border: '1px solid grey',
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                  }}
                  onClick={handleContinue}
                >
                  SIGUIENTE <img src='/icons/arrow-right.svg' alt='Home' />
                </button>
              </div>
            </div>
          </div>
        )
      case 2:
        return (

            <>
            <div
            style={{
              width: '100%',
              height: '80px',
              marginTop: '-120px',
              paddingRight: '40px',
              paddingBottom: '5px',
              background: 'white',
              position: 'fixed',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'inline-flex',
            }}
          >
            <div
              className='flex flex-col'
              style={{
                display: 'flex',
                position: 'fixed',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: '30px',
              }}
            >
              <button
                className='button-exit'
                onClick={() => {
                  popupDisplayInfo()
                }}
              >
                <img src={frame} alt='Exit Icon' className='icon' />
                SALIR
              </button>

              <div className='step-two-container'>
                <div
                  className='stepper-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    marginLeft: '-25px',
                  }}
                >
                  {/* Step 1 */}
                  <div className='step'>
                    <div className='circle'>1</div>
                    <p className='step-label'>DETALLES DEL<br />EMPLEADOR</p>
                  </div>
                  {/* Step 2 */}
                  <div className='step selected'>
                    <div className='circle'>2</div>
                    <p className='step-label'>SELECCION DE<br />SERVICIOS</p>
                  </div>
                  {/* Step 3 */}
                  <div className='step'>
                    <div className='circle'>3</div>
                    <p className='step-label'>COTIZACIÓN</p>
                  </div>
                </div>
                <div className="progress-line">
                              <div className="progress-line-completed">
                                  <div className="dot completed-dot"></div>
                                  <div className="checkmark-icon">
                                      <div className="checkmark"></div>
                                  </div>
                              </div>
                              <div className="progress-line-completed2">
                                  <div className="dot completed-dot1"></div>
                              </div>

                              <div className="dot-uncompleted2"></div>
                          </div>
              </div>
            </div>
          </div>
          <div
            className='mb-5 pl-5 pr-5'
            style={{paddingLeft: '150px', paddingRight: '150px', fontFamily: 'Lato'}}
          >            
            <div style={{marginTop: '150px'}}>    
            <h1 style={{marginBottom: '20px', fontSize: '18px', fontWeight: '700', }}>
              Seleccione los servicios que desee obtener
            </h1>
            <div style={{display: 'flex', flexDirection: 'row', gap: '30px', marginLeft: '2rem'}}>
              <div
                style={{display: 'flex', flexDirection: 'column', width: '234px', height: '215px'}}
              >
                <strong>Permiso de trabajo cerrado</strong>
                <a style={{color: '#637381', fontSize: '12px'}}>
                  Para contratar trabajadores extranjeros por la vía regular. Puede agregar LMIA o
                  validación de oferta de empleo.
                </a>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      if (numberOfCombos?.length === 0) {
                        setnumberOfCombos([{level1: 'Closed work permit'}])
                      } else {
                        setnumberOfCombos((prevArray: any) => [
                          ...prevArray,
                          {level1: 'Closed work permit'},
                        ])
                      }
                    }}
                  >
                    <a
                      style={{
                        borderBottom: '1px solid #18BFFF',
                        color: '#18BFFF',
                        gap: '10px',
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      Añadir servicio
                      <img src='/icons/circle-plus.svg' alt='Home' />
                    </a>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: '#C6C6C6', height: '165px', width: '1px'}}></div>
              <div
                style={{display: 'flex', flexDirection: 'column', width: '234px', height: '215px'}}
              >
                <strong>Permiso de trabajo cerrado (Para IEC)</strong>
                <a style={{color: '#637381', fontSize: '12px'}}>
                  Para contratar trabajadores extranjeros bajo la corriente Young Professional o
                  International co-op.
                </a>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      numberOfCombos.map((noc: any) => {
                        if (noc.level1 === 'Closed work permit (IEC)') {
                          return
                        }
                      })

                      if (
                        productsAfterValidation.length === numberOfCombos.length ||
                        !numberOfCombos.length
                      ) {
                        if (numberOfCombos?.length === 0) {
                          setnumberOfCombos([{level1: 'Closed work permit (IEC)'}])
                        } else {
                          setnumberOfCombos((prevArray: any) => [
                            ...prevArray,
                            {level1: 'Closed work permit (IEC)'},
                          ])
                        }
                      }
                    }}
                  >
                    <a
                      style={{
                        borderBottom: '1px solid #18BFFF',
                        color: '#18BFFF',
                        gap: '10px',
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      Añadir servicio
                      <img src='/icons/circle-plus.svg' alt='Home' />
                    </a>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: '#C6C6C6', height: '165px', width: '1px'}}></div>
              <div
                style={{display: 'flex', flexDirection: 'column', width: '234px', height: '215px'}}
              >
                <strong>Sólo LMIA</strong>
                <a style={{color: '#637381', fontSize: '12px'}}>
                  Trámite previo a la solicitud de permiso de trabajo de algunos extranjeros que
                  consiste en obtener una evaluación de impacto del mercado laboral.
                </a>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      if (numberOfCombos?.length === 0) {
                        setnumberOfCombos([{level1: 'LMIA'}])
                      } else {
                        setnumberOfCombos((prevArray: any) => [...prevArray, {level1: 'LMIA'}])
                      }
                    }}
                  >
                    <a
                      style={{
                        borderBottom: '1px solid #18BFFF',
                        color: '#18BFFF',
                        gap: '10px',
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      Añadir servicio
                      <img src='/icons/circle-plus.svg' alt='Home' />
                    </a>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: '#C6C6C6', height: '165px', width: '1px'}}></div>
              <div
                style={{display: 'flex', flexDirection: 'column', width: '234px', height: '215px'}}
              >
                <strong>Sólo Validación de Oferta de Empleo</strong>
                <a style={{color: '#637381', fontSize: '12px'}}>
                  Trámite previo a la solicitud de permiso de trabajo de algunos extranjeros que
                  consiste en validar la oferta de empleo exenta de LMIA
                </a>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      numberOfCombos.map((noc: any) => {
                        if (noc.level1 === 'Job offer validation') {
                          return
                        }
                      })
                      if (
                        productsAfterValidation.length === numberOfCombos.length ||
                        !numberOfCombos.length
                      ) {
                        if (numberOfCombos?.length === 0) {
                          setnumberOfCombos([{level1: 'Job offer validation'}])
                        } else {
                          setnumberOfCombos((prevArray: any) => [
                            ...prevArray,
                            {level1: 'Job offer validation'},
                          ])
                        }
                      }
                    }}
                  >
                    <a
                      style={{
                        borderBottom: '1px solid #18BFFF',
                        color: '#18BFFF',
                        gap: '10px',
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      Añadir servicio
                      <img src='/icons/circle-plus.svg' alt='Home' />
                    </a>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: '#C6C6C6', height: '165px', width: '1px'}}></div>
              <div
                style={{display: 'flex', flexDirection: 'column', width: '234px', height: '215px'}}
              >
                <strong>Sólo Anuncio de empleo </strong>
                <a style={{color: '#637381', fontSize: '12px'}}>
                  Trámite previo a la solicitud de LMIA, para realizar esfuerzos de reclutamiento
                  para contratar canadienses y residentes permanentes antes de ofrecer el empleo a
                  un extranjero.
                </a>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                  }}
                >
                  <div
                    onClick={() => {
                      numberOfCombos.map((noc: any) => {
                        if (noc.level1 === 'Job advertisement') {
                          return
                        }
                      })
                      if (
                        productsAfterValidation.length === numberOfCombos.length ||
                        !numberOfCombos.length
                      ) {
                        if (numberOfCombos?.length === 0) {
                          setnumberOfCombos([{level1: 'Job advertisement'}])
                        } else {
                          setnumberOfCombos((prevArray: any) => [
                            ...prevArray,
                            {level1: 'Job advertisement'},
                          ])
                        }
                      }
                    }}
                  >
                    <a
                      style={{
                        borderBottom: '1px solid #18BFFF',
                        color: '#18BFFF',
                        gap: '10px',
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      Añadir servicio
                      <img src='/icons/circle-plus.svg' alt='Home' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{display: 'flex', justifyContent: 'flex-end', width: '97%', marginTop: '1rem'}}
            >
              <div
                style={{
                  backgroundColor: '#F5F3FF',
                  borderRadius: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '38rem',
                  padding: '10px 10px 10px 10px',
                }}
              >
                <a
                  style={{
                    color: '#5B21B6',
                    fontSize: '16px',
                    marginBottom: '10px',
                    fontWeight: '600',
                  }}
                >
                  ¿SABE QUE TRABAJADORES AGREGAR?
                </a>
                <div style={{display: 'flex', width: '30rem'}}>
                  <a style={{width: '32rem', marginRight: '2rem'}}>
                    <strong style={{color: 'black', display: 'flex', width: '20rem'}}>
                      Si lo desea, puede ingresar sus correos electrónicos.
                    </strong>
                  </a>
                  <div
                    style={{
                      display: 'flex',
                      color: '#01A9DB',
                      width: '26rem',
                      marginRight: '-6rem',
                      gap: '5px',
                      marginTop: '-1rem',
                    }}
                  >
                    <button
                      onClick={openCombo}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        width: '14rem',
                        gap: '10px',
                        borderBottom: '1px solid #01A9DB',
                        marginBottom: '10px',
                      }}
                    >
                      <a style={{color: '#01A9DB'}}>INGRESAR TRABAJADORES</a>
                      <img src='/icons/add-user-alt.svg' alt='Home' />
                    </button>
                  </div>
                </div>

                {/* Contador de trabajadores ingresados o mensaje si no hay */}
                <div
                  style={{
                    backgroundColor: employees.length === 0 ? '#E1CFFF' : '#E1CFFF',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    borderRadius: '5px',
                    height: '1.3rem',
                  }}
                >
                  <img src={pnglogo2} alt='User icon' style={{marginRight: '10px'}} />
                  <p
                    style={{
                      color: employees.length === 0 ? '#5D3EBC' : 'rgb(91, 33, 182)',
                      fontWeight: '700',
                      fontSize: '14px',
                    }}
                  >
                    {employees.length === 0
                      ? 'No tiene trabajadores ingresados'
                      : `Tiene ${employees.length} ${
                          employees.length === 1
                            ? 'trabajador ingresado'
                            : 'trabajadores ingresados'
                        }`}
                  </p>
                </div>
              </div>
              {isComboVisible && (
                <div
                  className={`slide-over ${
                    isComboVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
                  }`}
                  data-controller='slide-over'
                >
                  <div
                    className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 z-10 '
                    data-slide-over-target='backdrop'
                    data-action='click->slide-over#hide' // Permite cerrar el slide-over haciendo clic en el fondo
                    data-transition-enter='ease-in-out duration-500'
                    data-transition-enter-start='opacity-0'
                    data-transition-enter-end='opacity-100'
                    data-transition-leave='ease-in-out duration-500'
                    data-transition-leave-start='opacity-100'
                    data-transition-leave-end='opacity-0'
                  ></div>

                  <div
                    className='fixed inset-0 overflow-hidden flex items-start justify-end z-10'
                    data-slide-over-target='panel'
                    data-transition-enter='transform transition ease-in-out duration-500 sm:duration-700'
                    data-transition-enter-start='-translate-x-full'
                    data-transition-enter-end='translate-x-0'
                    data-transition-leave='transform transition ease-in-out duration-500 sm:duration-700'
                    data-transition-leave-start='translate-x-0'
                    data-transition-leave-end='-translate-x-full'
                  >
                    <div
                      style={{maxWidth: '50rem'}}
                      className='relative w-screen  pointer-events-auto h-100'
                    >
                      <div className='flex flex-col h-full py-6 overflow-y-auto bg-white shadow-xl'>
                        <div className='sm:px-6 px-4 mt-5'>
                          <Button className='back-button-form1' onClick={closeCombo}>
                            <img src='/icons/Vector.svg' alt='Home' />
                            <strong style={{color: '#333333', fontSize: '16px', fontWeight: '700'}}>
                              VOLVER
                            </strong>
                          </Button>
                          <div
                            style={{
                              backgroundColor: '#DFE4EA',
                              height: '1px',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}
                          ></div>
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <a
                              style={{
                                fontSize: '24px',
                                fontWeight: '600',
                                color: '#49323A',
                                marginBottom: '20px',
                              }}
                            >
                              Agregar empleados a la solicitud
                            </a>
                            <strong style={{fontSize: '16px', fontWeight: '700', color: '#000000'}}>
                              Asignar correos de empleados
                            </strong>
                          </div>
                          <div
                            style={{
                              marginTop: '20px',
                              marginBottom: '20px',
                              border: '1px solid #374151',
                              borderRadius: '10px',
                              padding: '24px',
                            }}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                              <div
                                className='flex'
                                style={{
                                  gap: '10px',
                                  marginTop: '20px',
                                  marginBottom: '30px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                  <strong
                                    style={{color: '#111928', fontSize: '16px', fontWeight: '700'}}
                                  >
                                    Nombre completo del empleado
                                  </strong>
                                  <input
                                    type='text'
                                    placeholder='Ingrese el nombre del empleado'
                                    className={`input-employer ${nameError && 'input-error'}`}
                                    value={newName}
                                    onChange={handleNameChange}
                                  />
                                  {nameError && (
                                    <div style={{color: 'red'}} className='error-message'>
                                      {nameError}
                                    </div>
                                  )}
                                </div>

                                <div style={{display: 'flex', gap: '20px'}}>
                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <strong
                                      style={{
                                        color: '#111928',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      Correo de empleado
                                    </strong>
                                    <input
                                      type='email'
                                      placeholder='Ingrese un correo electrónico'
                                      className={`input-employer2 ${emailError && 'input-error'}`}
                                      value={newEmail}
                                      onChange={handleEmailChange}
                                    />
                                    {emailError && (
                                      <div style={{color: 'red'}} className='error-message'>
                                        {emailError}
                                      </div>
                                    )}
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <strong
                                      style={{
                                        color: '#111928',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      Número de teléfono del empleado
                                    </strong>
                                    <input
                                      type='text'
                                      placeholder='Ingrese el número de teléfono del empleado'
                                      className={`input-employer2 ${
                                        phoneError ? 'input-error' : ''
                                      }`} // Aplicar la clase 'input-error' si hay un error
                                      value={newPhoneNumber}
                                      onChange={handlePhoneNumberChange}
                                    />
                                    {phoneError && (
                                      <div style={{color: 'red'}} className='error-message'>
                                        {phoneError}
                                      </div>
                                    )}
                                  </div>{' '}
                                  <button
                                    className='button-hover-asign'
                                    onClick={addEmail}
                                    disabled={!!emailError || !newName.trim()}
                                  >
                                    <a
                                      style={{
                                        color: '#01A9DB',
                                        fontWeight: '700',
                                        fontSize: '16px',
                                      }}
                                    >
                                      Añadir
                                    </a>
                                  </button>
                                  <ToastContainer />
                                </div>
                              </div>
                            </div>
                            <strong
                              style={{fontWeight: '700', fontSize: '16px', lineHeight: '24px'}}
                            >
                              Empleados asignados a la solicitud
                            </strong>
                            <ul
                              className='mt-4'
                              style={{
                                border: '1px solid #374151',
                                borderRadius: '10px',
                                padding: '24px',
                                backgroundColor: '#D0F0FD66',
                              }}
                            >
                              {employees.map((employee, index) => (
                                <li
                                  className='list-hover'
                                  key={index}
                                  style={{
                                    borderBottom: '1px solid #DFE4EA',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '10px',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                  }}
                                >
                                  <span
                                    style={{color: '#005080', fontWeight: '400', fontSize: '14px'}}
                                  >
                                    {employee.name} - {employee.email} - {employee.phoneNumber}
                                  </span>
                                  <button
                                    className='quita-hover'
                                    style={{display: 'flex', gap: '10px'}}
                                    onClick={() => removeEmployee(index)}
                                  >
                                    {' '}
                                    <img src='/icons/circle-minus.svg' alt='Home' />{' '}
                                    <a
                                      style={{
                                        color: '#E10E0E',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      Quitar
                                    </a>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div
                            style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5rem'}}
                          >
                            <button
                              className='startbutton-disc3'
                              style={{
                                border: '1px solid grey',
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center',
                              }}
                              onClick={() => {
                                addEmail()
                                closeCombo()
                              }}
                            >
                              <img src={pnglogo3} alt='User icon' style={{marginRight: '10px'}} />{' '}
                              CONFIRMAR
                            </button>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1
                style={{marginBottom: '20px', fontSize: '18px', fontWeight: '700', width: '100%'}}
              >
                Estos son los servicios que ha seleccionado
              </h1>

              <div
                style={{
                  backgroundColor: '#D0F0FD66',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '40px',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    placeContent: 'center',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    flex: '1',
                  }}
                >
                  {numberOfCombos.length > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        flex: '1',
                      }}
                    >
                      {numberOfCombos.map((combo, index) => (
                        <div key={index} style={{marginRight: '20px', height: '100px'}}>
                          <strong style={{color: '#0B76B7', fontSize: '14px', fontWeight: '700'}}>
                            {combo.level1}
                          </strong>

                          <div style={{gap: '20px', display: 'flex', height: '83px'}}>
                            <TableElement
                              setNumberOfCombos={setnumberOfCombos}
                              numberOfCombos={numberOfCombos}
                              indexArray={index}
                              setProductsAfterValidation={setProductsAfterValidation}
                              combo={combo}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        placeContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <p style={{color: '#0B76B7'}}>
                        <strong>VEA AQUÍ SUS SERVICIOS AÑADIDOS</strong>
                      </p>
                    </div>
                  )}

                  <div style={{display: 'flex', flexDirection: 'column', marginTop: '5px'}}>
                    {productsAfterValidation.map((cat: any, index: any) => (
                      <div
                        key={index}
                        style={{
                          marginRight: '20px',
                          height: '100px',
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'row',
                        }}
                      >
                        <ComboInfo
                          key={index}
                          indexCombo={index}
                          products={cat.products}
                          setFinalSummary={setFinalSummary}
                          finalSummary={finalSummary}
                          groupName={cat.name}
                          setPricesDescriptionCombo={setPricesDescriptionCombo}
                          category={cat}
                          pricesDescriptionCombo={pricesDescriptionCombo}
                          onRemove={() => handleRemoveProduct(index)}
                          productsAfterValidation={productsAfterValidation}
                          numberOfCombos={numberOfCombos}
                          setProductsAfterValidation={setProductsAfterValidation}
                          setNumberOfCombos={setnumberOfCombos}
                        />
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '0px',
                      height: 'auto',
                      gap: '0px',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {productsAfterValidation.map((cat: any, index: any) => (
                      <button
                        style={{
                          backgroundColor: 'white',
                          width: '46px',
                          height: '36px',
                          borderRadius: '30px',
                          padding: '5px 14px 5px 14px',
                          boxShadow: '0px 5px 12px 0px #0000001A',
                          marginBottom: '36px',
                          marginTop: '27px',
                          boxSizing: 'border-box',
                        }}
                        onClick={() => handleRemoveProduct(index)}
                      >
                        <img src='/icons/trash.svg' alt='Home' />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {total - discountPriceLMIA - discountPriceNoLMIA > 0 && (
              <div>
                {' '}
                <div
                  className='div-price'
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end',
                    marginTop: '22px',
                    marginBottom: '40px',
                  }}
                >
                  <p
                    style={{
                      color: '#13C296',
                      display: 'flex',
                      gap: '10px',
                      fontSize: '18px',
                      fontWeight: '700',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    {' '}
                    Total de los servicios{' '}
                  </p>
                  <div
                    className='container-price'
                    style={{
                      backgroundColor: '#DAF8E6',
                      width: '30rem',
                      height: '6rem',
                      display: 'flex',
                      borderRadius: '10px',
                      padding: '5px 5px 5px 5px',
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <div style={{display: 'flex', marginLeft: '10px', marginTop: '8px'}}>
                        <p
                          style={{
                            color: '#13C296',
                            display: 'flex',
                            gap: '10px',
                            fontSize: '24px',
                            fontWeight: '700',
                          }}
                        >
                          {' '}
                          <p>
                            {' '}
                            {
                              productsAfterValidation.length
                              /*    pricesDescriptionComboClean.reduce((sum, category) => sum + (category.quantity || 0), 0) */
                            }
                          </p>{' '}
                          Servicios
                        </p>
                      </div>
                      <img
                        className='arrow-price'
                        src='/icons/arrow-right-green.svg'
                        alt='Logo de Immigracion'
                        style={{
                          paddingRight: '10px',
                          paddingTop: '12px',
                          marginLeft: '20px',
                          width: '40px',
                          height: '40px',
                        }}
                      />{' '}
                    </div>
                    <div>
                      {' '}
                      <p
                        style={{
                          color: '#13C296',
                          fontSize: '24px',
                          fontWeight: '700',
                          marginBottom: '0px',
                          marginTop: '8px',
                        }}
                      >
                        {' '}
                      </p>
                      <p
                        style={{
                          color: '#13C296',
                          fontSize: '24px',
                          fontWeight: '700',
                          marginBottom: '0px',
                          marginTop: '8px',
                        }}
                      >
                        {' '}
                        CAD{formatter.format(total - discountPriceLMIA - discountPriceNoLMIA)}
                      </p>
                      <a style={{color: '#219653', fontSize: '12px', fontWeight: '400'}}>
                        Costos de transacción se calculan al final
                      </a>
                    </div>
                  </div>
                </div>{' '}
              </div>
            )}

            {/*    <div>
                            {`lmia  ${discountPriceLMIA}`}

                            {`no lm  ${discountPriceNoLMIA}`}
                        </div> */}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '5rem',
                gap: '25rem',
                paddingBottom: '6rem',
              }}
            >
              <Button
                style={{width: '200px', height: '56px', display: 'flex', justifyContent: 'center'}}
                className='back-button-form1'
                onClick={() => setCurrentStep(1)}
              >
                <img src='/icons/Vector.svg' alt='Home' />
                <strong style={{color: '#333333', fontSize: '16px', fontWeight: '700'}}>
                  VOLVER
                </strong>
              </Button>
              <button
                className='startbutton-disc3'
                style={{
                  border: '1px solid grey',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                }}
                onClick={() => setCurrentStep(3)}
              >
                SIGUIENTE <img src='/icons/arrow-right.svg' alt='Home' />
              </button>
            </div>
            </div> 
          </div>
          </>
        )
      case 3:
        return (
          <div id='element-to-capture' style={{fontFamily: 'Lato'}}>
            {/* Contenido para mostrar los productos seleccionados */}
            <div
            style={{
              width: '100%',
              height: '80px',
              marginTop: '-120px',
              paddingRight: '40px',
              paddingBottom: '5px',
              background: 'white',
              position: 'fixed',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'inline-flex',
            }}
          >
            <div
              className='flex flex-col'
              style={{
                display: 'flex',
                position: 'fixed',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: '30px',
              }}
            >
              <button
                className='button-exit'
                onClick={() => {
                  popupDisplayInfo()
                }}
              >
                <img src={frame} alt='Exit Icon' className='icon' />
                SALIR
              </button>

              <div className='step-two-container'>
                <div
                  className='stepper-container'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    marginLeft: '-25px',
                  }}
                >
                  {/* Step 1 */}
                  <div className='step'>
                    <div className='circle'>1</div>
                    <p className='step-label'>DETALLES DEL<br />EMPLEADOR</p>
                  </div>
                  {/* Step 2 */}
                  <div className='step'>
                    <div className='circle'>2</div>
                    <p className='step-label'>SELECCION DE<br />SERVICIOS</p>
                  </div>
                  {/* Step 3 */}
                  <div className='step selected'>
                    <div className='circle'>3</div>
                    <p className='step-label'>COTIZACIÓN</p>
                  </div>
                </div>
                <div className="progress-line">
							<div className="progress-line-completed">
							<div className="dot completed-dot"></div>
							<div className="checkmark-icon">
								<div className="checkmark"></div>
							</div>
						</div>
						<div className="progress-line-completed2">
							<div className="checkmark-icon">
								<div className="checkmark"></div>
							</div>
						</div>
						<div className="progress-line-completed3">
							<div className="dot completed-dot1"></div>
						</div>
					</div>
              </div>
            </div>
          </div>

            <div
              style={{marginTop: '150px',display: 'flex', justifyContent: 'center', gap: '200px', fontFamily: 'Lato'}}
            >
              <div style={{textAlign: 'left'}}>
                <h1
                  style={{
                    color: '#0B76B7',
                    fontSize: '16px',
                    fontWeight: '700',
                    marginBottom: '10px',
                  }}
                >
                  DATOS DEL EMPLEADOR
                </h1>
                <div
                  style={{
                    backgroundColor: '#D0F0FD66',
                    width: '368px',
                    height: '342px',
                    borderRadius: '10px',
                    padding: '16px 20px 30px 20px',
                  }}
                >
                  <p
                    style={{
                      color: '#0B76B7',
                      fontSize: '12px',
                      fontWeight: '700',
                      marginBottom: '10px',
                    }}
                  >
                    DETALLES DE LA COMPAÑÍA:
                  </p>
                  <div
                    style={{
                      backgroundColor: '#0B76B7',
                      width: '100%',
                      height: '1px',
                      marginBottom: '10px',
                    }}
                  ></div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                      }}
                    >
                      Nombre legal de la compañía:
                    </p>
                    <p style={{color: 'black', display: 'inline-block', fontSize: '12px'}}>
                      <strong>{formResponses.companyName}</strong>
                    </p>
                  </div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                        minWidth: '103px',
                      }}
                    >
                      Direccion de la compañía:
                    </p>
                    <p
                      style={{
                        color: 'black',
                        display: 'inline-block',
                        fontSize: '12px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <strong>{formResponses.companyAddress}</strong>
                    </p>
                  </div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                      }}
                    >
                      Numero de telefono de la compañía:
                    </p>
                    <p style={{color: 'black', display: 'inline-block', fontSize: '12px'}}>
                      <strong>{formResponses.companyPhone}</strong>
                    </p>
                  </div>
                  <p
                    style={{
                      color: '#0B76B7',
                      fontSize: '12px',
                      fontWeight: '700',
                      marginBottom: '10px',
                      marginTop: '20px',
                    }}
                  >
                    DETALLES DE CONTRATO PRINCIPAL
                  </p>
                  <div
                    style={{
                      backgroundColor: '#0B76B7',
                      width: '100%',
                      height: '1px',
                      marginBottom: '10px',
                    }}
                  ></div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                      }}
                    >
                      Nombre de contacto principal:
                    </p>
                    <p style={{color: 'black', display: 'inline-block', fontSize: '12px'}}>
                      <strong>{formResponses.mainContactName}</strong>
                    </p>
                  </div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                      }}
                    >
                      Correo electrónico de contacto principal:
                    </p>
                    <p style={{color: 'black', display: 'inline-block', fontSize: '12px'}}>
                      <strong>{formResponses.mainContactEmail}</strong>
                    </p>
                  </div>
                  <div style={{gap: '5px', display: 'flex', marginBottom: '5px'}}>
                    <p
                      style={{
                        color: '#0B76B7',
                        display: 'inline-block',
                        width: 'auto',
                        fontSize: '12px',
                      }}
                    >
                      Teléfono de contacto principal:
                    </p>
                    <p style={{color: 'black', display: 'inline-block', fontSize: '12px'}}>
                      <strong>{formResponses.mainContactPhone}</strong>
                    </p>
                  </div>
                </div>
              </div>

              <div style={{marginLeft: '20px'}}>
                {/* Datos de container-total-price */}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  {' '}
                  <p
                    style={{
                      color: '#219653',
                      fontSize: '1rem',
                      paddingBottom: '7px',
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    Los servicios que contratará <strong>tienen un valor total de</strong>{' '}
                  </p>
                  <a
                    style={{
                      color: '#1A8245',
                      fontSize: '30px',
                      fontWeight: '800',
                      fontFamily: 'Lato',
                      backgroundColor: '#DAF8E6',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      width: '45rem',
                      height: '90px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    CAD ${total - discountPriceLMIA - discountPriceNoLMIA}
                  </a>
                </div>
                <div style={{marginBottom: '20px', marginTop: '40px'}}>
                  <strong>Incluye lo siguiente:</strong>
                </div>

                <div style={{width: '45rem'}}>
                  {pricesDescriptionComboClean?.map((category: any) => (
                    <>
                      <div
                        style={{
                          border: '1px solid #1F2A37',
                          borderRadius: '10px',
                          padding: '16px 20px 16px 20px',
                          marginBottom: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px',
                          }}
                          key={category.categoryName}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '20px',
                              width: '80%',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{color: 'black', fontSize: '14px', fontWeight: '700'}}>
                              {category.comboName.slice(0, 50)} {category.categoryName.slice(0, 50)}
                            </p>
                            {'  '}
                            <p
                              className='quantity-product'
                              style={{
                                color: '#1A8245',
                                fontSize: '12px',
                                fontWeight: '700',
                                maxWidth: '5rem',
                              }}
                            >
                              Cantidad {category.quantity}
                            </p>
                          </div>
                          <div
                            style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}
                          >
                            <p
                              style={{
                                color: '#111928',
                                fontSize: '18px',
                                fontWeight: '700',
                                marginRight: '10px',
                              }}
                            >
                              ${category.totalPrice}
                            </p>
                            <img
                              src={
                                openCategories[category.categoryName]
                                  ? '/icons/chevron-up.svg'
                                  : '/icons/chevron-down.svg'
                              }
                              alt='Toggle'
                              onClick={() => toggleCategory(category.categoryName)}
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </div>
                        {openCategories[category.categoryName] && (
                          <div>
                            {category.products?.map((productsAssigned: any) => (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                }}
                                key={productsAssigned.name}
                              >
                                <img
                                  style={{width: '20px', height: '20px', marginRight: '10px'}}
                                  src={getIconForCategory(productsAssigned.name)}
                                  alt={`Icon for ${productsAssigned.name}`}
                                />
                                <div
                                  style={{
                                    flex: '1',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '5px',
                                  }}
                                >
                                  <p
                                    style={{fontSize: '14px', fontWeight: '700', color: '#111928'}}
                                  >
                                    {productsAssigned.name}
                                  </p>
                                  <p
                                    style={{fontSize: '12px', fontWeight: '400', color: '#4B5563'}}
                                  >
                                    {productsAssigned.description}
                                  </p>
                                </div>
                                <p
                                  style={{
                                    marginLeft: 'auto',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    color: '#828282',
                                    marginRight: '2.6rem',
                                  }}
                                >
                                  ${productsAssigned.price}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  ))}

                  {verificationDocusign === true ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        fontWeight: 'bold',
                        padding: '0 24px',
                      }}
                    >
                      Descuento aplicado:
                      {!isNaN(discountPriceLMIA + discountPriceNoLMIA) ? (
                        <div>- {formatter.format(discountPriceLMIA + discountPriceNoLMIA)} </div>
                      ) : (
                        '$0.00'
                      )}
                    </div>
                  ) : null}
                </div>
                <hr style={{marginTop: '15px', marginBottom: '35px'}} />
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: 'fit-content',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <label>Forma de pago</label>
                    </div>
                    <Select
                      defaultValue={''}
                      value={answerPayment}
                      onChange={(e) => {
                        setAnswerPayment(e.target.value)
                        if (e.target.value === '') {
                          setBill('')
                          return
                        }
                        if (e.target.value === 'Pago inmediato con tarjeta de credito') {
                          setBill(false)
                        } else {
                          setBill(true)
                        }
                      }}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid black',
                        width: 'fit-content',
                        color: 'gray',
                        height: '40px',
                      }}
                    >
                      <option value={''}>- Seleccionar forma de pago -</option>

                      <option value={'Pago posterior con factura'}>
                        Pago posterior con factura
                      </option>
                      <option value={'Pago inmediato con tarjeta de credito'}>
                        Pago inmediato con tarjeta de credito
                      </option>
                    </Select>
                    {/*  <input
                                        type='checkbox'
                                        checked={bill}
                                        onChange={(e) => {
                                            setBill(e.target.checked)
                                        }}
                                    />
                                    
                                    <label htmlFor="billCheckbox" style={{marginLeft:'10px'}}>Enviar Factura</label> */}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      justifyItems: 'center',
                      flexWrap: 'wrap',
                      gap: '7px',
                    }}
                  >
                    <p>
                      <b>Esta cotización es válida por 90 días</b>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <button
                        onClick={() => {
                          console.log('entre')
                          captureScreen()
                        }}
                        style={{
                          color: '#60C9E8',
                          fontStyle: 'italic',
                          textDecoration: 'underline',
                          marginLeft: '5px',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '2px',
                        }}
                      >
                        Descargar
                        <img style={{height: '16px'}} src={pnglogo}></img>
                      </button>
                    </div>
                  </div>
                </div>

                {/* <h1>informacion del formulario:</h1>
                                <button onClick={() => { setShowTable(!showtable) }}>ver</button> */}
                {showtable === true && (
                  <>
                    <>
                      {Object.entries(formResponses).map(([key, value]) => (
                        <div key={key} style={{display: 'flex', marginBottom: '10px'}}>
                          <strong style={{marginRight: '10px'}}>{key}:</strong>
                          <span>{value}</span>
                        </div>
                      ))}
                    </>
                    <>
                      <div key={''} style={{display: 'flex', marginBottom: '10px'}}>
                        <strong style={{marginRight: '10px'}}>envelopeId</strong>
                        <span>{inputEnvelopeID}</span>
                      </div>
                    </>
                    <>
                      <div key={''} style={{display: 'flex', marginBottom: '10px'}}>
                        <strong style={{marginRight: '10px'}}>paymentType</strong>
                        <span>{answerPayment}</span>
                      </div>
                    </>
                    <>
                      <div key={''} style={{display: 'flex', marginBottom: '10px'}}>
                        <strong style={{marginRight: '10px'}}>Empleados</strong>
                        {employees.map((employee, index) => (
                          <li
                            className='list-hover'
                            key={index}
                            style={{
                              borderBottom: '1px solid #DFE4EA',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '10px',
                              fontSize: '13px',
                              fontWeight: '400',
                            }}
                          >
                            <span>
                              {employee.name} - {employee.email} - {employee.phoneNumber}
                            </span>
                          </li>
                        ))}
                      </div>
                    </>

                    <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
                      <strong style={{marginRight: '10px'}}>Combos detectados</strong>
                      {pricesDescriptionComboClean.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            padding: '20px',
                            margin: '10px',
                            width: '45%',
                          }}
                        >
                          <strong style={{marginRight: '10px'}}>ComboName</strong>
                          <h3>{item.comboName} </h3> {/* Mostrar comboName directamente */}
                          <strong style={{marginRight: '10px'}}>Quantity</strong>
                          <h3>{item.quantity}</h3> {/* Mostrar comboName directamente */}
                          <strong style={{marginRight: '10px'}}>Category</strong>
                          <h3>{item.categoryName}</h3> {/* Mostrar comboName directamente */}
                          <strong style={{marginRight: '10px'}}>ProductName</strong>
                          <div>
                            {item.products.map((product: any, productIndex: any) => (
                              <h4 key={productIndex}>{product.name}</h4> // Mostrar solo el nombre del producto
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5rem',
                    gap: '50px',
                    marginBottom: '3rem',
                  }}
                >
                  {' '}
                  <Button onClick={() => setCurrentStep(8)} className='backbutton'>
                    <strong>Volver a cotizar</strong>
                  </Button>
                  {bill !== true && bill !== false && (
                    <Button
                      className='startbutton-disc2FamGroup-firmar'
                      style={{
                        width: '14rem',
                        backgroundColor: 'gray',
                        color: 'white',
                        height: '4rem',
                        fontSize: '1rem',
                      }}
                    >
                      ¡Quiero contratar!
                    </Button>
                  )}
                  {(bill === true || bill === false) && (
                    <Button
                      onClick={async () => {
                        const matchId = uuidv4().toString()
                        IwantContract()
                        sendEmails()
                        console.log('productswhensent', productsAfterValidation)
                        const urlvar: string[] = []
                        let payment = 1
                        const comboNameExistsForService = productsAfterValidation.some(
                          (item: any) =>
                            item.name === 'Closed work permit (IEC)' ||
                            /*  || (item.name === "LMIA positivo (Empleo en Quebec)")
                                                 || (item.name === "LMIA positivo (Empleo fuera de Quebec)") */
                            item.name ===
                              'Permiso de trabajo cerrado (LMIA aprobado) para trabajador extranjero fuera de Canadá' ||
                            item.name ===
                              'Permiso de trabajo cerrado (LMIA aprobado) para trabajador extranjero en  Canadá' ||
                            item.name ===
                              'Permiso de trabajo cerrado  no incluye oferta de empleo validada Trabajador fuera de Canadá' ||
                            item.name ===
                              'Permiso de trabajo cerrado  no incluye oferta de empleo validada Trabajador  extrangero en  Canadá' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para trabajador extranjero fuera de Canadá Empleo fuera de Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero fuera de  Canadá Empleo en Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero en Canadá Empleo fuera de Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para trabajador en Canadá empleo en Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + Offer of employment (LMIA-exempt) No. para trabajador extranjero fuera de Canadá' ||
                            item.name ===
                              'Permiso de trabajo cerrado + Offer of employment (LMIA-exempt) No. para Trabajador extranjero en Canadá' ||
                            item.name === 'Job offer validation'
                        )

                        const comboForRetainer = productsAfterValidation.some(
                          (item: any) =>
                            item.name === 'LMIA positivo (Empleo en Quebec)' ||
                            item.name === 'LMIA positivo (Empleo fuera de Quebec)' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para trabajador extranjero fuera de Canadá Empleo fuera de Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero fuera de  Canadá Empleo en Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para Trabajador extranjero en Canadá Empleo fuera de Quebec' ||
                            item.name ===
                              'Permiso de trabajo cerrado + LMIA positivo para trabajador en Canadá empleo en Quebec' ||
                            item.name === 'Job advertisement'
                        )

                        /* if (bill === true) {
                                                payment = 0
                                            } else{
                                                payment=1
                                            }
*/
                        /*      console.log("PF",(total - ((lmiapf.totalCategoryPrice? lmiapf.totalCategoryPrice:0) + (lmiagf.totalCategoryPrice? lmiagf.totalCategoryPrice:0)))-gf   )
                                                 console.log("GF",gf)
                                                 console.log("total no LMIA CONTRACT ",((payment===0 ? 0: total - ((lmiapf.totalCategoryPrice? lmiapf.totalCategoryPrice:0) + (lmiagf.totalCategoryPrice? lmiagf.totalCategoryPrice:0)))))
 
                                             
 
                                                 console.log("PF LMIA",(lmiapf.totalCategoryPrice))
                                                 console.log("GF LMIA",(lmiagf.totalCategoryPrice))
                                                 console.log("TOTAL LMIA",((payment===0? 0:(lmiapf.totalCategoryPrice + lmiagf.totalCategoryPrice)))) */
                        if (bill === true) {
                          payment = 0
                        } else {
                          payment = 1
                        }
                        const today = new Date()
                        const date: string =
                          today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

                        const employername = formResponses.companyName
                        const employeraddress = formResponses.companyAddress
                        const employerphone = formResponses.companyPhone
                        const mainname = formResponses.mainContactName
                        const mainemail = formResponses.mainContactEmail
                        const mainphone = formResponses.mainContactPhone
                        const contractpf =
                          total -
                          ((lmiapf.totalCategoryPrice ? lmiapf.totalCategoryPrice : 0) +
                            (lmiagf.totalCategoryPrice ? lmiagf.totalCategoryPrice : 0)) -
                          gf -
                          (verificationDocusign === true ? discountPriceNoLMIA : 0)
                        const contractgf = gf
                        const contracTotalNoLmia =
                          payment === 0
                            ? 0
                            : total -
                              ((lmiapf.totalCategoryPrice ? lmiapf.totalCategoryPrice : 0) +
                                (lmiagf.totalCategoryPrice ? lmiagf.totalCategoryPrice : 0)) -
                              discountPriceNoLMIA
                        console.log('pf', contractpf, 'gf', contractgf, 'total', contracTotalNoLmia)
                        const contractLMIApf =
                          (lmiapf.totalCategoryPrice ? lmiapf.totalCategoryPrice : 0) -
                          (verificationDocusign === true ? discountPriceLMIA : 0)
                        const contractLMIAgf = lmiapf.totalCategoryPrice
                          ? lmiagf.totalCategoryPrice
                          : 0
                        const contracTotalLmia =
                          payment === 0
                            ? 0
                            : (lmiapf.totalCategoryPrice ? lmiapf.totalCategoryPrice : 0) +
                              (lmiagf.totalCategoryPrice ? lmiagf.totalCategoryPrice : 0) -
                              (verificationDocusign === true ? discountPriceLMIA : 0)

                        console.log(
                          'pfLMIA',
                          contractLMIApf,
                          'gfLMIA',
                          contractLMIAgf,
                          'totalLMIA',
                          contracTotalLmia
                        )

                        /*  if (comboNameExistsForService) {
                                                urlvar.push(`https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9d8ca94d-4b4f-41eb-8874-1ac86c6aef8d&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_UserName=${formResponses.mainContactName}&Client_name=${formResponses.mainContactName}&Client_pf=${formatter.format(contractpf ? contractpf : 0)}&Client_gf=${formatter.format(contractgf ? contractgf : 0)}&Client_tc=${formatter.format(contracTotalNoLmia ? contracTotalNoLmia : 0)}&Client_Email=${formResponses.mainContactEmail}&Client_employer_name=${employername}&Client_employername=${employername}&Client_employeraddress=${employeraddress}&Client_employerphone=${employerphone}&Client_matchId=${matchId}&Client_workersemails=${employeeEmails}&Client_employeremail=${mainemail}&Client_mainname=${mainname}&Client_mainphone=${mainphone}&Client_paymentfixed=${contracTotalNoLmia}&Client_date=${date}&ActivateOnly=1`)
                                            }

                                            if (comboForRetainer) {
                                                urlvar.push(`https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c7707a19-5f49-4089-bfcc-143e997bb9a5&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_UserName=${formResponses.mainContactName}&Client_name=${formResponses.mainContactName}&Client_pf=${formatter.format(contractLMIApf ? contractLMIApf : 0)}&Client_gf=${formatter.format(contractLMIAgf ? contractLMIAgf : 0)}&Client_tc=${formatter.format(contracTotalLmia ? contracTotalLmia : 0)}&Client_Email=${formResponses.mainContactEmail}&Client_employer_name=${employername}&Client_employername=${employername}&Client_employeraddress=${employeraddress}&Client_employerphone=${employerphone}&Client_workersemails=${employeeEmails}&Client_matchId=${matchId}&Client_employeremail=${mainemail}&Client_mainname=${mainname}&Client_mainphone=${mainphone}&Client_paymentfixed=${contracTotalLmia}&Client_date=${date}&ActivateOnly=1`)
                                            }
 */
                        //esto se hizo por que ya no se quieren dos contratos, solamente uno , asi que tuve que sumar los precios de los dos
                        const finalPf =
                          (contractpf ? contractpf : 0) + (contractLMIApf ? contractLMIApf : 0)
                        const finalGf =
                          (contractgf ? contractgf : 0) + (contractLMIAgf ? contractLMIAgf : 0)
                        const finalTc =
                          (contracTotalNoLmia ? contracTotalNoLmia : 0) +
                          (contracTotalLmia ? contracTotalLmia : 0)
                        const finalPaymenTf =
                          (contracTotalNoLmia ? contracTotalNoLmia : 0) +
                          (contracTotalLmia ? contracTotalLmia : 0)

                        urlvar.push(
                          `https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9d8ca94d-4b4f-41eb-8874-1ac86c6aef8d&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_UserName=${
                            formResponses.mainContactName
                          }&Client_name=${
                            formResponses.mainContactName
                          }&Client_pf=${formatter.format(
                            finalPf ? finalPf : 0
                          )}&Client_gf=${formatter.format(
                            finalGf ? finalGf : 0
                          )}&Client_tc=${formatter.format(finalTc ? finalTc : 0)}&Client_Email=${
                            formResponses.mainContactEmail
                          }&Client_employer_name=${employername}&Client_employername=${employername}&Client_employeraddress=${employeraddress}&Client_employerphone=${employerphone}&Client_matchId=${matchId}&Client_workersemails=${employeeEmails}&Client_employeremail=${mainemail}&Client_mainname=${mainname}&Client_mainphone=${mainphone}&Client_paymentfixed=${finalPaymenTf}&Client_date=${date}&ActivateOnly=1`
                        )

                        seturls(urlvar)
                        console.log(urlvar)
                        const finalObject: IfinalObject = {
                          props: {
                            companyName: formResponses.companyName,
                            companyAddress: formResponses.companyAddress,
                            companyPhone: formResponses.companyPhone,
                            mainContactName: formResponses.mainContactName,
                            mainContactEmail: formResponses.mainContactEmail,
                            mainContactPhone: formResponses.mainContactPhone,
                            envelopeId: inputEnvelopeID, // Assuming inputEnvelopeID is defined elsewhere
                            paymentType: answerPayment, // Assuming answerPayment is defined elsewhere
                            combosInfo: pricesDescriptionComboClean, // Assuming pricesDescriptionComboClean is an array
                            employersInfo: employees, // Assuming employees is an array
                          },
                          match_id: matchId, // Assuming match_id needs to be set
                          email: formResponses.mainContactEmail, // Assuming mainContactEmail is defined elsewhere
                        }

                        await saveEmployer(finalObject)
                        setSent(true)
                      }}
                      className='startbutton-disc2FamGroup-firmar'
                      style={{
                        width: '14rem',
                        backgroundColor: '#2CD673',
                        color: 'white',
                        height: '4rem',
                        fontSize: '1rem',
                      }}
                    >
                      <strong>¡Quiero contratar!</strong>
                    </Button>
                  )}{' '}
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      {sent === true ? (
        <>
          {urls?.map((urlstring: string, index: number) => (
            <React.Fragment key={index}>
              {/* <div>{urlstring}</div> */}
              <Frames urlString={urlstring}></Frames>
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <div
            className='nav-bar1'
            style={{
              position: 'fixed',
              zIndex: 1000,
              width: '100%',
              height: '30px',
              marginBottom: '10px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1 className='text-block-25'>
              Immiland lo tiene <strong>todo</strong>
            </h1>
            <div style={{marginTop: '18px'}} className='nav-top-social-2'>
              <a
                href='https://www.youtube.com/immiland'
                target='_blank'
                className='icon-social-media'
              >
                <img src={youtube} alt='Link to youtube'></img>
              </a>
              <a
                href='https://www.instagram.com/immilandlaw/'
                target='_blank'
                className='icon-social-media'
              >
                <img src={instagram} alt='Link to instagram'></img>
              </a>
              <a
                href='https://www.facebook.com/immilandcanada'
                target='_blank'
                className='icon-social-media'
              >
                <img src={facebook} alt='Link to facebook'></img>
              </a>
              <a href='https://x.com/immilandcanada' target='_blank' className='icon-social-media'>
                <img src={twitter} alt='Link to twitter'></img>
              </a>
              <a
                href='https://www.tiktok.com/@immilandcanada'
                target='_blank'
                className='icon-social-media'
              >
                <img src={tiktok} alt='Link to tiktok'></img>
              </a>
              <a
                href='https://www.linkedin.com/company/immiland-canada/posts/?feedView=all'
                target='_blank'
                className='icon-social-media'
              >
                <img src={linkedin} alt='Link to linkedin'></img>
              </a>
            </div>
          </div>
          <div
            className='mb-5 pl-5 pr-5'
            style={{
              fontFamily: 'CocogoosePro, sans-serif',
            }}
          >
            {renderStepContent()}
          </div>
          <footer className='footer-style'>
            <div className='footer-style-continer'>
              <div
                style={{
                  width: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>¿Quienes somos?</strong>
                </h3>
                <a href='https://www.immilandcanada.com/quienes-somos'>Historia y Valores</a>
                <a href='https://www.immilandcanada.com/equipo-immiland'>Nuestro Equipo</a>
                <a href='https://www.immilandcanada.com/testimonios'>Testimonios</a>
                <a href='https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#'>
                  Preguntas Frecuentes
                </a>
                <a href='https://www.immilandcanada.com/terminos-y-condiciones'>
                  <strong>Términos y condiciones.</strong>
                </a>
              </div>
              <div
                style={{
                  width: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>Servicios</strong>
                </h3>
                <a href='https://www.immilandcanada.com/migracion/inicio'>Migración</a>
                <a href='https://www.immilandcanada.com/reclutamiento/inicio'>Trabajo</a>
                <a href='https://www.immilandcanada.com/educacion/inicio'>Educacion</a>
                <a href='https://www.immilandcanada.com/traduccion/inicio'>Traducciones</a>
                <a href='https://www.immilandcanada.com/establecimiento/inicio'>Establecimiento</a>
              </div>
              <div
                style={{
                  width: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>Ubicación</strong>
                </div>
                <div>
                  <a href='https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share'>
                    <div>
                      2025 Rue Parthenais #320
                      <br />
                      Montreal, Quebec
                      <br />
                      H2K 3T2, Canadá
                    </div>
                  </a>
                </div>
              </div>
              <div
                style={{
                  width: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>Blog</strong>
                </h3>
                <div>
                  <a href='https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio'>
                    <div>
                      ¿Puedo viajar fuera <br />
                      de Canada mientras estudio?
                    </div>
                  </a>
                </div>
              </div>
              <div
                style={{
                  width: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                    <strong>¡Sigamos en contacto!</strong>
                  </h3>
                  <div>
                    <span>Info@immilandcanada.com</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      paddingRight: '2rem',
                      alignItems: 'center',
                    }}
                  >
                    <a href='https://www.youtube.com/immiland'>
                      <img
                        src='https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png'
                        alt=''
                      />
                    </a>
                    <a href='https://www.instagram.com/immilandcanada/'>
                      <img
                        src='https://img.icons8.com/windows/25/ffffff/instagram-new.png'
                        alt=''
                      />
                    </a>
                    <a href='https://www.facebook.com/immilandcanada'>
                      <img
                        src='https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png'
                        alt=''
                      />
                    </a>
                    <a href='https://www.tiktok.com/@immilandcanada'>
                      <img
                        src='https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png'
                        alt=''
                      />
                    </a>
                    <a href='https://ca.linkedin.com/company/immiland-canada'>
                      <img
                        src='https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png'
                        alt=''
                      />
                    </a>
                    <a href='https://twitter.com/immilandcanada'>
                      <img src='https://img.icons8.com/material/25/ffffff/twitter--v2.png' alt='' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  )
}
export default observer(Shopping)
