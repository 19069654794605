/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'
import MembersFormVisitor from 'FormBuilder/components/FamilyModule/MembersData/visitor-visa/MembersFormVisitor'

interface IProps {
	products: Product[]
}
const CalculateSpousePhaseTwo: React.FC<IProps> = ({
	products
}) => {
	const spouse = products.find(element => element.contratationProductId == "136")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSpouseExEntry, getSpouseExEntry, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member,index) => {
		if (principalUsed()?.phaseexpress==="fase2" && member.type===FamilyTypes.conyuge) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	})
	if (spouse !== undefined) {
		setSpouseExEntry({
			id: spouse.id,
			productContratationId: spouse.contratationProductId,
			description: spouse.description,
			totalFee: countwp * spouse.fee,
			name: spouse.name,
			quantity: countwp,
			fee: spouse.price,
			memberInfo:arrayOfMembers,
            type:'pf'
		})
	}
	return (
		<>
			{getSpouseExEntry().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSpouseExEntry().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSpouseExEntry().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getSpouseExEntry().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSpouseExEntry().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSpousePhaseTwo)
