/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateStudyPermitAcademic: React.FC<IProps> = ({
	products
}) => {
	const traditional = products.find(element => element.contratationProductId === "4")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setCalculatestudyPermitAcademicDetails,getsetCalculatestudyPermitAcademicDetails } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {

		if (member.type == FamilyTypes.principal && member.typeOfStudyPermit === "Permiso de estudios programas académicos (más de 6 meses)" && member.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional") {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (traditional !== undefined) {
		setCalculatestudyPermitAcademicDetails({
			id: traditional.id,
			productContratationId: traditional.contratationProductId,
			description: traditional.description,
			totalFee: counter * traditional.fee,
			name: traditional.name,
			quantity: counter,
			fee: traditional.price,
			memberInfo:arrayOfMembers


		})
	}

	return (
		<>
			{getsetCalculatestudyPermitAcademicDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getsetCalculatestudyPermitAcademicDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getsetCalculatestudyPermitAcademicDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getsetCalculatestudyPermitAcademicDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getsetCalculatestudyPermitAcademicDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateStudyPermitAcademic)
