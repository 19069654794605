/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext} from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateDependantStudyOverFiveExtention: React.FC<IProps> = ({
	products
}) => {
	const dependantPlusFive = products.find(element => element.contratationProductId === "32")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setDependantStudyPermitExtentionOverFiveDetails,getDependantStudyPermitExtentionOverFiveDetails,principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
        if(principalUsed()?.typeOfApplication==="Study Visa Extension" && member.type===FamilyTypes.children && member.ageForVisitorDependant===false && member.ageIndependentChildren===false){
        counter++
		const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
        }

		if(principalUsed()?.inCanada==="si"){
			if(member.type===FamilyTypes.children && principalUsed()?.typeOfApplication==="Work Visa Extension" && member.wantsBP!=="no"  && member.ageForVisitorDependant===false && member.ageIndependentChildren===false){
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if(principalUsed()?.inCanada==="si"){
			if(member.type===FamilyTypes.children && principalUsed()?.typeOfApplication==="PGWP" && member.wantsBP!=="no"  && member.ageForVisitorDependant===false && member.ageIndependentChildren===false){
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}




	})
	if (dependantPlusFive !== undefined) {
		setDependantStudyPermitExtentionOverFiveDetails({
			id: dependantPlusFive.id,
			productContratationId: dependantPlusFive.contratationProductId,
			description: dependantPlusFive.description,
			totalFee: counter * dependantPlusFive.fee,
			name: dependantPlusFive.name,
			quantity: counter,
			fee: dependantPlusFive.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getDependantStudyPermitExtentionOverFiveDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getDependantStudyPermitExtentionOverFiveDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getDependantStudyPermitExtentionOverFiveDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getDependantStudyPermitExtentionOverFiveDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getDependantStudyPermitExtentionOverFiveDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantStudyOverFiveExtention)
