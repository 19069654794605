/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/ban-types */
import { Uploaders } from "modules/uploaders/core/_models"

// create interface FamilyTypes with enum option  father and mother and children
export enum FamilyTypes {
  principal = 'Solicitante principal',
  conyuge = 'Conyuge o Pareja de hecho',
  children = 'Hija(o) dependiente',
  grandchildren = 'nieta(o)',
  otros = 'otros',
}

export interface IFamilys {
  [key: string]: any; 
  JSONAnswers?: string
  //
  id: any
  type: FamilyTypes
  name: string
  lastname: string
  age: number
  birthday: Date
  nationality: string
  hasBiometrics?: boolean
  academicRecord?: File
  coopLetter?: File
  biometricsForSelect?: string
  biometrics?: File
  statusEvidence?: File
  visaType: string
  valid?: boolean
  principal?: boolean
  eta?: string
  etaCountry?: boolean | false
  etaRejection?: string
  visaEU?: string
  groupId: string
  etaImmiland?: string
  validEta?: boolean | false
  canadianVisaRejection?: string
  email?: string
  address?: string
  phoneNumber?: string
  birthdayFormatted?: string
  monthDifference: number
  ageForBiometrics?: boolean
  ageIndependentChildren?: boolean
  //study
  quebec?: string
  ExpiredPermit?: string
  ExpiredPermitDate?: any
  ExpiredPermit90?: string
  ExpiredPermitRestoration?: string
  principalHasPathway?: string
  HasCaq?: string
  caqDocument?: File
  workPermit?: boolean | false
  normalVisitorVisaLogic?: boolean | false
  validPrincipalCaq?: boolean | false
  validDependantStudent?: boolean | false
  validAccompainedMinor?: boolean | false
  visaTypeFromSurvey?: string | undefined
  hasLoa?: string
  loaDocument?: File
  validateCAQquestions?: boolean
  //Work
  typeWorkPermit?: string
  partnerHasWorkStudyPermit?: string
  partnerTypeOfPermit?: string
  borderPackage?: boolean | false
  borderPackageForSelect?: string
  dliPGWP?: string
  aplicationType?: string
  hasLMIAAlready?: string
  ftaCountry?: boolean | false
  caqChildren?: boolean | false
  wantsBPValidationConyugue?: boolean
  wantsTypeOfApplicationValidationConyugue?: boolean
  employerName?: string
  employerEmail?: string
  companyName?: string
  //
  typeOfProcedure?: string
  NOC?: string
  //previous client
  isPreviousClient?: string
  previousContractDocument?: File
  previousContractNumber?: string
  //inside Canada Visitor extension
  insideCanada?: string
  wantsVisitorExtension?: string
  previousPermit?: File

  //inside Canada Study extension
  wantsStudyExtension?: string
  studentDirectStream?: string
  optionOfExtension?: string
  currentStatus?: string
  previousStatusPermit?: File
  wantsToApplyToAnyExtension?: string
  typeOfExtensionBaseOnCurrentStatus?: string
  //inside canada work extension
  wantsWorkExtension?: string

  //codigo de webiner
  discountCode?: string

  //transit visa
  wayToAnotherCountry?: string
  connectionCanadianAirport?: string
  transit48hours?: string

  //super Visa
  isParent?: string
  typeOfVisaSuper?: string

  //transit visa , super visa or normal visitor

  ageForVisitorDependant?: boolean
  ageForCAQMinor?: boolean
  typeOfVisitorVisa?: string
  hasInternetionalStop?: boolean
  internationalStopSelect?: string
  hasInternetionalConextion?: boolean
  internationalConextionSelect?: string
  stayFourtyeightHours?: boolean
  stayFourtyeightHoursSelect?: string
  hasCanadianParents?: boolean
  CanadianParentsForSelect?: string
  hasFinancialSupport?: boolean
  FinancialSupportForSelect?: string
  hasPrivateInsurance?: boolean
  PrivateInsuranceForSelect?: string
  isTransitVisa?: boolean
  isSuperVisa?: boolean
  financialLetter?: File
  privateInsurance?: File

  //2.0
  inCanada?: string
  countryOfCitizenship?: string
  countryOfCurrentResidency?: string
  civilStatus?: string
  appliedBefore?: string
  confirmEmail?: string
  validBiometrics?: string
  wantsAtip?: boolean
  AtipFee?: boolean
  wantsAddConyugue?: string
  wantsDependantChildren?: string
  howManyDependantChild?: number
  wantAddTravelDocument?: string
  contractEnvelopeID?: string
  validContract?: boolean
  dateOfBirth?: string
  wayChooseOnStudy?: string
  ApplyWithSpouse?: string
  documents: Uploaders[]
  haveEta?: string
  addEta?: string
  wantsBP?: string
  answers?: {}[]
  //Visitor
  purpose?: string
  purposeVisit?: string
  passportCountry?: string
  province?: string
  canadianChildren?: string
  lessThanSixMonths?: string
  citizenship?: string
  typeOfApplication?: string

  //study
  acceptedLOAInstitution?: string
  typeOfStudyPermit?: string
  typeOfApplicationOfStudyPermit?: string
  languageRequired?: string
  applyingAlone?: string
  studyOption?: string
  complementaryService?: string
  scenery?: string
  lessThanSixCaq?: string

  //work
  typeOfWorkPermit?: string
  typeOfClosedWorkPermit?: string
  wantAddLmiaPositive?: string
  wantCaq?: string
  wantValidationLmiaExemptJobOffer?: string
  typeOfStatusConyuge?: string
  proofStatus?: string
  elegiblePGWP?: string
  kindOfTeer?: string
  labourMarketImpactAssessment?: string

  //inside canada
  migratoryStatus?: string
  wantsTemporaryResidenceService?: string
  validacademicRecord?: string
  validcoopLetter?: string
  casePGWP?: string
  employletter?:boolean

  //Express Entry
  sponsorship?: string
  programAplication?: string
  programAplicationExplain?: string
  moreThan18?: string
  isRecident?: string
  isDisability?: string
  addOpenWork?: string
  addCSQ?: string
  addCSQChildren?: string
  typeOfClass?: string

  //permanent residence
  wantwp?: string
  phase?: string
  phaseexpress?: string
  pnpprogram?: string
  phasepnp?: string
  //quebec  pr  RSWP:
  expresion?: boolean
  csq?: boolean
  rpcsq?: boolean

  //quebec  PEQ:
  csqPEQ?: boolean
  rpcsqPEQ?: boolean

  //pr no quebec
  pn?: boolean
  prendorsement?: boolean

  //newQuestionETA
  havePreviousUSAVisa?:string
}


export interface IFamilyGroup {
  id: string
  index: number
  family: IFamilys[] | []
}

export interface IDescription {
  members: string
  price: number
}

export interface IDetails {
  id?: string
  productId?: string
  product: string
  description: IDescription[]
  price: number
}

export interface IProduct {
  id: string
  productContratationId?: string
  description: string
  totalFee: number
  name: string
  quantity: number
  fee: number
  memberInfo?: any[]
  type?: string
}
export const productInitialDetails: IProduct = {
  id: '',
  productContratationId: '',
  description: '',
  totalFee: 0,
  name: '',
  quantity: 0,
  fee: 0,
  type: ''
}

export const initialDetails = {
  id: '',
  productId: '',
  product: '',
  description: [
    {
      members: '',
      price: 0,
    },
  ],
  price: 0,
}

export const initialValues: IFamilys = {
  id: '',
  name: '',
  type: FamilyTypes.principal,
  lastname: '',
  nationality: '',
  age: 0,
  hasBiometrics: false,
  birthday: new Date(),
  visaType: '',
  groupId: '',
  monthDifference: -1,
  documents: [
    { name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
    { name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
    { name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
    { name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
    { name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
    { name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
  ]
}
