/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateTransitVisaFee: React.FC<IProps> = ({
	products
}) => {
	const transitVisa = products.find(element => element.contratationProductId == "91")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setTransitVisaDetailsFee, principalUsed, getTransitVisaDetailsFee } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countTransitVisas = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.typeOfApplication === "Visa de tránsito por Canadá" &&
			(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)) {
			countTransitVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Visa de tránsito por Canadá" && (ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined
			&& ((member.type === FamilyTypes.children &&
				member.ageIndependentChildren === false &&
				(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)) ||
				(member.type === FamilyTypes.conyuge &&
					(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined)))) {
			countTransitVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (transitVisa !== undefined) {
		setTransitVisaDetailsFee({
			id: transitVisa.id,
			productContratationId: transitVisa.contratationProductId,
			description: transitVisa.description,
			totalFee: countTransitVisas * transitVisa.fee,
			name: transitVisa.name,
			quantity: countTransitVisas,
			fee: transitVisa.price,
			memberInfo: arrayOfMembers

		})
	}

	return (
		<>
			{getTransitVisaDetailsFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getTransitVisaDetailsFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getTransitVisaDetailsFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getTransitVisaDetailsFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getTransitVisaDetailsFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateTransitVisaFee)
