/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { WorkingHoliday } from 'FormBuilder/components/FamilyModule/Data/WorkingHolidayCountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateWorkPermitHolidayFee: React.FC<IProps> = ({
	products
}) => {
	const workingHolidayFee = products.find(element => element.contratationProductId === "88")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setWorkingHolidayFee, getWorkingHolidayFee, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.purposeVisit === "Trabajo" &&
			principalUsed()?.typeOfClosedWorkPermit === "International Experience Canada work permit"
			&& (principalUsed()?.typeOfWorkPermit === "Postulación a Young Professionals" ||
				principalUsed()?.typeOfWorkPermit === "Postulación a International CoOp")) {
			if ((member.type === FamilyTypes.principal
				&& WorkingHoliday.find(
					(holidays) => holidays.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
			)) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})
	if (workingHolidayFee !== undefined) {
		setWorkingHolidayFee({
			id: workingHolidayFee.id,
			productContratationId: workingHolidayFee.contratationProductId,
			description: workingHolidayFee.description,
			totalFee: countwp * workingHolidayFee.fee,
			name: workingHolidayFee.name,
			quantity: countwp,
			fee: workingHolidayFee.price,
			memberInfo: arrayOfMembers

		})
	}
	return (
		<>
			{getWorkingHolidayFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getWorkingHolidayFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getWorkingHolidayFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getWorkingHolidayFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getWorkingHolidayFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateWorkPermitHolidayFee)
