
import React,{ useContext,useEffect,useState } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Description } from 'Components/Tables/description'

const cost = 0.04

const CalculateTransaccionFee = () => {
	const rootStore = useContext(RootStoreContext)
	const { setTotalTransactionFee,getTotalTransactionFee } = rootStore.familyStore
	const { totalPrice } = rootStore.familyStore
	const [price,setPrice] = useState(0)
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})
	useEffect(() => {
		loopGroups()
	},[])

	const loopGroups = () => {
		setPrice(totalPrice * cost)
		
		//summ all totalBiometricos ,  totalEta ,  totalFees ,  totalProfesional ,
		//setPrice(totalBiometricos?.price + totalEta.price + totalFees.price + totalProfesional.price)
		setTotalTransactionFee({
			product: 'Transaccion Fee',
			description: [
				{
					members: 'Transaccion Fee',
					price: price + price * cost,
				},
			],
			price: price + price * cost,
		})
	}

	return (
		<>
			<TableCell style={{}}>
				{' '}
				<Span style={{ fontWeight: 'bold' }}>Servicio</Span>Costo de la
				transacción
			</TableCell>
			<TableCell >
				<Span  style={{ fontWeight: 'bold' }}>Descripcion</Span>
				<Description>4% del monto total, tarifa aplicada por la plataforma de pago</Description>
			</TableCell>
			<TableCell style={{}}></TableCell>
			<TableCell className='border-b border-indigo-500'>
				{' '}
				<Span>Precio</Span>
				CAD  {formatter.format(getTotalTransactionFee())}
			</TableCell>
		</>
	)
}

export default observer(CalculateTransaccionFee)
