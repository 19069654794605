
import tw from 'tailwind-styled-components'

const Label = tw.label`
  block 
  tracking-wide
  text-gray-700
  text-xs
  font-bold
  mb-2
  mt-2
  h-8
  flex
 items-end
`
export {Label}
