/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateWorkPermitPositiveON: React.FC<IProps> = ({
	products
}) => {
	const wpPos = products.find(element => element.contratationProductId == "65")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setWorkPermitPositiveON,getWorkPermitPositiveON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
		if(member.inCanada==="no"){
		if ((member.type === FamilyTypes.principal
			&& member.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral"
			/* && (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined*/)
			||
			((member.type === FamilyTypes.principal
				&& member.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral")
				&& ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
				&& member.wantsBP === "no")) {
			countwp++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	}
	})
	if (wpPos !== undefined) {
		setWorkPermitPositiveON({
			id: wpPos.id,
			productContratationId: wpPos.contratationProductId,
			description: wpPos.description,
			totalFee: countwp * wpPos.fee,
			name: wpPos.name,
			quantity: countwp,
			fee: wpPos.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getWorkPermitPositiveON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getWorkPermitPositiveON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getWorkPermitPositiveON().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getWorkPermitPositiveON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getWorkPermitPositiveON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateWorkPermitPositiveON)
