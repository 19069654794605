/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCSQGovernmentApplicationFeeDependentChild : React.FC<IProps> = ({
	products
}) => {
	const dependant = products.find(element => element.contratationProductId === "126")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setCSQCSQGovernmentApplicationFeeDependentChild,getCSQGovernmentApplicationFeeDependentChild ,principalUsed} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
        if(member.type===FamilyTypes.children && (principalUsed()?.csqPEQ===true||principalUsed()?.csq===true)){
         counter++
		const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type

		}
		arrayOfMembers.push(memberInfo) 
        }

	})
	if (dependant !== undefined) {
		setCSQCSQGovernmentApplicationFeeDependentChild({
			id: dependant.id,
			productContratationId: dependant.contratationProductId,
			description: dependant.description,
			totalFee: counter * dependant.fee,
			name: dependant.name,
			quantity: counter,
			fee: dependant.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}

	return (
		<>
			{getCSQGovernmentApplicationFeeDependentChild().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCSQGovernmentApplicationFeeDependentChild().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>{getCSQGovernmentApplicationFeeDependentChild().description}
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span><Description>{getCSQGovernmentApplicationFeeDependentChild().quantity}</Description>
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCSQGovernmentApplicationFeeDependentChild().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCSQGovernmentApplicationFeeDependentChild)
