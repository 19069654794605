/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Select,
} from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import PersonalData from './PersonalData'


interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const StudyPlusSix: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [displayErrors, setDisplayErrors] = React.useState(false)
	const [showPopup, setShowPopup] = useState(false);
	const [showQuebecOptions, setShowQuebecOptions] = useState(false);

	useEffect(() => { }, [currentMember])

	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])

	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])

	useEffect(() => { }, [open])

	useEffect(() => { }, [principalUsed])

	useEffect(() => { }, [emailForUploaders])

	useEffect(() => {
		if (showPopup) {
			popupDisplayNoCase();
		}
	}, [showPopup]);

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}

	const popupCAQ = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				"<p>El Certificat d'acceptation du Québec (CAQ) es requerido para la mayoría de los estudiantes y trabajadores extranjeros temporales que tienen la intención de residir en la provincia de Quebec.</p>",
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupDisplayNoCase = () => {
		const checkboxText = 'ACEPTAR';
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html: `
		<p>Si usted desea aplicar con su cónyuge o pareja de hecho, por favor, tenga en cuenta que para que él o ella sea elegible a un permiso abierto de trabajo su carta de aceptación de la institución educativa NO DEBE ESTAR CONDICIONADA.</p>
		<input type="checkbox" id="checkbox-terms">
		<label for="checkbox-terms">${checkboxText}</label>
	  `,
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: '¡Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
			preConfirm: () => {
				const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
				if (!checkbox?.checked) {
					Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
					return false;
				}
			},
		});
	};

	return (
		<div className='my-4 h-100'>
			<Box>
				<>
					<form className='w-full' id='fromEl'>
						<div className='flex flex-wrap mb-6'>
							{/* start page 5 */}
							{step === 5 ? <>
								<div>
									<div>
										<div className='titledivquestions'>
											<h1>¿Usted es elegible para aplicar por la vía express (Student Direct Stream)? </h1>
										</div>
										<div className='containerbackandnextbuttons' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
											<div>
												<button type='button' className='rcorners2' onClick={() => {
													handleChange('typeOfApplicationOfStudyPermit', "Aplicar por la vía express (Student Direct Stream)")
												}}>
													Si
												</button>
											</div>
											<div>
												<button type='button' className='rcorners2' onClick={() => {
													setStep(8)
													handleChange('typeOfApplicationOfStudyPermit', "Aplicar por la vía tradicional")
												}}>
													No
												</button>
											</div>
										</div>

									</div>
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
										<Button
											onClick={() => setStep(4)}
											className='backbutton-disc2'>
											Atras
										</Button>
										{currentMember.typeOfApplicationOfStudyPermit === 'Aplicar por la vía express (Student Direct Stream)' &&
											<Button
											  type='button'
												className='startbutton-disc2'
												onClick={() => {
												setStep(11)
											}}>
												Verifique su elegibilidad
											</Button>}
									</div>

								</div>
							</> : null}
							{/* start page 5 */}

							{/* start page 7 */}
							{step === 7 ? <>
								<div>
									<div>
										<div className='titledivquestions'>
											<h1>Lea cuidadosamente los siguientes escenarios y seleccione el que más se ajuste al suyo. <br />Asegúrese de responder correctamente, de su respuesta dependerá el permiso emitido para su pareja. </h1>
										</div>
										<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
											<div className='scenario'>
												<div className='scenario-text'>
													<p><b>Escenario 1:</b> Pathway <b>online</b> (en curso o finalizado) y obtendrá <b>LOA SIN condiciones</b> 4 a 6 meses antes de la fecha de inicio de clases: Su pareja es elegible para aplicar a un permiso de trabajo abierto.</p>
												</div>
												<button className='rcorners2' style={{ height: '100px' }} onClick={() => {
													setStep(8)
													handleChange('scenery', "Escenario 1")
												}}>
													Escenario 1
												</button>
											</div>
											<div className='scenario'>
												<div className='scenario-text'>
													<p><b>Escenario 2:</b> Pathway <b>online</b> y obtendrá<b>LOA SIN condiciones</b> 1 a 2 meses antes de la fecha de inicio de clases: Su pareja AUN NO es elegible para aplicar a un permiso abierto de trabajo sin embargo es elegible para una visa de visitante o eTA.</p>
												</div>
												<button className='rcorners2' style={{ height: '100px' }} onClick={() => {
													setStep(8)
													handleChange('scenery', "Escenario 2")
												}}>
													Escenario 2
												</button>
											</div>
											<div className='scenario'>
												<div className='scenario-text'>
													<p><b>Escenario 3:</b> Pathway <b>presencial</b> y obtendrá <b>LOA SIN condiciones</b> al interior de Canada. Su pareja AUN NO es elegible para aplicar a un permiso abierto de trabajo sin embargo es elegible para una visa de visitante o eTA.</p>
												</div>
												<button className='rcorners2' style={{ height: '100px' }} onClick={() => {
													setStep(8)
													handleChange('scenery', "Escenario 3")
												}}>
													Escenario 3
												</button>
											</div>
										</div>
									</div>
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
										<Button
											onClick={() => setStep(8)}
											className='backbutton-disc2'>
											Atras</Button>
									</div>
								</div>
							</> : null}
							{/* start page 7 */}
							{/* start page 8 */}
							{step === 8 ? <>
								<div>
									<div>
										<BoxModal>
											<Label>Provincia de destino:</Label>
											<ProvinceSelector className='custom-input'
												handleChange={(e: any) => {
													{ e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
													if (e.target.value === 'Quebec') {
														setShowQuebecOptions(true);
													} else {
														setShowQuebecOptions(false);
													}
												}}
												value={currentMember.province || ''}
												label='Provinces'
											/>
											{!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
											</span>}
										</BoxModal>

									</div>
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
										<Button
											onClick={() => setStep(4)}
											className='backbutton-disc2'>
											Atras
										</Button>
										<Button
											type='button'
											onClick={() => {
												if (!currentMember.province) {
													setDisplayErrors(true)
												} else {
													setDisplayErrors(false)
													if (currentMember.province === 'Quebec') {
														setStep(9)
													} else if (currentMember.province) {
														setStep(10)
													}
												}
											}} className='startbutton-disc2'>
											Siguiente
										</Button>
									</div>
								</div>
							</> : null}
							{/* end page 8 */}
							{/* start page 9 */}
							{step === 9 ? <>
								{showQuebecOptions && step === 9 ? (
									<div>
										<div>
											<div className='titledivquestions'>
											<h1>Para solicitar un permiso de trabajo cerrado con LMIA y/o permiso de estudio para dependientes en Quebec, <br/> es necesario obtener previamente un &#160;
												<button type='button'
													onClick={() => popupCAQ()}
													style={{
														color: 'blue',
														textDecoration: 'underline'
													}}>CAQ</button><br/>
												¿Desea incluir este servicio?</h1>
											</div>
											<div className='containerbackandnextbuttons' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

												<span className='rcorners2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
													setStep(10)
													handleChange('studyOption', "Deseo incluir CAQ (Certificado de aceptación de Quebec)")
												}}>
													Si
												</span>
												<span className='rcorners2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
													setStep(10)
													handleChange('studyOption', "NO deseo incluir CAQ (Certificado de aceptación de Quebec)")
												}}>
													No
												</span>
											</div>
											<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop:'2rem' }}>
												<Button onClick={() => setStep(8)} className='backbutton-disc2'>
													Atras
												</Button>
											</div>
										</div>
									</div>
								) : null}
							</> : null}
							{/* end page 9 */}
							{step === 10 && (currentMember.studyOption === 'NO deseo agregar este servicio complementario para mi y los miembros de mi familia que aplique' || 'Si deseo agregar este servicio complementario para mi y los miembros de mi familia que aplique') && <PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
							{step === 11 && <div className='flex items-center'>
								<div className='w-full flex flex-col items-center' >
									<p style={{ fontSize: '1.3rem', color: 'black', width: '90%', padding:'30px' }}>Para ser elegible es, <b>imprescindible cumplir con TODOS los siguientes requisitos,</b> sin excepción:</p><br></br>
									<ul style={{ width: '90%', marginBottom: '2rem', padding:'30px' }}>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Ser residente legal viviendo en 1 de los siguientes países: Antigua y Barbuda, Brasil, China, Colombia, Costa Rica, India, Marruecos, Pakistán, Perú, Filipinas, Senegal, San Vicente y las Granadinas, Trinidad y Tobago, Vietnam. </p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Tener una carta de aceptación de una institución de aprendizaje postsecundaria designada (DLI).</p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Vivir fuera de Canadá cuando enviemos su aplicación.</p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Haber pagado la matrícula completa de su primer año de estudios.</p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Tener un Certificado de Inversión Garantizada (GIC) por mínimo CAD$10,000.</p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Tener sus notas certificadas de secundaria o postsecundaria más reciente.</p></li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸 Tener el puntaje mínimo requerido en las cuatro habilidades lingüísticas (hablar, escuchar, leer y escribir) para una de las pruebas de idioma calificativas. Las pruebas online no son aceptadas.</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> a.Canadian Academic English Language (CAEL) Test</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> b. Canadian English Language Proficiency Index Program (CELPIP)</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> c.International English Language Testing System (IELTS) Academic</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> d. International English Language Testing System (IELTS) General</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> e. Pearson Test of English (PTE) Academic</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> f. Test de connaissance du français (TCF) Canada</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> g. Test de connaissance du français (TCF) tout public</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> h. Test d’évaluation de français (TEF) Canada</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> i. Test d’évaluation de français (TEF) 5 épreuves</p>
											<p style={{ fontSize: '1rem', marginLeft: '1rem', marginBottom: '1rem' }}> j. Test of English as a Foreign Language (TOEFL) iBT</p>
										</li>
										<li> <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>🔸Al aplicar por esta vía el Gobierno de Canadá TRATA de procesar este tipo de solicitudes en un periodo de 20 días hábiles.</p></li>
									</ul>
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop:'2rem' }}>
										<Button
										type='button'
										className='startbutton-disc2'
										onClick={() => setStep(8)}>Aceptar
										</Button>
									</div>
								</div>
							</div>}
						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default StudyPlusSix
