/* eslint-disable no-empty */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'Components'
import {IIfs} from './types'
import React, {useEffect} from 'react'

import FormIfSelect from './FormIfSelect'



export const options = [
  {value: '', label: 'Selecione'},
  {value: '=', label: '='},
  {value: '<=', label: '<='},
  {value: '<', label: '<'},
  {value: '=>', label: '=>'},
  {value: '>', label: '>'},
  {value: '!=', label: '!='},
  {value: 'in', label: 'in'},
]

interface IProps {
  codeData: any
  updateData: any
  nodes: any
  allCodeData: any
}

const getAllForms = (allCodeData: any) => {
  const forms: any[] = []
  Object.keys(allCodeData).forEach((key) => {
    if (allCodeData[key].type === 'form') {
      try {
        if (allCodeData[key].data.id) {
          //if node exists in forms
          if (forms.find((form) => form.data.id === allCodeData[key].data.id)) {
            return
          }
          forms.push(allCodeData[key])
        }
      } catch (e) {}
    }
  })

  return forms
}

const IfPropertyEditor: React.FC<IProps> = ({codeData, updateData, allCodeData}) => {
  const [ifs, setIfs] = React.useState<IIfs[] | []>([])
  const [formSelect, setFormSelect] = React.useState<IIfs[] | []>([])

  useEffect(() => {
    if (codeData.data.length > 0) {
      setIfs(codeData.data)
    }
  }, [codeData])

  useEffect(() => {
    setFormSelect(getAllForms(allCodeData))
    updateData(ifs)
  }, [ifs])

  const type = codeData.type



  if (type === 'if') {
    return (
      <div>
        <Button
          onClick={() =>
            setIfs([
              ...ifs,
              {variable: '', value: '', operator: '', formId: '', title: 'Seleccione'},
            ])
          }
          variant={'contained'}
        >
          Add If
        </Button>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Form</TableCell>
                <TableCell>Variable</TableCell>
                <TableCell>Operador</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ifs.map((ifData, index) => {
                return (
                  <TableRow key={index} hover={true}>
                    <FormIfSelect forms={formSelect} setIfs={setIfs} index={index} ifs={ifs} />

                    <TableCell>
                      <select
                        value={ifData.operator}
                        onChange={(e) => {
                          ifs[index].operator = e.target.value
                          setIfs([...ifs])
                        }}
                      >
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <input
                        type='text'
                        value={ifData.value}
                        onChange={(e) => {
                          ifs[index].value = e.target.value
                          setIfs([...ifs])
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          ifs.splice(index, 1)
                          setIfs([...ifs])
                        }}
                        variant={'contained'}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </>
        <div></div>
      </div>
    )
  }

  return null
}

export default IfPropertyEditor
