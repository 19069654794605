/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateOpenWorkPermitHolderFederalFee: React.FC<IProps> = ({
	products
}) => {
	const openWorkPermitHolderFederalFee = products.find(element => element.contratationProductId === "61")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setOpenWorkPermitHolderFederalFee, principalUsed, getOpenWorkPermitHolderFederalFee } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countPermitHolderFederalFee = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.principal &&
			(principalUsed()?.sponsorship === 'spouseOrPartner' ||
				principalUsed()?.sponsorship === 'spousePartnerShildren') &&
			principalUsed()?.addOpenWork === 'si') {
			countPermitHolderFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
			principalUsed()?.languageRequired === 'no' &&
			principalUsed()?.scenery === 'Escenario 1') ||
			(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
				principalUsed()?.languageRequired === 'no' &&
				principalUsed()?.scenery === 'Escenario 1')) {
			if (member.type == FamilyTypes.conyuge) {
				countPermitHolderFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
			principalUsed()?.languageRequired === 'si') ||
			(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
				principalUsed()?.languageRequired === 'si')) {
			if (member.type == FamilyTypes.conyuge) {
				countPermitHolderFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (member.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá') {
			if (member.type === FamilyTypes.principal) {
				countPermitHolderFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type === FamilyTypes.conyuge
				&& (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined)
				||
				(member.type === FamilyTypes.conyuge &&
					principalUsed()?.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral")
				||
				(member.type === FamilyTypes.conyuge &&
					principalUsed()?.typeOfClosedWorkPermit === "ESTÁ EXENTA de una evaluación de impacto del mercado laboral")
				||
				member.type === FamilyTypes.conyuge && member.wantsBP !== "si" && principalUsed()?.typeOfApplication === "PGWP"
				||
				(((member.type === FamilyTypes.conyuge
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
					&& (ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === principalUsed()?.countryOfCitizenship?.toLocaleLowerCase())) === undefined))
				)) {
				countPermitHolderFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge && principalUsed()?.languageRequired === "si") {
			countPermitHolderFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.type === FamilyTypes.principal
			/* && member.wantsBP !== "si" */ &&
			member.typeOfApplication === "PGWP") {
			countPermitHolderFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.type === FamilyTypes.conyuge &&
			principalUsed()?.typeOfApplication === "PGWP" &&
			principalUsed()?.inCanada === "si") {
			countPermitHolderFederalFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.conyuge &&
				principalUsed()?.typeOfApplication === "Work Visa Extension" &&
				member.wantsBP !== "si") {
				countPermitHolderFederalFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})

	if (openWorkPermitHolderFederalFee !== undefined) {
		setOpenWorkPermitHolderFederalFee({
			id: openWorkPermitHolderFederalFee.id,
			productContratationId: openWorkPermitHolderFederalFee.contratationProductId,
			description: openWorkPermitHolderFederalFee.description,
			totalFee: countPermitHolderFederalFee * openWorkPermitHolderFederalFee.fee,
			name: openWorkPermitHolderFederalFee.name,
			quantity: countPermitHolderFederalFee,
			fee: openWorkPermitHolderFederalFee.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getOpenWorkPermitHolderFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getOpenWorkPermitHolderFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getOpenWorkPermitHolderFederalFee().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getOpenWorkPermitHolderFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getOpenWorkPermitHolderFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateOpenWorkPermitHolderFederalFee)
