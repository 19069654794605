/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'


import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCAQMinor: React.FC<IProps> = ({
	products
}) => {
	const caqMinor = products.find(element => element.contratationProductId === "40")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed, setCalculateCAQMinor, getCalculateCAQMinor } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		//Permiso de trabajo cerrado dentro de canada
		if (principalUsed()?.province === 'Quebec' &&
		principalUsed()?.purposeVisit !=="Visitar o transito" &&
			principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)" &&
			principalUsed()?.inCanada === "si" &&
			member.type == FamilyTypes.children &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.province === 'Quebec' &&
			principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.children &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false &&
			/* member.wantsBP !== "si" && */
			principalUsed()?.typeOfApplication === "PGWP" ) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.province === 'Quebec' &&
		principalUsed()?.purposeVisit !== "Residencia Permanente" &&
		principalUsed()?.inCanada === "si" &&
		member.type === FamilyTypes.children &&
		member.ageForCAQMinor === true &&
		member.ageForVisitorDependant === false &&
		member.ageIndependentChildren === false &&
		/* member.wantsBP !== "si" && */
		(principalUsed()?.typeOfClosedWorkPermit	=== "ESTÁ EXENTA de una evaluación de impacto del mercado laboral" ||
		principalUsed()?.typeOfClosedWorkPermit	=== "REQUIERE de una evaluación de impacto del mercado laboral"	) ) {
		countCAQ++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
	}

		if (principalUsed()?.province === 'Quebec' &&
			principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.children &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false &&
			member.wantsBP !== "si" &&
			principalUsed()?.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		//Permiso de trabajo cerrado dentro de canada
		if (principalUsed()?.inCanada === "no" &&
		principalUsed()?.purposeVisit !=="Visitar o transito" &&
		principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			member.type == FamilyTypes.children &&
			(principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"
				|| principalUsed()?.province === 'Quebec') &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (caqMinor !== undefined) {
		setCalculateCAQMinor({
			id: caqMinor.id,
			productContratationId: caqMinor.contratationProductId,
			description: caqMinor.description,
			totalFee: countCAQ * caqMinor.fee,
			name: caqMinor.name,
			quantity: countCAQ,
			fee: caqMinor.price,
			memberInfo: arrayOfMembers
		})
	}

	return (
		<>
			{getCalculateCAQMinor().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculateCAQMinor().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCalculateCAQMinor().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculateCAQMinor().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculateCAQMinor().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCAQMinor)
