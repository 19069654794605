import React from 'react'


import { Select } from 'Components/Select'

interface IProps {
	label: string
	value: string
	handleChange: (event: any) => void
}

interface ICivilStatus {
	"name": string
}

const CivilStatusSelector: React.FC<IProps> = ({ value,label,handleChange }) => {

	const status: ICivilStatus[] = [
		{ name: "Soltero" },
		{ name: "Casado" },
		{ name: "Legalmente separado" },
		{ name: "Divorciado" },
		{ name: "Matrimonio anulado" },
		{ name: "Viudo" },
		{ name: "Unión marital de hecho" }
	]

	return (
		<>
			<div>
				<Select
					id='civilStatus'
					name='civilStatus'
					defaultValues={value}
					className='custom-input'
					labelId='demo-simple-select-label'
					value={value}
					label={label}
					onChange={handleChange}
				>
					<option value=''>Seleccione</option>
					{status.map((status: ICivilStatus) => (
						<option key={status.name} value={status.name}>
							{status.name}
						</option>
					))}
				</Select>
			</div>
		</>
	)
}

export { CivilStatusSelector }
