/* eslint-disable no-empty-pattern */
import { FamilyTypes} from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateStudyLanguagePermitExtensionON: React.FC<IProps> = ({
	products
}) => {
	const studyPermitExtensionON = products.find(element => element.contratationProductId === "147")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setStudyLanguagePermitExtentionON, getStudyLanguagePermitExtentionON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
		familyMembers.map((member) => {
        if(member.typeOfApplication==="Study Visa Extension" &&
				member.typeOfStudyPermit === 'Permiso de estudios programas de idiomas (menos de 6 meses)' &&
				member.type===FamilyTypes.principal){
        counter++
		const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
        }

	})
	if (studyPermitExtensionON !== undefined) {
		setStudyLanguagePermitExtentionON({
			id: studyPermitExtensionON.id,
			productContratationId: studyPermitExtensionON.contratationProductId,
			description: studyPermitExtensionON.description,
			totalFee: counter * studyPermitExtensionON.fee,
			name: studyPermitExtensionON.name,
			quantity: counter,
			fee: studyPermitExtensionON.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getStudyLanguagePermitExtentionON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getStudyLanguagePermitExtentionON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getStudyLanguagePermitExtentionON().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getStudyLanguagePermitExtentionON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getStudyLanguagePermitExtentionON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateStudyLanguagePermitExtensionON)
