import { Span, TableCell } from 'Components'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculateBiometrics: React.FC<IProps> = ({ products }) => {
	const rootStore = useContext(RootStoreContext)
	const biometrics = products?.find(element => element.contratationProductId === "53")
	const familyBiometrics = products?.find(element => element.contratationProductId === "54")
	const { familyMembers, setBiometrics, getBiometrics, principalUsed } = rootStore.familyStore
	let auxPrice = 0
	let count = 0
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []

	familyMembers.map((member: any) => {

		if (familyMembers[0].typeOfApplication === "Ciudadania") {
			return
		}

		if (principalUsed()?.inCanada === "no" &&
			(principalUsed()?.havePreviousUSAVisa === 'si' ||
				principalUsed()?.typeOfApplication === "Electronic Travel Authorization - eTA")) {
			return
		}


		if (member.ageForBiometrics === true &&
			member.type === FamilyTypes.principal &&
			principalUsed()?.purposeVisit === "Trabajo" &&
			principalUsed()?.typeOfClosedWorkPermit === "International Experience Canada work permit" &&
			(principalUsed()?.typeOfWorkPermit === "Postulación a Young Professionals" ||
				principalUsed()?.typeOfWorkPermit === "Postulación a International CoOp")) {
			auxPrice += (biometrics) ? (biometrics.fee) : 85
			count++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			console.log("pasa por aca")
			arrayOfMembers.push(memberInfo)
		}

		if (member.wantsBP === "si" ||
			member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.purposeVisit === "Residencia Permanente") {
			//biometricos para PNP
			if (principalUsed()?.pnpprogram === 'RSWP' && principalUsed()?.rpcsq !== true) {
				console.log("pasa por aca")
				return
			}


			if (principalUsed()?.csqPEQ === true && principalUsed()?.rpcsqPEQ !== true) {
				console.log("pasa por aca")
				return
			}

			if (principalUsed()?.pn === true && principalUsed()?.prendorsement !== true) {
				console.log("pasa por aca")
				return
			}
			//

			if (member.ageForBiometrics === false) {
				console.log("pasa por aca")
				return
			}

			if (principalUsed()?.phase === "fase1" || principalUsed()?.phaseexpress === "fase1") {
				console.log("pasa por aca")
				return
			}

			if (member.ageForBiometrics === true  && auxPrice < (170) ) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca")
				arrayOfMembers.push(memberInfo)
				return
			}
		}

		if (principalUsed()?.inCanada === "no" &&
			principalUsed()?.typeOfApplication === "PGWP") {
			if (member.type === FamilyTypes.principal &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				return
			}
			if (member.type === FamilyTypes.conyuge
				&& (member.havePreviousUSAVisa === "no" || member.havePreviousUSAVisa === undefined) &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				console.log("pasa por aca")
				return
			}
			if (member.type === FamilyTypes.children
				&& (member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					member.ageIndependentChildren === false &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				console.log("pasa por aca")
				return
			}
		}


		if (principalUsed()?.inCanada === "no" &&
			principalUsed()?.purposeVisit === "Estudios") {
			if (member.type === FamilyTypes.principal &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					auxPrice < (170))) {
				console.log('dentro del aplicante principal')
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				return
			}
			if (member.type === FamilyTypes.conyuge &&
				(member.havePreviousUSAVisa === "no" || member.havePreviousUSAVisa === undefined) &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" && member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				console.log("pasa por aca")
				return
			}
			if (member.type === FamilyTypes.children
				&& (member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				(member.ageForBiometrics === true &&
					member.ageIndependentChildren === false &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
				console.log("pasa por aca")
				return
			}
		}

		if ((member.type === FamilyTypes.children) &&
			(/* ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined && */
				member.ageForBiometrics === true &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no")) &&
				auxPrice < (170) &&
				principalUsed()?.purposeVisit === "Estudios" &&
				principalUsed()?.wayChooseOnStudy === "StudyLessSix")) {
			auxPrice += (biometrics) ? (biometrics.fee) : 85
			count++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			console.log("pasa por aca")
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.purposeVisit !== 'Trabajo' &&
			principalUsed()?.inCanada === "no" &&
			principalUsed()?.typeOfApplication !== 'Express Entry') {
			if (((member.type === FamilyTypes.principal &&
				member.typeOfApplication !== "Electronic Travel Authorization - eTA" &&
				(member.appliedBefore === "no" ||
					(member.appliedBefore === "si" &&
						member.validBiometrics === "no") &&
					member.ageForBiometrics === true &&
					auxPrice < (170)))

				//logica de children cuando el principal va por estudio
				||
				(principalUsed()?.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
					((member.type === FamilyTypes.children) &&
						(member.ageForBiometrics === true &&
							member.ageIndependentChildren === false &&
							(member.appliedBefore === "no" ||
								(member.appliedBefore === "si" &&
									member.validBiometrics === "no")) &&
							auxPrice < (170))))
				|| ((member.type === FamilyTypes.conyuge) &&
					(member.havePreviousUSAVisa === "no" || member.havePreviousUSAVisa === undefined) &&
					((ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined &&
						member.ageForBiometrics === true &&
						(member.appliedBefore === "no" ||
							(member.appliedBefore === "si" &&
								member.validBiometrics === "no")) &&
						auxPrice < (170) &&
						principalUsed()?.purposeVisit === "Visitar o transito")
						//logica de children cuando el principal va por turismo
						|| ((member.type === FamilyTypes.children) &&
							(ETA.find(
								(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined &&
								member.ageForBiometrics === true &&
								member.ageIndependentChildren === false &&
								(member.appliedBefore === "no" ||
									(member.appliedBefore === "si" &&
										member.validBiometrics === "no")) &&
								auxPrice < (170) &&
								principalUsed()?.purposeVisit === "Visitar o transito"))
						||
						(ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined &&
							member.ageForBiometrics === true &&
							(member.appliedBefore === "no" ||
								(member.appliedBefore === "si" &&
									member.validBiometrics === "no")) &&
							auxPrice < (170) &&
							principalUsed()?.purposeVisit === "Estudios" &&
							principalUsed()?.wayChooseOnStudy === "StudyLessSix")

						||
						(member.ageForBiometrics === true &&
							(member.appliedBefore === "no" ||
								(member.appliedBefore === "si" &&
									member.validBiometrics === "no")) &&
							auxPrice < (170) &&
							principalUsed()?.purposeVisit === "Estudios" &&
							principalUsed()?.wayChooseOnStudy === "StudyPlusSix" &&
							principalUsed()?.languageRequired === "si")
						||
						(member.ageForBiometrics === true &&
							(member.appliedBefore === "no" ||
								(member.appliedBefore === "si" &&
									member.validBiometrics === "no")) &&
							auxPrice < (170) &&
							principalUsed()?.purposeVisit === "Estudios" &&
							principalUsed()?.wayChooseOnStudy === "StudyPlusSix" &&
							principalUsed()?.languageRequired === "no")))
				//conyuge ETA con biometrics david
				|| ((member.type === FamilyTypes.conyuge) &&
					(member.havePreviousUSAVisa === "no" || member.havePreviousUSAVisa === undefined) &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < (170) &&
					principalUsed()?.purposeVisit === "Visitar o transito"))
				||
				(member.type === FamilyTypes.children &&
					member.ageForBiometrics === true &&
					principalUsed()?.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
					(member.appliedBefore === "no" || (member.appliedBefore === "si" && member.validBiometrics === "no")) &&
					auxPrice < 170)
			) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca")
				arrayOfMembers.push(memberInfo)
			}

			// familyboimetrics for ETA inside Canada childrens lo hizo david

			if ((((member.type === FamilyTypes.children) &&
				((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					member.ageIndependentChildren === false &&
					auxPrice < (170) &&
					principalUsed()?.purposeVisit === "Visitar o transito" &&
					member.typeOfApplication !== "Electronic Travel Authorization - eTA"
				)))
				||
				((member.type === FamilyTypes.children) &&
					((ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined &&
						member.ageForBiometrics === true &&
						(member.appliedBefore === "no" ||
							(member.appliedBefore === "si" &&
								member.validBiometrics === "no")) &&
						member.ageIndependentChildren === true &&
						auxPrice < (170) &&
						principalUsed()?.purposeVisit === "Visitar o transito" &&
						member.typeOfApplication !== "Electronic Travel Authorization - eTA"
					))))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca")
				arrayOfMembers.push(memberInfo)
			}
			// familyboimetrics for ETA inside Canada childrens lo hizo david final
		}

		if (principalUsed()?.purposeVisit === 'Trabajo' &&
			principalUsed()?.typeOfApplication !== 'Express Entry') {
			if ((
				// Este es permiso de trabajo cerrado
				//este es de eta
				(principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' &&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < (170)))
				||
				(
					//Este es no eta
					(member.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' &&
						(ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined &&
						member.ageForBiometrics === true &&
						(member.appliedBefore === "no" ||
							(member.appliedBefore === "si" &&
								member.validBiometrics === "no")) &&
						auxPrice < (170)))
				||
				(principalUsed()?.typeOfWorkPermit === "Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense" &&
					member.type === FamilyTypes.children &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < 170)
				||
				(principalUsed()?.typeOfWorkPermit === "Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense" &&
					member.type === FamilyTypes.conyuge &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < 170)
				||
				// Este es permiso de trabajo Abierto
				(member.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < (170))
				||
				// Este es permiso de trabajo Abierto hijo
				(principalUsed()?.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
					member.type === FamilyTypes.children &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < 170)
				||
				(member.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
					member.ageForBiometrics === true &&
					(member.appliedBefore === "no" ||
						(member.appliedBefore === "si" &&
							member.validBiometrics === "no")) &&
					auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca")
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.inCanada == 'si' &&
			principalUsed()?.typeOfApplication !== 'Express Entry' &&
			principalUsed()?.typeOfApplication !== 'Co-op work permit') {
			if ((member.type === FamilyTypes.principal
				&& member.validBiometrics === "no"
				&& member.ageForBiometrics === true && auxPrice < (170))
				||
				(member.type === FamilyTypes.conyuge
					&& member.ageForBiometrics === true
					&& member.validBiometrics === "no"
					&& auxPrice < (170))
				||
				(member.type === FamilyTypes.children
					&& member.ageForBiometrics === true
					&& member.validBiometrics === "no"
					&& member.ageIndependentChildren === false
					&& auxPrice < (170))) {
				auxPrice += (biometrics) ? (biometrics.fee) : 85
				count++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca")
				arrayOfMembers.push(memberInfo)
			}
		}
	})

	if (biometrics !== undefined &&
		familyBiometrics !== undefined/*  && independantBiometrics !== undefined */) {
		if (count > 1) {
			setBiometrics({
				id: familyBiometrics.id,
				productContratationId: familyBiometrics.contratationProductId,
				description: familyBiometrics.description,
				totalFee: auxPrice,
				name: familyBiometrics.name,
				quantity: 1,
				fee: familyBiometrics.fee,
				memberInfo: arrayOfMembers,
				type: 'gf'
			})
		} else if (count === 1) {
			setBiometrics({
				id: biometrics.id,
				productContratationId: biometrics.contratationProductId,
				description: biometrics.description,
				totalFee: auxPrice,
				name: biometrics.name,
				quantity: 1,
				fee: biometrics.fee,
				memberInfo: arrayOfMembers,
				type: 'gf'
			})

		} else {
			setBiometrics({
				id: biometrics.id,
				productContratationId: biometrics.contratationProductId,
				description: biometrics.description,
				totalFee: auxPrice,
				name: biometrics.name,
				quantity: 0,
				fee: biometrics.fee,
				memberInfo: arrayOfMembers,
				type: 'gf'
			})
		}
	}

	return (
		<>
			{getBiometrics().quantity > 0 && (<>
				<TableCell style={{}}>
					<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
					{getBiometrics().name}
				</TableCell>
				<TableCell style={{ display: 'flex' }}>
					<Span>Descripcion</Span>{' '}<Description>{getBiometrics().description}</Description>
				</TableCell>
				<TableCell style={{}}>
					{' '}
					<Span>Cantidad </Span>
					{getBiometrics().quantity}
				</TableCell>
				<TableCell className='border-b border-indigo-500'>
					{' '}
					<Span>Precio </Span>
					CAD {formatter.format(getBiometrics().totalFee)}
				</TableCell>
			</>)}
		</>
	)
}

export default observer(CalculateBiometrics)
