
import tw from 'tailwind-styled-components'

interface SelectProps {
  labelId?: string
  label?: string
  defaultValue?: any
  defaultValues?: any
}
const Select = tw.select<SelectProps>`
  w-full
  text-xs
  rounded
  bg-rosa-100
  text-red-500
  p-2
  shadow-sm
  no-underline
  mb-3
  focus:outline-none
`
export {Select}
