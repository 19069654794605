

import { RootStore } from "../../stores/rootStore";
import axios from "axios";
import { configure } from "mobx";
import setupAxios from "../../setup/axios/SetupAxios";

configure({
  enforceActions: "never",
});

export default class AuthStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    setupAxios(axios);
  }
}
