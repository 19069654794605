/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import StudyPlusSix from './StudyPlusSix'
import StudyLessSix from './StudyLessSix'
import '../../../../../Components/Modal/modal.css'

interface IProps {
  closeModal: () => void
  edit: boolean
  step: number
  setStep: Dispatch<SetStateAction<number>>
}
const Study: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
  const rootStore = useContext(RootStoreContext)
  const {
    principalUsed,
    currentMember,
    handleChangeFamilyMember,
    getVisaTypeFromSurvey,
  } = rootStore.familyStore
  const [birthday, setBirthday] = React.useState(currentMember.birthday)
  const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
  const [etaCountry, setEtaCountry] = React.useState(currentMember.etaCountry)
  const [usVisa, setUSVisa] = React.useState(false)
  const [showEtaImmiland, setShowEtaImmiland] = React.useState(false)
  const [emailForUploaders, setEmailForUploaders] = React.useState('')
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [open, setOpen] = React.useState(true)

  useEffect(() => { }, [currentMember])

  useEffect(() => {
    if (currentMember.age > -1) handleChange('valid', true)
    handleChange('age', moment().diff(birthday, 'years', false))
  }, [birthday])

  useEffect(() => {
    validateShowBiometrics()
  }, [currentMember.age, edit])

  useEffect(() => { }, [open])

  useEffect(() => { }, [principalUsed])

  useEffect(() => { }, [emailForUploaders])

  const handleChange = (
    target: string,
    value: string | number | Date | File | boolean | undefined
  ) => {
    //const newFamily = {...family}
    const newFamily = Object.assign(currentMember, { [target]: value })
    handleChangeFamilyMember(newFamily)
  }

  const validateEtaCountry = (nationality: string) => {
    //validate if currentMember.nationality is in ETA array
    const isEtaCountry = ETA.find(
      (etas) => etas.country.toLocaleLowerCase() === nationality.toLocaleLowerCase()
    )
    setEtaCountry(false)
    setShowEtaImmiland(false)
    handleChange('etaCountry', false)
    handleChange('validEta', false)
    if (isEtaCountry) {
      handleChange('etaCountry', true)
      handleChange('validEta', true)
      setEtaCountry(true)
    }
    validateBrazil()
  }

  const validateBrazil = () => {
    if (currentMember.nationality.toLocaleLowerCase() === 'brazil') {
      setUSVisa(true)
      if (currentMember.etaRejection === 'si') {
        handleChange('validEta', false)
        setShowBiometricSelect(true)
        setShowEtaImmiland(false)
      } else {
        if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'no') {
          handleChange('validEta', false)
          setShowBiometricSelect(true)
          setShowEtaImmiland(false)
        } else if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'si') {
          handleChange('validEta', true)
          setShowBiometricSelect(false)
          //  handleChange('hasBiometrics', false)
          setShowEtaImmiland(true)
        }
      }
    } else {
      setUSVisa(false)
    }
  }

  const validateShowBiometrics = () => {
    setShowBiometricSelect(true)
    if (
      currentMember.type === FamilyTypes.principal &&
      currentMember.ageForBiometrics === true &&
      getVisaTypeFromSurvey() === 'visadeestudios'
    ) {
      setShowBiometricSelect(true)
      return
    }
    if (
      !(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
      currentMember.etaCountry === true &&
      currentMember.etaRejection === 'no'
    ) {
      setShowBiometricSelect(false)
      return
    }
    if (currentMember.ageForBiometrics === false) {
      setShowBiometricSelect(false)
      return
    }
    if (currentMember.etaRejection === 'si') {
      if (currentMember.ageForBiometrics === true) {
        setShowBiometricSelect(true)
      }
      return
    }
  }

  return (
    <div className='my-4 h-100' >
      <Box>
        <>
          <form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
            <div className='container-aplication'>
              <div className='flex flex-wrap -sm:mx-3 mb-6'>
                {/* start page 3 */}
                {step === 3 ? <div>
                  <div className='flex flex-wrap justify-center'>
                    <BoxModal>
                      <Label>Seleccione el país emisor de su pasaporte</Label>
                      <div >
                        <CountrySelector
                          handleChange={(e: any) => {
                            { e.target.value != '' && currentMember.type === FamilyTypes.principal ? handleChange('countryOfCitizenship', e.target.value) : null }
                            { e.target.value != '' ? handleChange('passportCountry', e.target.value) : handleChange('passportCountry', undefined) }
                            validateEtaCountry(e.target.value)
                          }}
                          value={currentMember.passportCountry || ''}
                          label='Seleccione el país emisor de su pasaporte'
                        />
                      </div>
                      {!currentMember.passportCountry && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                        Debe seleccionar el pais emisor  de su pasaporte
                      </span>}
                    </BoxModal>
                  </div>
                  <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                    <Button
                      onClick={() => setStep(2)}
                      className='backbutton-disc2'>
                      Atras
                    </Button>
                    <Button
                      type='button'
                      className='startbutton-disc2'
                      onClick={() => {
                        if (!currentMember.passportCountry) {
                          setDisplayErrors(true);
                        } else {
                          setDisplayErrors(false);
                          setStep(4)
                        }
                      }}>
                      Siguiente
                    </Button>
                  </div>
                  {/* end page 3 */}
                </div> : null}
              </div>
              {/* start page 4 */}
              {step === 4 ? <>
                <div>
                  <div>
                    <div className='titledivquestions'>
                      <h1>¿Qué clase de programa planea estudiar?</h1>
                    </div>
                    <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                      {currentMember.acceptedLOAInstitution === "si" ?
                        <div>
                          <button className='rcorners2' onClick={() => {
                            setStep(8)
                            handleChange('typeOfStudyPermit', "Permiso de estudios programas académicos (más de 6 meses)")
                            handleChange('wayChooseOnStudy', 'StudyPlusSix')
                            handleChange('typeOfApplicationOfStudyPermit', "Aplicar por la vía tradicional")
                          }}>
                            <p>Acádemico	 </p>
                          </button>
                        </div> :
                        <div>
                          <button className='rcorners2' onClick={() => {
                            setStep(8)
                            handleChange('typeOfStudyPermit', "Permiso de estudios programas académicos (más de 6 meses)")
                            handleChange('wayChooseOnStudy', 'StudyPlusSix')
                            handleChange('typeOfApplicationOfStudyPermit', "Aplicar por la vía tradicional")
                          }}>
                            <p>Acádemico	</p>
                          </button>
                        </div>
                      }

                      {currentMember.acceptedLOAInstitution === "si" ?
                        <div>
                          <button className='rcorners2' onClick={() => {
                            setStep(5)
                            handleChange('typeOfStudyPermit', "Permiso de estudios programas de idiomas (menos de 6 meses)")
                            handleChange('wayChooseOnStudy', 'StudyLessSix')
                          }}>
                            <p>Idiomas</p>
                          </button>
                        </div> :
                        <div>
                          <button className='rcorners2' onClick={() => {
                            setStep(5)
                            handleChange('typeOfStudyPermit', "Permiso de estudios programas de idiomas (menos de 6 meses)")
                            handleChange('wayChooseOnStudy', 'StudyLessSix')
                          }}>
                            <p>Idiomas</p>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: 'center', marginBottom: '60px', marginTop: '60px', paddingBottom: '50px' }}>
                    <Button
                      onClick={() => setStep(3)}
                      className='backbutton-disc2'>
                      Atras
                    </Button>
                  </div>
                </div>
              </> : null}
              {/* end page 4 */}
              {currentMember.typeOfStudyPermit === "Permiso de estudios programas académicos (más de 6 meses)" && <StudyPlusSix closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
              {currentMember.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)" && <> <StudyLessSix closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} /></>}
            </div>
          </form>
        </>
      </Box>
    </div>
  )
}
export default Study
