import React, {useContext, useEffect} from 'react'

import {Button} from 'Components'
import {RootStoreContext} from '../../../stores/rootStore'
import {observer} from 'mobx-react'

interface IProps {
  nodeId: string
  node: any[]
}
const PriceModule: React.FC<IProps> = ({nodeId}) => {
  const rootStore = useContext(RootStoreContext)
  const { addPrice,  nodesStore, increaseEstep} =
    rootStore.formBuilderStore

  useEffect(() => {
    getNodeData(nodeId)
  }, [])

  const getNodeData = (nodeId: string) => {
    const node = nodesStore.find((node: any) => node.id === nodeId)
    addPrice(node)
    increaseEstep()
    return {...node.data}
  }

  return (
    <div>
      {nodeId}
      <Button onClick={() => increaseEstep()}>Next</Button>
    </div>
  )
}

export default observer(PriceModule)
