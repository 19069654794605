/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IallSources,
	getCodeData,
	getNextNode,
	setAllSources,
	getNodeByType,
} from '../../utils'
import React,{ useContext,useEffect } from 'react'
import EndModule from '../EndModule'
import FamilyModule from '../FamilyModule'
import { INode } from '../../types/nodes'
import IfModule from '../IfModule'
import InformationModule from '../InformationModule'
import PriceModule from '../PriceModule'
import { RootStoreContext } from '../../../stores/rootStore'
import SurveyModule from '../SurveyModule'
import UploaderModule from '../UploaderModule'
import { observer } from 'mobx-react'
import EndEducationModule from '../EndEducationModule'
import SurveyEducationModule from '../SurveyEducationModule'

interface IProps {
	codeData: any
	nodes: any
}
const setAllNodes = (flow: any,codeData: any) => {
	const allnodes: INode[] = []
	if(flow && flow.length>0){
	const nodes = flow.forEach((node: any) => {
		if (node.type === 'form') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<SurveyModule nodeId={node.id} node={getCodeData(codeData,node.id)} data={node} />
				),
				data: [],
			})
		} else if (node.type === 'formeducation') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<SurveyEducationModule
						nodeId={node.id}
						node={getCodeData(codeData,node.id)}
						data={node}
					/>
				),
				data: [],
			})
		} else if (node.type === 'uploader') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<UploaderModule nodeId={node.id} node={getCodeData(codeData,node.id)} data={node} />
				),
				data: [],
			})
		} else if (node.type === 'information') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<InformationModule nodeId={node.id} node={getCodeData(codeData,node.id)} data={node} />
				),
				data: [],
			})
		} else if (node.type === 'family') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<FamilyModule
						//SetOpen={SetOpen}
						nodeId={node.id}
						node={getCodeData(codeData,node.id)}
						data={node}
					/>
				),
				data: [],
			})
		} else if (node.type === 'if') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: (
					<IfModule
						nodeId={node.id}
						node={getCodeData(codeData,node.id)}
						data={node}
						codeData={codeData}
					/>
				),
				data: [],
			})
		} else if (node.type === 'price') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: <PriceModule nodeId={node.id} node={getCodeData(codeData,node.id)} />,
				data: getCodeData(codeData,node.id),
			})
		} else if (node.type === 'start') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: <>start</>,
				data: [],
			})
		} else if (node.type === 'endeducation') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: <EndEducationModule />,
				data: [],
			})
		} else if (node.type === 'end') {
			allnodes.push({
				id: node.id,
				type: node.type,
				component: <EndModule />,
				data: [],
			})
		}
	})
}
	return allnodes
}
const StartModule: React.FC<IProps> = ({ codeData,nodes }) => {
	const rootStore = useContext(RootStoreContext)
	const [sources,setSources] = React.useState<IallSources[]>(setAllSources(nodes))
	const [initialNodes,setInitialNodes] = React.useState(setAllNodes(nodes,codeData))
	const {
		currentNode,
		nodesStore,
		step,
		setPreviousNode,
		setCurrentNode,
		setEstep,
		setNodesStore,
		setEnd,
		setIfResult,
		backbuttonClicked,
		clickbackbuttonFalse,
	} = rootStore.formBuilderStore

	const [start,setStart] = React.useState(false)
	const [familyNode,setFamilyNode] = React.useState<INode>()
	const [childrenTarguet,setChildrenTarguet] = React.useState<JSX.Element | null>(null)
	useEffect(() => {
		setAllData()
		setNodesStore(initialNodes)
		setPreviousNode(initialNodes.find((node: any) => node.id === 'node_0'))
		setCurrentNode(initialNodes.find((node: any) => node.id === 'node_0') as INode)
		},[initialNodes])

	const setAllData = () => {
		setStart(true)
		setEstep(1)
	}
	useEffect(() => {
		if (nodesStore) {
			getNextStepNode()
		}
		if (backbuttonClicked) {
			getBackStepNode()
			clickbackbuttonFalse()
		}
		if (step == 3) {
			setFamilyNode(currentNode)
		}
	},[step])

	const getBackStepNode = async () => {
		let back: any = []
		let form: any = []
		if (currentNode.type === 'end') {
			back = getNodeByType(nodes,'family')
			form = getNodeByType(nodes,'form')
			nodesStore.forEach((node: any) => {
				if (familyNode != undefined) {
					try {
						setPreviousNode(back)
						setCurrentNode(familyNode)
						setChildrenTarguet(familyNode.component)
					} catch (error) {
					}
				}
			})
		}
	}

	const getNextStepNode = async () => {
		let next: any = []
		if (step === 1) {
			next = getNextNode(sources,'node_0',null)
		} else {
			if (currentNode.type === 'if') {
				next = getNextNode(
					sources,
					currentNode.id,
					JSON.stringify(currentNode.data) === 'true' ? true : false
				)
				setIfResult(undefined)
			} else {
				next = getNextNode(sources,currentNode.id,null)
			}
		}

		nodesStore.forEach((node: any) => {
			try {
				if (node.id === next[0].target) {
					setPreviousNode(currentNode)
					setCurrentNode(node)
					setChildrenTarguet(node.component)
				}
			} catch (error) { }
		})
	}
	if (start) {
		setEnd()
	}
	const renderComponent = () => {
		return <>{childrenTarguet}</>
	}
	return renderComponent()
}

export default observer(StartModule)
