import React, { useContext} from 'react'

import {RootStoreContext} from '../../../stores/rootStore'
import SurveyForms from './SurveyForms'
import {observer} from 'mobx-react'


interface IProps {
  nodeId: string
  node: any
  data: any
}
const SurveyModule: React.FC<IProps> = ({nodeId, node}) => {
  const rootStore = useContext(RootStoreContext)
  const {updateDataNode, getNodeData, increaseEstep} = rootStore.formBuilderStore

  const onComplete = (survey: any) => {
    updateDataNode(survey.data, nodeId)
    increaseEstep()

  }

  return (
    <div>
      {/* {nodeId}
      SurveyModule... */}
      <SurveyForms
        json={node.data}
        onComplete={onComplete}
        data={getNodeData}
        edit={true}
        id={nodeId}
      />
    </div>
  )
}

export default observer(SurveyModule)
