/* eslint-disable no-empty */
import {
  IDetails,
  IEducationSurvey,
  IFamilyGroup,
  IFamilys,
  initialDetails,
  initialDetailsEducationSurvey,
  initialValues,
} from '../types'
import {action, makeObservable, observable} from 'mobx'
import {RootStore} from 'stores/rootStore'
import {configure} from 'mobx'


configure({
  enforceActions: 'never',
})

export default class EducationStore {
  rootStore: RootStore

  @observable familyMembers: IFamilys[] = []
  @observable familygroup: IFamilyGroup[] = []
  @observable currentMember: IFamilys = initialValues
  @observable totalBiometricos: IDetails = initialDetails
  @observable totalEta: IDetails = initialDetails
  @observable totalFees: IDetails = initialDetails
  @observable totalProfesional: IDetails = initialDetails
  @observable totalCaq: IDetails = initialDetails
  @observable totalCaqWork: IDetails = initialDetails
  @observable totalMifi: IDetails = initialDetails
  @observable totalPoints: IDetails = initialDetails
  @observable totalTransactionFee: IDetails = initialDetails
  @observable totalProfesionalStudy: IDetails = initialDetails
  @observable totalFeeStudy: IDetails = initialDetails
  @observable visaTypeSurvey = ''
  @observable totalPrice = 0
  @observable countGovermentFeeETA = 0
  @observable countProfessionalFeeETA = 0
  @observable totalProfesionalFeeWork: IDetails = initialDetails
  @observable totalGovermentFeeWork: IDetails = initialDetails
  @observable totalCloseWorkPermitFee: IDetails = initialDetails
  @observable totalLMIAMIFIReviewFee: IDetails = initialDetails
  @observable totalLMIAExemption: IDetails = initialDetails
  @observable totalPreviousDiscount = 0
  @observable totalCanadaTaxFee: IDetails = initialDetails
  @observable resumeService = ''
  @observable isPreviousClient = false
  @observable answersJson = ''
  @observable answersEducationFormSurvey: IEducationSurvey = initialDetailsEducationSurvey
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action principalUsed = () => {
    //Search in familyMembers if principal is used
    return this.familyMembers.find((family) => family.principal === true)
  }

  @action numberOfGroupFamilies = () => {
    //Search in familyMembers if principal is used
    return this.familygroup.length
  }
  @action applicantsNames = () => {
    //Search in familyMembers if principal is used
    const output = []
    for (let i = 0; i < this.familyMembers.length; ++i) {
      if (this.familyMembers[i].etaImmiland === 'no') {
      } else {
        output.push(this.familyMembers[i].name + ' ' + this.familyMembers[i].lastname)
      }
    }
    const outputToString = output.toString()
    return outputToString
  }
  @action docusignData = () => {
    //Search in familyMembers if principal is used

    const today = new Date()
    const date: string = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const data = {
      biometrics: this.totalBiometricos,
      eta: this.totalEta,
      govermentFee:
        this.totalFees.price +
        this.countGovermentFeeETA +
        this.totalFeeStudy.price +
        this.totalGovermentFeeWork.price,
      profesionalFee:
        this.totalProfesional.price +
        this.countProfessionalFeeETA +
        this.totalProfesionalStudy.price +
        this.totalCaq.price +
        this.totalProfesionalFeeWork.price,
      resume: this.resumeService,
      currentDate: date,
      mifi: this.totalMifi.price > 0 ? this.totalMifi.price : 0,
    }
    return data
  }

  @action setFamilyMember = (family: IFamilys[]) => {
    this.familyMembers = family
  }
  @action setCurrentMember = (family: IFamilys) => {
    this.currentMember = family
  }

  @action handleChangeFamilyMember = (familyChange: IFamilys) => {
    const newFamilys = [...this.familyMembers]
    const index = newFamilys.findIndex((family: IFamilys) => family.id === familyChange.id)
    newFamilys[index] = familyChange
    this.familyMembers = newFamilys
    this.currentMember = familyChange

    //find index of familygroup dependein of family.groupId
    const indexFamilyGroup = this.familygroup.findIndex(
      (familygroup: IFamilyGroup) => familygroup.id === familyChange.groupId
    )

    //set familygroup.family dependein of family.groupId
    this.familygroup[indexFamilyGroup].family = this.familyMembers.filter(
      (family: IFamilys) => family.groupId === familyChange.groupId
    )
  }

  @action setFamilyGroup = (familygroup: IFamilyGroup[]) => {
    this.familygroup = familygroup
  }

  //set price of totalBiometricos
  @action setVisaTypeFromSurvey = (visaType: string) => {
    this.visaTypeSurvey = visaType
  }
  //set price of totalBiometricos
  @action getVisaTypeFromSurvey = () => {
    return this.visaTypeSurvey
  }

  @action setEducationAnswersFormSurvey = (clientInfo: IEducationSurvey) => {
    this.answersEducationFormSurvey = clientInfo
    this.calculateTotalPrice()
  }

  @action getEducationAnswersFormSurvey = () => {
    return this.answersEducationFormSurvey
  }
  @action setTotalPoints = (totalpoint: IDetails) => {
    this.totalPoints = totalpoint
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalBiometricos = (totalBiometricos: IDetails) => {
    this.totalBiometricos = totalBiometricos
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalEta = (totalEta: IDetails) => {
    this.totalEta = totalEta
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalFees = (totalFees: IDetails) => {
    this.totalFees = totalFees
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalProfesional = (totalProfesional: IDetails) => {
    this.totalProfesional = totalProfesional
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalCaq = (totalCaq: IDetails) => {
    this.totalCaq = totalCaq
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalMifi = (totalMifi: IDetails) => {
    this.totalMifi = totalMifi
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalProfesionalStudy = (totalProfesionalStudy: IDetails) => {
    this.totalProfesionalStudy = totalProfesionalStudy
    this.calculateTotalPrice()
  }

  @action setTotalFeesStudy = (totalFeeStudy: IDetails) => {
    this.totalFeeStudy = totalFeeStudy
    this.calculateTotalPrice()
  }

  //work
  @action setTotalProfesionalFeesWork = (totalProfesionalWork: IDetails) => {
    this.totalProfesionalFeeWork = totalProfesionalWork
    this.calculateTotalPrice()
  }
  //work
  @action setTotalGovermentFeesWork = (totalGovermentWork: IDetails) => {
    this.totalGovermentFeeWork = totalGovermentWork
    this.calculateTotalPrice()
  }

  //work
  @action setTotalCaqQuebecWork = (totalCaq: IDetails) => {
    this.totalCaqWork = totalCaq
    this.calculateTotalPrice()
  }
  //work
  @action setCloseWorkPermitFee = (totalCloseWorkPermitFee: IDetails) => {
    this.totalCloseWorkPermitFee = totalCloseWorkPermitFee
    this.calculateTotalPrice()
  }

  //work
  @action setLMIAMIFIReviewFee = (totalLMIAMIFIReviewFee: IDetails) => {
    this.totalLMIAMIFIReviewFee = totalLMIAMIFIReviewFee
    this.calculateTotalPrice()
  }

  //work
  @action setLMIAExemption = (totalLMIAExemption: IDetails) => {
    this.totalLMIAExemption = totalLMIAExemption
    this.calculateTotalPrice()
  }
  //set price of totalTransaction
  @action setTotalTransactionFee = (setTotalTransactionFee: IDetails) => {
    this.totalTransactionFee = setTotalTransactionFee
    this.calculateTotalPrice()
  }

  //set price of totalTransaction
  @action setTotalCanadaTaxFee = (setTotalCanadaTaxFee: IDetails) => {
    this.totalCanadaTaxFee = setTotalCanadaTaxFee
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action getTotalTransactionFee = () => {
    return this.totalPrice * 0.04
  }
  //set price of totalBiometricos
  @action getTotalCanadaTaxFee = () => {
    if (this.principalUsed()?.insideCanada === 'si') {
      return this.totalPrice * 0.14975
    } else {
      return 0
    }
  }

  //set price of totalTransaction
  @action setDiscount = (totalPreviousDiscount: IDetails) => {
    this.totalPreviousDiscount = totalPreviousDiscount.price
    this.calculateTotalPrice()
  }

  @action setAnswersJson = (answersJson: string) => {
    this.answersJson = answersJson
  }

  calculateTotalPrice = () => {
    this.totalPrice = this.totalPoints.price
  }

  @action setResumeService = (resumeService: string) => {
    this.resumeService = resumeService
  }

  @action setCountGovermentFeeETA = (numberOfETA: number) => {
    this.countGovermentFeeETA = numberOfETA
  }

  @action setCountProfessionalFeeETA = (numberOfETA: number) => {
    this.countProfessionalFeeETA = numberOfETA
  }
}
