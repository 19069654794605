import 'index.css'
import './i18n/i18n'

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import AppRoutes from './routes'
import React from 'react'
import ReactDOM from 'react-dom'

      
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
