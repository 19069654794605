/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculatePermanentResidenceSponsorshipFeeParents: React.FC<IProps> = ({
	products
}) => {
	const PermanentResidenceSponsorshipFeeParents = products.find(element => element.contratationProductId == "101")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed ,setResidenceSponsorshipFeeParents,getResidenceSponsorshipFeeParents} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
        if ((principalUsed()?.phase==='fase2' ||principalUsed()?.phase==='ambas' ||principalUsed()?.province!=='Quebec' ) &&  member.type===FamilyTypes.principal && principalUsed()?.sponsorship==='parents') {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
		if ((principalUsed()?.phase==='fase2' ||principalUsed()?.phase==='ambas'  ||principalUsed()?.province!=='Quebec'  ) &&  member.type===FamilyTypes.conyuge && principalUsed()?.sponsorship==='parents') {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
	})
	if (PermanentResidenceSponsorshipFeeParents !== undefined) {
		setResidenceSponsorshipFeeParents({
			id: PermanentResidenceSponsorshipFeeParents.id,
			productContratationId: PermanentResidenceSponsorshipFeeParents.contratationProductId,
			description: PermanentResidenceSponsorshipFeeParents.description,
			totalFee: countwp * PermanentResidenceSponsorshipFeeParents.fee,
			name: PermanentResidenceSponsorshipFeeParents.name,
			quantity: countwp,
			fee: PermanentResidenceSponsorshipFeeParents.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}
	return (
		<>
			{getResidenceSponsorshipFeeParents().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getResidenceSponsorshipFeeParents().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getResidenceSponsorshipFeeParents().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getResidenceSponsorshipFeeParents().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getResidenceSponsorshipFeeParents().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePermanentResidenceSponsorshipFeeParents)
