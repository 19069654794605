import { Flow } from 'modules/flows/core/_models'
import {IRequestUploadersParams, Uploaders, UploadersQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const UPLOADERS_URL = `${API_URL}/uploadertypes`
const USERS_URL = `${API_URL}/flows/sendEmailsForEmployer`
const PREVAL_URL = `${API_URL}/flows/sendEmailsForPreEvaluation`
const saveUploaders = (payload: object): Promise<Array<Uploaders>> => {
  return axios.post(`${UPLOADERS_URL}`, payload)
}




const sendEmailsForEmployer = (employees: { name: string, email: string, phoneNumber: string }[]): Promise<any> => {
  return axios.post(`${USERS_URL}`, { employees }).then((response: AxiosResponse) => {
    return response.data as Flow;
  });
};

const getUploaders = (params: IRequestUploadersParams): Promise<Array<Uploaders>> => {
  return axios
    .post(`${UPLOADERS_URL}/all`, {...params})
    .then((d: AxiosResponse<UploadersQueryResponse>) => {
      return d.data as Array<Uploaders>
    })
}

const getUploadersActivatedInClient = (userId: string): Promise<Array<Uploaders>> => {
  return axios
    .get(`${UPLOADERS_URL}/getlist/${userId}`)
    .then((d: AxiosResponse<UploadersQueryResponse>) => {
      return d.data as Array<Uploaders>
    })
}

const getUploadersNotActivatedInClient = (userId: string): Promise<Array<Uploaders>> => {
  return axios
    .get(`${UPLOADERS_URL}/getlistnot/${userId}`)
    .then((d: AxiosResponse<UploadersQueryResponse>) => {
      return d.data as Array<Uploaders>
    })
}

const deleteUploaders = async (uploadersId: string) => {
  return axios.delete(`${UPLOADERS_URL}/${uploadersId}`).then((response) => {
    return response.data as Uploaders
  })
}


/* Emails for preevaluations */

const sendEmailsPreEvaluation = async (email: string, template: number) => {
  return await axios
    .post(`${PREVAL_URL}`, {
      email,
      template
    })
    .then((response: AxiosResponse) => response.data)
};




export {
  saveUploaders,
  getUploaders,
  getUploadersActivatedInClient,
  getUploadersNotActivatedInClient,
  deleteUploaders,
  sendEmailsForEmployer,
  sendEmailsPreEvaluation
}
