/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateDependantLessFive: React.FC<IProps> = ({
	products
}) => {
	const dependant = products.find(element => element.contratationProductId == "29")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setDependantVisitorLessFive,principalUsed,getDependantVisitorLessFive } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countDependant = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(principalUsed()?.purposeVisit === "Residencia Permanente"){return}
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}

		if (principalUsed()?.inCanada==="no" &&
		principalUsed()?.purposeVisit !== "Trabajo" &&
		member.ageIndependentChildren===false &&
		(member.havePreviousUSAVisa === 'no' || member.havePreviousUSAVisa === undefined) &&
		member.type == FamilyTypes.children &&
		(member.ageForVisitorDependant || member.ageForVisitorDependant === undefined) &&
		(ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined)) {
		if(principalUsed()?.typeOfApplication !== "Visa de tránsito por Canadá"){
			countDependant++
			const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		}

		if (principalUsed()?.inCanada==="no" &&
		principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type == FamilyTypes.children
				&& member.wantsBP === "no"
				&& member.ageIndependentChildren===false
				&& (member.ageForVisitorDependant || member.ageForVisitorDependant === undefined)
				&&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()))
				||
				(member.type == FamilyTypes.children
					&& member.ageIndependentChildren===false
					&& (member.ageForVisitorDependant || member.ageForVisitorDependant === undefined)
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined)) {
				countDependant++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

	})
	if (dependant !== undefined) {
		setDependantVisitorLessFive({
			id: dependant.id,
			productContratationId: dependant.contratationProductId,
			description: dependant.description,
			totalFee: countDependant * dependant.fee,
			name: dependant.name,
			quantity: countDependant,
			fee: dependant.price,
			memberInfo:arrayOfMembers
		})
	}

	return (
		<>
			{getDependantVisitorLessFive().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getDependantVisitorLessFive().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getDependantVisitorLessFive().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getDependantVisitorLessFive().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getDependantVisitorLessFive().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantLessFive)
