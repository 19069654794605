import {Link as RLink} from 'react-router-dom'
import React from 'react'


interface IProps {
  to: string
  children: React.ReactNode
}

const Link: React.FC<IProps> = ({to, children}) => {
  return (
    <RLink
      to={to}
      className={`
		text-center
		font-bold
		text-sm
		text-tomato-500
		hover:text-tomato-500
`}
    >
      {children}
    </RLink>
  )
}

// export default index

// const Link = tw.Link`
//  to=''
//       className='text-center
// 		font-bold
// 		text-sm
// 		text-tomato-500
// 		hover:text-tomato-500'
//     />
// `

export {Link}
