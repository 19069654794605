import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import './paralax.css'
import Estudia from './assets/pexels-djpetty2-756790.jpg'
import RecidenceBlue from './assets/icon/jumbo-residencia.svg'
import Instalaciones from './assets/instalaciones.png'
import Certufications from './assets/certificacion.png'
import PGWP from './assets/pgwp.png'
import Avatar1 from './assets/avatar/avatar-a.svg'
import Avatar2 from './assets/avatar/avatar-b.svg'
import Avatar3 from './assets/avatar/avatar-c.svg'
import Avatar4 from './assets/avatar/avatar-d.svg'
import Avatar5 from './assets/avatar/avatar-e.svg'
import Avatar6 from './assets/avatar/avatar-f.svg'
import GoogleBusinessReviews from './GoogleBussinesReviews';
import { sendEmailsPreEvaluation } from 'modules/uploaders/core/_requests';

interface IProps {
  preEvaluationAnswers: any
}

const ResultEvaluationSix: React.FC<IProps> = ({ preEvaluationAnswers }) => {

  const sendEmails = async (email: string, template: number) => {
    try {
      await sendEmailsPreEvaluation(email, template);
    } catch (error) {
      console.error('Error enviando correos electrónicos:', error);
    }
  };

  useEffect(() => {
    console.log('Estoy mandando email')
    sendEmails(preEvaluationAnswers.email, 221)
  }, [])

  return (
    <div >
      <Parallax
        bgImageStyle={{ height: '90vh', maxWidth: '100%' }}
        className='paralax-image'
        bgImage={Estudia}
        strength={400}
      >
      </Parallax>
      <div className='paralax-header-icon-continer'>
        <img className='paralax-icon-header' style={{ width: '300px', top: '-110px' }} src={RecidenceBlue} alt="RecidenceBlue" />
        <div className='paralax-header-text-continer'>
          <h3 className='first-form-white-subtitle paralax-background-text-blue'></h3>
          <h1 className='first-form-white-title-header paralax-background-text-blue'>Sin status migratorio</h1>
        </div>
      </div>
      <section className='paralax-content-info-blue-residence'>
        <div className=' paralax-content-info-blue-text-container'
          style={{ marginTop: '3rem' }}>
          <div className='paralax-content-info-blue-text-container-inner'>
            <p className='paralax-content-info-blue-text-container-inner-subtitle'>Esperamos que su estadía en Canadá haya sido muy satisfactoria.</p>
            <p className='paralax-content-info-blue-text-container-inner-title'>Para continuar con su trayectoria en Canadá debemos determinar elementos como los siguientes:</p>
            <ul style={{ listStyleType: 'disc' }}>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Elegibilidad para aplicar a la residencia por medio de programas Federales.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Elegibilidad para aplicar a la residencia por medio de programas Provinciales.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Elegibilidad para extender ya sea un permiso de estudio/trabajo actual que posea.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Si es que su estatus migratorio caducó, explorar la posibilidad de aplicar para restauración de estatus.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Elegibilidad para aplicar a un permiso de trabajo post diploma en caso de que haya terminado o esté por terminar sus estudios en una institución educativa elegible.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Si posee una oferta de empleo, elegibilidad suya y de su empleador para  aplicar a un permiso de trabajo con patrocinio del empleador.</li>
              <li className='paralax-content-info-blue-text-container-inner-text'
                style={{ fontSize: '1rem' }}>
                Elegibilidad para sus familiares (si aplica).</li>
            </ul>
          </div>
          <iframe
            className='paralax-video'
            src='https://player.vimeo.com/video/842910868?h=58eeee7d4d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            allow='autoplay; fullscreen; picture-in-picture allowfullscreen'
            title='Bienvenidos a consulta' />
        </div>
      </section>
      <section className='paralax-content-info-yellow-gradient'>
        <div>
          <h1 className='first-form-white-title-header'
            style={{ fontWeight: 'normal', lineHeight: '1.15', fontSize: '70px' }}>¡Súmate!</h1>
          <p className='first-form-white-title-header'
            style={{ fontWeight: 'normal' }}>y comienza a <b>vivir tu aventura</b></p>
          <br />
          <p className='first-form-white-subtitle'>¿Cuál de los servicios te interesó?</p>
        </div>
        <div className='paralax-white-cards-continer'>
          <div className='flex flex-col gap-4'>
            <p className='paralax-white-cards-title'>Servicios de consultoría</p>
            <div className='flex gap-4 flex-wrap'>
              {/* producto */}
              <div className='paralax-white-cards'>
                <div className='paralax-white-cards-icons'
                  style={{ backgroundColor: '#F59460' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                    <path d="M22.8749 17.5H21.9V15.85C22.1999 15.925 22.5374 15.85 22.7624 15.5875C23.0624 15.2125 23.025 14.6875 22.65 14.3875L18.9749 11.3125C18.6749 11.05 18.2249 11.05 17.8874 11.3125L14.2125 14.3875C13.8375 14.6875 13.7999 15.2125 14.0999 15.5875C14.2499 15.775 14.5124 15.8875 14.7374 15.8875C14.8124 15.8875 14.8875 15.8875 14.9625 15.85V17.5H9.03745V15.85C9.11245 15.8875 9.18745 15.8875 9.26245 15.8875C9.48745 15.8875 9.74995 15.775 9.89995 15.5875C10.2 15.2125 10.1624 14.6875 9.78745 14.3875L6.11245 11.3125C5.81245 11.05 5.36245 11.05 5.02495 11.3125L1.34995 14.3875C0.974951 14.6875 0.937451 15.2125 1.23745 15.5875C1.46245 15.85 1.79995 15.925 2.09995 15.85V17.5H1.12495C0.674951 17.5 0.262451 17.875 0.262451 18.3625C0.262451 18.85 0.637451 19.225 1.12495 19.225H22.8749C23.3249 19.225 23.7374 18.85 23.7374 18.3625C23.7374 17.875 23.3249 17.5 22.8749 17.5ZM3.78745 17.5V14.5375L5.54995 13.075L7.31245 14.5375V17.5H3.78745ZM16.65 17.5V14.5375L18.4124 13.075L20.1749 14.5375V17.5H16.65Z" fill="#111928" />
                    <path d="M11.4749 12.9625C11.6249 13.075 11.8124 13.15 11.9999 13.15C12.1874 13.15 12.3749 13.075 12.5249 12.9625C13.0124 12.55 17.3249 8.9125 17.3249 6.1375C17.3249 3.2125 14.9249 0.8125 11.9999 0.8125C9.07493 0.8125 6.67493 3.2125 6.67493 6.1375C6.67493 8.9125 10.9874 12.55 11.4749 12.9625ZM11.9999 2.5C13.9874 2.5 15.6374 4.15 15.6374 6.1375C15.6374 7.4875 13.5749 9.775 11.9999 11.2C10.4249 9.775 8.36243 7.4875 8.36243 6.1375C8.36243 4.1125 10.0124 2.5 11.9999 2.5Z" fill="#111928" />
                    <path d="M14.4749 5.94998C14.4749 4.56248 13.3499 3.47498 11.9999 3.47498C10.6124 3.47498 9.5249 4.59998 9.5249 5.94998C9.5249 7.29998 10.6499 8.42497 11.9999 8.42497C13.3874 8.46247 14.4749 7.33748 14.4749 5.94998ZM11.2124 5.94998C11.2124 5.49998 11.5874 5.16248 11.9999 5.16248C12.4499 5.16248 12.7874 5.53748 12.7874 5.94998C12.7874 6.36248 12.4124 6.73747 11.9999 6.73747C11.5499 6.77497 11.2124 6.39998 11.2124 5.94998Z" fill="#111928" />
                  </svg>
                </div>
                <div className='paralax-white-cards-inner'>
                  <h2 className='paralax-white-cards-inner-title '>Cita con consultor</h2>
                  <p>Conversemos para barajar qué opciones son las mejores para usted.</p>
                  <a target="_blank"
                    rel="noopener noreferrer"
                    href='https://www.immilandcanada.com/consultas/%20cita-residencia-permanente-personas-dentro-de-canada'
                    className='paralax-white-cards-inner-buybutton'>
                    <p className='paralax-white-cards-inner-buybutton-text'>Ver más</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mb-24'>
        <div className='paralax-content-info-yellow'>
          <p className='first-form-white-subtitle'>¿Por qué contratar los servicios de
            &nbsp;<span style={{ color: '#CA5602' }}>Immiland?</span> </p>
        </div>
        <div className='paralax-icons-container'>
          <div className='paralax-card-group-item'>
            <img src={Instalaciones} alt="Instalaciones" />
            <p className='paralax-blue-text'>Contamos con acuerdos educativos con <b>más de 100 colleges, universidades e institutos</b> alrededor de Canadá</p>
          </div>
          <div className='paralax-card-group-item'>
            <img src={Certufications} alt="Certufications" />
            <p className='paralax-blue-text'>Ofrecemos centros de estudio reconocidos como <b>DLI (Designated Learning Institution)</b></p>
          </div>
          <div className='paralax-card-group-item'>
            <img src={PGWP} alt="PGWP" />
            <p className='paralax-blue-text'>Todas las opciones que ofrecemos son <b>válidas para elegibilidad al PGWP</b>  (Permiso de trabajo post graduiación).</p>
          </div>
        </div>
      </section>
      <section className='paralax-content-info-city'>
        <div className='flex gap-4 items-center'>
          <h1 className='first-form-white-title-header'>10.322</h1>
          <p className='first-form-white-subtitle '>ya han confiado en nosotros</p>
        </div>
        <div className='paralax-google-reviews-container-inner'>
          <div className='paralax-testimonies-container'>
            <div>
              <GoogleBusinessReviews />
            </div>
          </div>
          <div className='paralax-google-reviews-testimonies-container'>
            <div className='mt-40 flex flex-col items-center'>
              <img src={Avatar1} alt="Avatar1" />
              <div className='paralax-globes' />
            </div>
            <div className='mt-20 flex flex-col items-center'>
              <img src={Avatar2} alt="Avatar2" />
              <div className='paralax-globes' />
            </div>
            <div className='flex flex-col items-center'>
              <img src={Avatar3} alt="Avatar3" />
              <div className='paralax-globes' />
            </div>
            <div className='mt-40 flex flex-col items-center'>
              <img src={Avatar4} alt="Avatar4" />
              <div className='paralax-globes' />
            </div>
            <div className='flex flex-col items-center'>
              <img src={Avatar5} alt="Avatar5" />
              <div className='paralax-globes' />
            </div>
            <div className='mt-20 flex flex-col items-center'>
              <img src={Avatar6} alt="Avatar6" />
              <div className='paralax-globes' />
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};






export default ResultEvaluationSix;
