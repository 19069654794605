/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateVisitorVisaLessThanSixMonth: React.FC<IProps> = ({
	products
}) => {
	const visitorVisa = products.find(element => element.contratationProductId == "146")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setVisitorVisaLessThanSixMonths,principalUsed,getVisitorVisaDetailsLessThanSixMonths } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countVisitorVisas = 0
	const allowedCountries = [
		"Antigua and Barbuda",
		"Argentina",
		"Brazil",
		"Costa Rica",
		"Mexico",
		"Morocco",
		"Panama",
		"Philippines",
		"Saint Kitts and Nevis",
		"Saint Lucia",
		"Saint Vincent and the Grenadines",
		"Seychelles",
		"Thailand",
		"Trinidad and Tobago",
		"Uruguay"
	];
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (!member.validEta && !member.isSuperVisa && !member.isTransitVisa && member.lessThanSixMonths === 'si') {
			//si una persona como mexico , va por un curso de menos de 6 meses y tiene visa americana no le cobra
			if((member.typeOfApplication === "Visa de visitante") &&
			   member.countryOfCitizenship && 
			   allowedCountries.includes(member.countryOfCitizenship)&&
			   member.havePreviousUSAVisa === "si"){
				
				return
			}
			
			if ((member.typeOfApplication === "Visa de visitante")) { countVisitorVisas++ 
			
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log('pasa por aca')
				arrayOfMembers.push(memberInfo)
			
			}

			if ( principalUsed()?.typeOfApplication === "Visa de visitante" || principalUsed()?.typeOfApplication === "Electronic Travel Authorization - eTA") {
				if (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
					&&
					member.type === FamilyTypes.conyuge) { countVisitorVisas++
					const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log('pasa por aca')
				arrayOfMembers.push(memberInfo) }

				if (member.type === FamilyTypes.children && member.ageIndependentChildren===false && (member.ageForVisitorDependant === false)
					&&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) { countVisitorVisas++
							const memberInfo = {
								"memberName": member.name + " " + member.lastname,
								"memberType": member.type
							}
							console.log('pasa por aca')
							arrayOfMembers.push(memberInfo) }
			}
if (member.type === FamilyTypes.children && member.ageIndependentChildren===true 
	&& (member.appliedBefore === "no" || (member.appliedBefore === "si" && member.validBiometrics === "no"))
	
	&& principalUsed()?.purposeVisit === "Visitar o transito" 	&& member.typeOfApplication !== "Electronic Travel Authorization - eTA"
					&&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined) { countVisitorVisas++
							const memberInfo = {
								"memberName": member.name + " " + member.lastname,
								"memberType": member.type
							}
							console.log('pasa por aca')
							arrayOfMembers.push(memberInfo) }
		}


		if (principalUsed()?.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
				&&
				member.type === FamilyTypes.conyuge) { countVisitorVisas++ 
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					console.log('pasa por aca')
					arrayOfMembers.push(memberInfo)}
		}

		if (principalUsed()?.scenery === "Escenario 2" || principalUsed()?.scenery === "Escenario 3") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) === undefined
				&&
				member.type === FamilyTypes.conyuge) { countVisitorVisas++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					console.log('pasa por aca')
					arrayOfMembers.push(memberInfo) }
		}
	})
	if (visitorVisa !== undefined) {
		setVisitorVisaLessThanSixMonths({
			id: visitorVisa.id,
			productContratationId: visitorVisa.contratationProductId,
			description: visitorVisa.description,
			totalFee: countVisitorVisas * visitorVisa.fee,
			name: visitorVisa.name,
			quantity: countVisitorVisas,
			fee: visitorVisa.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getVisitorVisaDetailsLessThanSixMonths().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getVisitorVisaDetailsLessThanSixMonths().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getVisitorVisaDetailsLessThanSixMonths().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getVisitorVisaDetailsLessThanSixMonths().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getVisitorVisaDetailsLessThanSixMonths().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorVisaLessThanSixMonth)