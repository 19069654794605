import {IIfs, ISelectNames} from './types'
import React from 'react'

interface IProps {
  ifs: IIfs[]
  index: number
  setIfs: (ifs: any) => void
  allFields: Array<ISelectNames>
}

const OptionIfSelect: React.FC<IProps> = ({ifs, index, setIfs, allFields}) => {
  const getTitle = (name: string) => {
    const field = allFields.find((field: ISelectNames) => field.name === name)
    return field ? field.title : ''
  }

  return (
    <select
      onChange={(e) => {
        ifs[index].variable = e.target.value
        ifs[index].title = getTitle(e.target.value)
        setIfs([...ifs])
      }}
    >
      <option value={ifs[index].variable}>{ifs[index].title}</option>

      {allFields.map((item: ISelectNames, index: number) => {
        return (
          <option key={index} value={item.name}>
            {item.title}
          </option>
        )
      })}
    </select>
  )
}

export default OptionIfSelect
