
import tw from 'tailwind-styled-components'

interface TableRowProps {
  selected?: any
  hover?: any
}
const TableRow = tw.tr<TableRowProps>`
bg-gray-300  flex justify-between   md:text-left block md:table-cellder block sm:table-row  md:border-none
`

export {TableRow}
