/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */

import React, { FC, Fragment} from 'react'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from 'Components'
import {observer} from 'mobx-react'
import CalculatePoints from './CalculatePoints'
/* import MembersFormVisitor from './MembersFormVisitor'
import MembersFormStudy from './MembersFormStudy'
import MembersFormWork from './MembersFormWork' */


interface IProps {}

const MembersData: FC<IProps> = ({}) => {
  return (
    <>
      <div style={{width: '100%', height: '100%'}}>
        <Table style={{tableLayout: 'fixed', width: '100%'}}>
          <TableHead>
            <TableRow>
              <TableCell style={{display: 'block'}}>
                <div style={{marginTop: '20px'}}></div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  color: 'rgb(191 60 60)',
                  paddingTop: '30px',
                  paddingBottom: '30px',

                  backgroundColor: 'white',
                  fontFamily: 'Open Sans',
                  fontSize: '20px',
                  fontWeight: '1000',
                }}
              >
                Link
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <CalculatePoints />
            </TableRow>
          </TableBody>
        </Table>

        <TableFooter>
          <TableRow>
            <TableCell style={{display: 'block'}}>
              <div style={{marginTop: '20px'}}>
                {' '}
                <a
                // href={`https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5b08ee6f-ee1c-4bfe-91dc-23f2c6a779ee&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_date=${date}&Client_name=${applicantsNames()}&Client_UserName=${name}&Client_matter=${matter}&Client_professionalFees=${profesionalFees}&Client_governmentFees=${govermentFees}&Client_biometrics=${biometrics}&Client_mifiFees=${miFiFees}&Client_applicableTaxes=${applicableTaxes}&Client_transactionFee=${transactionFee}&Client_totalCost=${totalCost}&Client_clientName=${clientName}&Client_clientAddress=${clientAddress}&Client_clientPhone=${clientPhone}&Client_Email=${email}&Client_clientPaymentFixed=${totalCost}&ActivateOnly=1`}
                //  href={`https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=439c2a86-dfcc-4089-8a6c-2821ddd7aa09&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_date=${date}&Client_name=${applicantsNames()}&Client_UserName=${name}&Client_matter=${matter}&Client_professionalFees=${profesionalFees}&Client_governmentFees=${govermentFees}&Client_biometrics=${biometrics}&Client_mifiFees=${miFiFees}&Client_applicableTaxes=${applicableTaxes}&Client_transactionFee=${transactionFee}&Client_totalCost=${totalCost}&Client_clientName=${clientName}&Client_clientAddress=${clientAddress}&Client_clientPhone=${clientPhone}&Client_Email=${email}&Client_clientPaymentFixed=${totalCost}&ActivateOnly=1`}
                >
                  ¡ Ha llegado al final del proceso de educación !, si quiere continuar haz click en
                  el botón rojo
                </a>
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </div>
    </>
  )
}

export default observer(MembersData)
