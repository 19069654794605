/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext} from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCAQWorker: React.FC<IProps> = ({
	products
}) => {
	const caqWorker = products.find(element => element.contratationProductId == "39")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getCalculateCAQWorker, setCalculateCAQWorker} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {

		if (member.type == FamilyTypes.principal && member.wantCaq === "si") {
			countCAQ++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


	})
	if (caqWorker !== undefined) {
		setCalculateCAQWorker({
			id: caqWorker.id,
			productContratationId: caqWorker.contratationProductId,
			description: caqWorker.description,
			totalFee: countCAQ * caqWorker.fee,
			name: caqWorker.name,
			quantity: countCAQ,
			fee: caqWorker.price,
			memberInfo:arrayOfMembers
		})
	}

	return (
		<>
			{getCalculateCAQWorker().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculateCAQWorker().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCalculateCAQWorker().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculateCAQWorker().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculateCAQWorker().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCAQWorker)
