// jsx-a11y/anchor-is-valid

import AuthStore from '../auth/store/AuthStore'
import FamilyStore from 'FormBuilder/components/FamilyModule/Store/FamilyStore'
import FlowStore from '../modules/flows/store/FlowStore'
import FormBuilderStore from '../FormBuilder/store/FormBuilderStore'
import {createContext} from 'react'
import EducationStore from 'FormBuilder/components/EducationModule/Store/EducationStore'

export class RootStore {
  authStore: AuthStore
  formBuilderStore: FormBuilderStore
  flowStore: FlowStore
  familyStore: FamilyStore
  educationStore: EducationStore
  constructor() {
    this.authStore = new AuthStore(this)
    this.formBuilderStore = new FormBuilderStore(this)
    this.flowStore = new FlowStore(this)
    this.familyStore = new FamilyStore(this)
    this.educationStore = new EducationStore(this)
  }
}

export const RootStoreContext = createContext(new RootStore())
