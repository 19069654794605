import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateEta: React.FC<IProps> = ({
	products
}) => {
	const eta = products.find(element => element.contratationProductId === "55")
	const rootStore = useContext(RootStoreContext)
	const { familyMembers, setEtaFederalFee, getEtaFederalFee, principalUsed } = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
	const arrayOfMembers: any[] = []
	let countEtas = 0

	familyMembers.map((member) => {
		
		if (member.havePreviousUSAVisa === 'si') {
			countEtas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Visa de tránsito por Canadá" ||
		principalUsed()?.typeOfApplication === "Visa de visitante" ||
		principalUsed()?.typeOfApplication === "Electronic Travel Authorization - eTA") {
			if (member.type === FamilyTypes.children && member.ageIndependentChildren === false && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}

			/* if (member.type === FamilyTypes.conyuge && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			} */
			if (member.type === FamilyTypes.principal && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}

			//eta para niños mas de 21.5 años
			if (FamilyTypes.children && member.ageIndependentChildren === true
				&& (member.appliedBefore === "no" || (member.appliedBefore === "si" && member.validBiometrics === "no"))
				&& principalUsed()?.purposeVisit === "Visitar o transito" && member.typeOfApplication !== "Electronic Travel Authorization - eTA" && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {

				countEtas++
				console.log('eta de hijo adentro')
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		//study
		if (principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" && principalUsed()?.languageRequired === 'no' || principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" && principalUsed()?.languageRequired === 'no') {
			if (member.type == FamilyTypes.conyuge && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
			) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (member.type === FamilyTypes.children &&
			member.ageIndependentChildren === false &&
			(ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) &&
			member.ageForVisitorDependant === true &&
			principalUsed()?.purposeVisit === "Estudios") {
			{
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		// conyuge study option
		if (principalUsed()?.insideCanada === 'no' && principalUsed()?.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
				&&
				member.type === FamilyTypes.conyuge) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
		// conyuge scenary study option
		if (principalUsed()?.scenery === "Escenario 2" || principalUsed()?.scenery === "Escenario 3") {
			if (ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
				&&
				member.type === FamilyTypes.conyuge) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
		// children study option
		if (principalUsed()?.purposeVisit === "Trabajo" && member.type === FamilyTypes.children && member.ageIndependentChildren === false && (ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) && member.ageForVisitorDependant === true && principalUsed()?.purposeVisit === "Estudios") {
			{
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		//Work
		// children work option
		if (principalUsed()?.purposeVisit === "Trabajo") {
			if (member.type == FamilyTypes.children
				&& member.ageIndependentChildren === false
				&& member.ageForVisitorDependant === true
				/* && member.addEta === "si" */
				&&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if (member.type == FamilyTypes.children &&
				member.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
				member.ageIndependentChildren === true &&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
				countEtas++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			} else if (member.type == FamilyTypes.children &&
				member.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" &&
				member.ageIndependentChildren === false) {
				return; // Aquí puedes hacer un return vacío o cualquier acción que desees realizar
			}
		}
	})
	if (eta !== undefined) {
		setEtaFederalFee({
			id: eta.id,
			productContratationId: eta.contratationProductId,
			description: eta.description,
			totalFee: countEtas * eta.fee,
			name: eta.name,
			quantity: countEtas,
			fee: eta.fee,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getEtaFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getEtaFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getEtaFederalFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Quantity</Span>{getEtaFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio</Span>CAD {formatter.format(getEtaFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateEta)
