/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Select,
} from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import PersonalData from './PersonalData'
import Swal from 'sweetalert2'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const StudyLessSix: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
	} = rootStore.familyStore

	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [usVisa, setUSVisa] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const fileUploadRef = useRef(null)
	const [displayErrors, setDisplayErrors] = React.useState(false)

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}

			return
		}
	}

	const popupCAQ = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				"<p>El Certificat d'acceptation du Québec (CAQ) es requerido para la mayoría de los estudiantes y trabajadores extranjeros temporales que tienen la intención de residir en la provincia de Quebec.</p>",
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const [showQuebecOptions, setShowQuebecOptions] = useState(false);
	return (
		<div className='my-4 h-100'>
			<Box>
				<>

					<form className='w-full' id='fromEl'>
						<div className='flex flex-wrap -mx-3 mb-6'>
							{/* start page 3 */}
							{step === 5 ? <>
								<div>
									<div>
										<BoxModal style={{ marginLeft: '5rem' }}  >
											<Label>Provincia de destino:</Label>
											<ProvinceSelector className='custom-input'
												handleChange={(e: any) => {
													{ e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
													if (e.target.value === 'Quebec') {
														setShowQuebecOptions(true);
													} else {
														setShowQuebecOptions(false);
													}


												}}
												value={currentMember.province || ''}
												label='Provinces'


											/>
											{!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
											</span>}
										</BoxModal>

									</div>
									<div style={{ gap: '20px' }} className='containerbackandnextbuttons'>
										<Button onClick={() => setStep(4)} className='backbutton'>
											Atras</Button>
										<Button
											type='button'
											onClick={() => {
												if (!currentMember.province) {
													setDisplayErrors(true)
												} else {
													setDisplayErrors(false)
													if (currentMember.province === 'Quebec') {
														setStep(6)
													} else if (currentMember.province) {
														setStep(8)
													}
												}


											}} className='startbutton-disc2FamGroup'>
											Siguiente</Button>

									</div>
								</div>
							</> : null}
							{/* end page 3 */}
							{/* start page 3 */}
							{step === 6 ? <>
								<div>
									<div>
										<BoxModal>
											<Label>¿Su programa de idiomas tiene una duración de 22 semanas o más?</Label>
											<Select
												className='custom-input'
												name='Su programa de idiomas tiene una duración de 24 semanas o más o su carta de aceptación le exige obtener un CAQ'
												defaultValue={currentMember.lessThanSixCaq}
												labelId='demo-simple-select-label'
												label='Seguro privado'
												onChange={(e: any) => {
													{ e.target.value != '' ? handleChange('lessThanSixCaq', e.target.value) : handleChange('lessThanSixCaq', undefined) }
												}}
											>
												<option value=''>Seleccione</option>
												<option value='si'>Si</option>
												<option value='no'>No</option>
											</Select>
											{!currentMember.lessThanSixCaq && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
												Debe seleccionar si su programa de idiomas tiene una duración de 24 semanas o más  o su carta de aceptación le exige obtener un CAQ
											</span>}

										</BoxModal>

									</div>
									<div className='containerbackandnextbuttons'>
										<Button onClick={() => setStep(5)} className='backbutton'>
											Atras</Button>
										<Button
											type='button'
											onClick={() => {
												if (!currentMember.province || !currentMember.lessThanSixCaq) {
													setDisplayErrors(true)
												} else {
													setDisplayErrors(false)
													if (currentMember.lessThanSixCaq === 'si') {
														setStep(7)
													} else if (currentMember.lessThanSixCaq) {
														setStep(8)
													}
												}


											}} className='startbutton-disc2FamGroup'>
											Siguiente</Button>

									</div>
								</div>
							</> : null}
							{/* end page 3 */}
							{/* start page 4*/}
							{step === 7 ? <>
								<div>
									<div>

										<div className='titledivquestions'>
											<h1>Para solicitar un permiso de trabajo cerrado con LMIA y/o permiso de estudio para dependientes en Quebec, <br/> es necesario obtener previamente un &#160;
												<button type='button'
													onClick={() => popupCAQ()}
													style={{
														color: 'blue',
														textDecoration: 'underline'
													}}>CAQ</button><br />
												¿Desea incluir este servicio?</h1>
										</div>
										<div className='containerbackandnextbuttons' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

											<span className='rcorners2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
												setStep(8)
												handleChange('studyOption', "Deseo incluir CAQ (Certificado de aceptación de Quebec)")
											}}>
												Si
											</span>

											<span className='rcorners2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
												setStep(8)
												handleChange('studyOption', "NO deseo incluir CAQ (Certificado de aceptación de Quebec)")
											}}>
												No
											</span>

										</div>
										<div className='containerbackandnextbuttons' style={{ marginTop: '1rem' }}>
											<Button onClick={() => setStep(6)} className='backbutton'>
												Atras
											</Button>

										</div>

									</div>
								</div>
							</> : null}
							{/* end page 4*/}
							{step === 8 ? <PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} /> : null}

						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default StudyLessSix
