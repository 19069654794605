export default function setupAxios(
  axios: any,
  token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA5ODY5MWNiLTViMzQtNGZiMy1hMDRjLWZjOGRhOWU3NzhkOSIsInJvbGUiOnsiaWQiOjEsIm5hbWUiOiJBZG1pbiIsIl9fZW50aXR5IjoiUm9sZSJ9LCJpYXQiOjE2NTg3NTg4MDAsImV4cCI6MTk3NDMzNDgwMH0.q0KeFTfzkjbHH_VaJWJqj_Qiqe6LsOVJZaE_X2l_ob8'
) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: any) => {
      config.headers.Authorization = `Bearer ${token}`

      return config
    },
    (err: any) => {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    function (response: any) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response
    },
    function (error: any) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(error)
    }
  )
}
