export interface INode {
  id: any
  type: any
  component: JSX.Element
  data: object
}

export const initialNode: INode = {
  id: '',
  type: '',
  component: '' as any,
  data: [],
}
