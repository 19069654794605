/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FamilyTypes, IFamilyGroup, IFamilys } from './types'
import React, { useContext, useEffect } from 'react'


import { Button } from 'antd'
import MembersCards from './MembersCards'
import MembersData from './MembersData'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import { nanoid } from '@reduxjs/toolkit'
import { observer } from 'mobx-react'
import '../../../Components/Modal/modal.css'
import './style.css'
interface IProps {
	family?: IFamilys
	familyGroup: IFamilyGroup
}

const FamilyGroup: React.FC<IProps> = ({ familyGroup }) => {
	const rootStore = useContext(RootStoreContext)

	const {
		principalUsed,
		familyMembers,
		currentMember,
		setFamilyMember,
		setCurrentMember,
		numberOfGroupFamilies,
		numberOfChildrenUsed,
	} = rootStore.familyStore

	const [open, setOpen] = React.useState(false)
	const [edit, setEdit] = React.useState(false)
	const [countConyugue, setCountConyugue] = React.useState(0)
	const [countChildren, setCountChildren] = React.useState(1)
	const [numberofMembers, setNumberofMembers] = React.useState<number>()

	useEffect(() => {
		if (familyMembers.length === 0) {
			addRow()
		} else {
			/* setEdit(true) */
			setCurrentMember(familyMembers[0])
  }
		setOpen(true)
	}, [])
	const deleteFamilyMember = (id: string, type: string) => {
		{ type === FamilyTypes.conyuge && countConyugue === 1 && setCountConyugue(0) }
		{ type === FamilyTypes.children && countChildren > 0 && setCountChildren(countChildren - 1) }
		setFamilyMember(familyMembers.filter((family: IFamilys) => family.id !== id))
	}
	useEffect(() => {
		// addRow()
	}, [numberofMembers])
	useEffect(() => {
		// addRow()
	}, [currentMember])
	useEffect(() => { }, [numberOfGroupFamilies.length])
	useEffect(() => {
		// calculatePrice()
		validateFields()
	}, [familyMembers])

	const validateFields = () => {
		//
		let valid = false
		familyMembers.forEach((family: IFamilys, index: number) => {
			if (family.valid === undefined || family.valid === false) {
				valid = true
			}
			if (index > 0) {
				if (family.name === '' || family.lastname === '') {
					valid = true
				}
			}
		})
		// setAllValid(valid)
	}

	const addRow = () => {
		setEdit(false)
		const newMember: IFamilys = {
			id: nanoid(),
			name: '',
			type: FamilyTypes.principal,
			lastname: '',
			nationality: '',
			age: -1,
			hasBiometrics: undefined,
			birthday: new Date(),
			birthdayFormatted: moment().format('YYYY-MM-DD'),
			visaType: '',
			groupId: familyGroup.id,
			monthDifference: -1,
			principal: true,
			documents: [{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }]
		}
		setCurrentMember(newMember)

		setFamilyMember([...familyMembers, newMember])
		setOpen(true)
	}

	const addRowChildren = () => {
		setEdit(false)
		const newMember: IFamilys = {
			id: nanoid(),
			name: '',
			type: FamilyTypes.children,
			lastname: '',
			nationality: '',
			age: -1,
			hasBiometrics: undefined,
			birthday: new Date(),
			birthdayFormatted: moment().format('YYYY-MM-DD'),
			visaType: '',
			groupId: familyGroup.id,
			monthDifference: -1,
			documents: [{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
			{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }]
		}
		setCurrentMember(newMember)

		setFamilyMember([...familyMembers, newMember])
		setOpen(true)
		setCountChildren(countChildren + 1)
	}

	const ifEditing = (familySelected: IFamilys) => {
		setOpen(true)
		setEdit(true)
		setCurrentMember(familySelected)
	}

	return (
		<>
			<div className='blockstyle' style={{ paddingLeft: '7rem' }}>
				{!principalUsed() &&
					<Button
						className='startbutton-disc2FamGroup'
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8rem', height: '2rem' }}
						onClick={addRow}
					>
						<div >Comenzar</div>
					</Button>
				}

				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{numberOfChildrenUsed() < Number(localStorage.getItem('wantsDependantChildren')) && principalUsed()?.wantsDependantChildren === "si" &&
						<Button
							className='startbutton-disc2FamGroup'
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20rem', height: '2rem', marginTop: '1rem' }}
							onClick={
								addRowChildren
							}>
							{/* <UserAddOutlined /> */}
							<div >Agregar Hija(o) dependiente</div>
						</Button>}
				</div>
				<div className='parent'>
					{familyMembers
						.filter((family: IFamilys) => family.groupId === familyGroup.id)
						.map((family: IFamilys,) => {
							return (
								<MembersCards
									family={family}
									deleteFamilyMember={deleteFamilyMember}
									ifEditing={ifEditing}
								/>
							)
						})}

				</div>
				{currentMember && (
					<MembersData
						familyGroup={familyGroup}
						open={open}
						setOpen={setOpen}
						onSubmit={() => { }}
						onClose={() => { }}
						family={currentMember}
						edit={edit}
						setEdit={setEdit}
					/>
				)}
			</div>
		</>
	)
}

export default observer(FamilyGroup)
