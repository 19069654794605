import {Product, ProductQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const PRODUCT_URL = `${API_URL}/products`

const getProductsByCategory = (productId: string): Promise<Array<Product>> => {
  return axios
    .get(`${PRODUCT_URL}/category/${productId}`)
    .then((response: AxiosResponse<ProductQueryResponse>) => {
      return response.data as Array<Product>
    })
}

const getProductById = (id: string): Promise<Product> => {
  return axios.get(`${PRODUCT_URL}/find/${id}`).then((d: AxiosResponse<ProductQueryResponse>) => {
    return d.data as Product
  })
}

const getProducts = (query: string): Promise<Array<Product>> | undefined => {
  return axios.get(`${PRODUCT_URL}/all?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Array<Product>
  })
}

const getProductsEmployer = (query: string): Promise<any> | undefined => {
  return axios.get(`${PRODUCT_URL}/allProductEmpl`).then((response: AxiosResponse) => {
    return response.data as any
  })
}

export {getProductById, getProductsByCategory, getProducts, getProductsEmployer}
