/* eslint-disable no-empty-pattern */

import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateTransitVisa: React.FC<IProps> = ({
	products
}) => {
	const superVisa = products.find(element => element.contratationProductId == "56")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSuperVisaFederalFee, principalUsed, getSuperVisaFederalFee } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countSuperVisas = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (principalUsed()?.typeOfApplication === "Super visa") {
			countSuperVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


	})
	if (superVisa !== undefined) {
		setSuperVisaFederalFee({
			id: superVisa.id,
			productContratationId: superVisa.contratationProductId,
			description: superVisa.description,
			totalFee: countSuperVisas * superVisa.fee,
			name: superVisa.name,
			quantity: countSuperVisas,
			fee: superVisa.price,
			memberInfo: arrayOfMembers,
			type:'gf'

		})
	}

	return (
		<>
			{getSuperVisaFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSuperVisaFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSuperVisaFederalFee().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getSuperVisaFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSuperVisaFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateTransitVisa)
