/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateStudyPermitStream: React.FC<IProps> = ({
	products
}) => {
	const spFederal = products.find(element => element.contratationProductId === "58")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, getCalculatestudyPermitFederalFeeDetails, setCalculatestudyPermitFederalFeeDetails, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countSp = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (member.type === FamilyTypes.principal && member.purposeVisit === "Estudios") {
			countSp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (member.type === FamilyTypes.children &&
			member.ageIndependentChildren === false &&
			member.ageForVisitorDependant === false &&
			principalUsed()?.purposeVisit === "Estudios") {
			countSp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type == FamilyTypes.children
				&& member.ageIndependentChildren === false
				/* && member.wantsBP === "no" */
				&& member.ageForVisitorDependant === false
				/* &&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()) */)
				|| (
					member.type == FamilyTypes.children
					&& member.ageIndependentChildren === false
					&& member.ageForVisitorDependant === false
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined
				)
				|| (member.type == FamilyTypes.children
					&& member.ageIndependentChildren === false
					&& member.ageForVisitorDependant === false
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === principalUsed()?.countryOfCitizenship?.toLocaleLowerCase())) === undefined
					&&
					(ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase()))
				)) {
				countSp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (member.typeOfApplication === "Study Visa Extension" &&
			member.type === FamilyTypes.principal) {
			countSp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
		member.type === FamilyTypes.children &&
		member.ageIndependentChildren === false &&
		member.ageForVisitorDependant === false) {
			countSp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

			if (principalUsed()?.inCanada === "si" &&
			member.type===FamilyTypes.children &&
			member.ageIndependentChildren===false &&
			member.wantsBP!=="si"  &&
			principalUsed()?.typeOfApplication==="PGWP"  &&
			member.ageForVisitorDependant===false) {
				countSp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}

		if (principalUsed()?.inCanada === "si") {
			if (member.type === FamilyTypes.children &&
				member.ageIndependentChildren === false &&
				principalUsed()?.typeOfApplication === "Work Visa Extension" &&
				member.wantsBP !== "no" &&
				member.ageForVisitorDependant === false) {
				countSp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	});

	if (spFederal !== undefined) {
		setCalculatestudyPermitFederalFeeDetails({
			id: spFederal.id,
			productContratationId: spFederal.contratationProductId,
			description: spFederal.description,
			totalFee: countSp * spFederal.fee,
			name: spFederal.name,
			quantity: countSp,
			fee: spFederal.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getCalculatestudyPermitFederalFeeDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculatestudyPermitFederalFeeDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getCalculatestudyPermitFederalFeeDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculatestudyPermitFederalFeeDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculatestudyPermitFederalFeeDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateStudyPermitStream)
