import { Table, TableBody, TableCell, TableHead, TableRow} from 'Components'


import FamilyGroup from './FamilyGroup'
import {IFamilyGroup} from './types'
import React from 'react'

interface IFamilyPanel {
  familyGroup: IFamilyGroup
  deleteRow: (id: string) => void
}

const FamilyPanel: React.FC<IFamilyPanel> = ({familyGroup}) => {
  return (
    <TableRow>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{display:'flex',justifyContent:'center'}}>
              <FamilyGroup familyGroup={familyGroup} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell style={{width: '45px'}}>
              {/*   <Button width={'40px'} onClick={() => deleteRow(familyGroup.id)}>
                Borrar grupo
              </Button> */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableRow>
  )
}

export default FamilyPanel
