import {Box, Button} from 'Components'
import React, { useContext,} from 'react'


import {RootStoreContext} from '../../../stores/rootStore'

import parse from 'html-react-parser'

interface IProps {
  nodeId: string
  node: any
  data: any
}

const InformationModule: React.FC<IProps> = ({node}) => {
  const rootStore = useContext(RootStoreContext)
  const { increaseEstep} =
    rootStore.formBuilderStore




  const onComplete = () => {
    increaseEstep()
  }

  return (
    <Box>
      Informacion
      <>{parse(node)}</>
      <Button variant='contained' onClick={() => onComplete()}>
        Siguiente
      </Button>
    </Box>
  )
}

export default InformationModule
