import {INode, initialNode} from '../types/nodes'
import {action, makeObservable, observable} from 'mobx'

import {RootStore} from '../../stores/rootStore'

export default class FormBuilderStore {
  rootStore: RootStore

  @observable nodesStore: any = null
  @observable end = true
  @observable step = 0
  @observable sources: any = null
  @observable currentNode: INode = initialNode
  @observable ifResult: boolean | undefined = undefined
  @observable previousNode: INode = initialNode
  @observable prices: any[] = []
  @observable currentData: any = null
  @observable backbuttonClicked = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action setCurrentNode = (node: INode) => {
    this.currentNode = node
  }
  @action setPreviousNode = (node: any) => {
    this.previousNode = node
  }

  @action setNodesStore = (node: any) => {
    this.nodesStore = node
  }

  @action setSources = (node: any) => {
    this.sources = node
  }

  @action setCurrentData = (node: any) => {
    this.currentData = node
  }

  @action setEnd = () => {
    this.end = false
  }

  @action setEstep = (step: number) => {
    this.step = step
  }

  @action increaseEstep = () => {
    this.step++
  }

  @action decreaseStep = () => {
    this.step--
  }

  @action clickbackbutton = () => {
    this.backbuttonClicked = true
  }

  @action clickbackbuttonFalse = () => {
    this.backbuttonClicked = false
  }

  @action updateDataNode = (data: any, target: string) => {
    this.nodesStore.forEach((node: any) => {
      if (node.id === target) {
        node.data = data
      }
    })
  }

  @action getNodeData = (data: any, target: string) => {
    this.nodesStore.forEach((node: any) => {
      if (node.target === target) {
        return node.data
      }
    })
  }

  @action setIfResult = (result: boolean | undefined) => {
    this.ifResult = result
  }

  @action addPrice = (prices: any) => {
    this.prices.push(prices)
  }
}
