import React from 'react'
import { observer } from 'mobx-react'
import './style/style.css'
interface IProps {
	urlString: string
}
const Frames: React.FC<IProps> = ({ urlString }) => {
	return (
		<>
        <iframe onLoad={(e)=>{console.log("cargando..",e.target)}}   
                    width='0'
                          height='0'
                          src={urlString}
                          title='YouTube video player'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe> 
        </>
	)
}

export default observer(Frames)
