/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateSubmission: React.FC<IProps> = ({
	products
}) => {
	const submission = products.find(element => element.contratationProductId == "45")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSubmission, getSubmission, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countSubmission = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (principalUsed()?.purposeVisit === "Residencia Permanente") {
			return
		}


		if (member.canadianVisaRejection == "si" &&
		  (member.havePreviousUSAVisa == "no" || member.havePreviousUSAVisa == undefined) &&
			member.typeOfWorkPermit !== 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && member.typeOfWorkPermit !== "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá" && member.typeOfWorkPermit !== "Permiso de trabajo CERRADO para parejas de un estudiante o trabajador que ya está en Canadá" && member.typeOfWorkPermit !== "PGWP" && member.purposeVisit !== "PGWP" && member.typeOfApplication !== "PGWP") {
			if (member.typeOfApplication !== "Electronic Travel Authorization - eTA") {
				if (member.type === FamilyTypes.principal) {
					countSubmission++
					const memberInfo = {
						"memberName": member.name + " " + member.lastname,
						"memberType": member.type
					}
					arrayOfMembers.push(memberInfo)
				}
			}
		}

	})
	if (submission !== undefined) {
		setSubmission({
			id: submission.id,
			productContratationId: submission.contratationProductId,
			description: submission.description,
			totalFee: countSubmission >= 1 ? submission.fee : 0,
			name: submission.name,
			quantity: countSubmission >= 1 ? 1 : 0,
			fee: submission.price,
			memberInfo: arrayOfMembers

		})
	}

	return (
		<>
			{getSubmission().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSubmission().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSubmission().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getSubmission().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSubmission().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSubmission)
