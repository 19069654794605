import React, { useEffect, useState } from 'react';
import './preEvaluation.css';
import MainPreEvaluationForm from './mainPreEvaluationForm';
import ResultEvaluationOne from './resultPEone';
import ResultEvaluationTwo from './resultPETwo';
import ResultEvaluationThree from './resultPEThree';
import ResultEvaluationFour from './resultPEFour';
import ResultEvaluationFive from './resultPEFive';
import ResultEvaluationSix from './resultPESix';
import ResultEvaluationOutOne from './resultPEOutOne';
import ResultEvaluationOutTwo from './resultPEOutTwo';
import ResultEvaluationOutThree from './resultPEOutThree';
import ResultEvaluationOutFour from './resultPEOutFour';
import GoogleBusinessReviews from './GoogleBussinesReviews';

const PreEvaluation: React.FC = () => {
  const [showResult, setShowResult] = useState(false);
  const [preEvaluationAnswers, setPreEvaluationAnswers] = useState<any>({});

  useEffect(() => {
    console.log(preEvaluationAnswers)
  }, [preEvaluationAnswers])

  useEffect(() => {
    console.log(showResult)
  }, [showResult])


  return (
    <>
      <div className='pre-evaluation-container-main '>
        {showResult === false ? <MainPreEvaluationForm setShowResult={setShowResult} setPreEvaluationAnswers={setPreEvaluationAnswers} preEvaluationAnswers={preEvaluationAnswers} />
          :
          <>
            {preEvaluationAnswers.location === 'dentro' &&
              <>
                {/* Dentro de Canada */}
                {preEvaluationAnswers.inmigrationArea === 'estudios' &&
                  <ResultEvaluationOne preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'residencia' &&
                  <ResultEvaluationTwo preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'trabajo' &&
                  <ResultEvaluationThree preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'turismo' &&
                  <ResultEvaluationFour preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'refugio' &&
                  <ResultEvaluationFive preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'sin status' &&
                  <ResultEvaluationSix preEvaluationAnswers={preEvaluationAnswers} />}
              </>}

            {/* Fuera de Canada */}
            {preEvaluationAnswers.location === 'fuera' &&
              <>
                {/* Fuera de Canada */}
                {preEvaluationAnswers.inmigrationArea === 'estudios' &&
                  <ResultEvaluationOutOne preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'residencia' &&
                  <ResultEvaluationOutTwo preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'trabajo' &&
                  <ResultEvaluationOutThree preEvaluationAnswers={preEvaluationAnswers} />}
                {preEvaluationAnswers.inmigrationArea === 'turismo' &&
                  <ResultEvaluationOutFour preEvaluationAnswers={preEvaluationAnswers} />}
              </>}
          </>}
      </div></>
  );
};

export default PreEvaluation;
