/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateWorkPermitPositivePOE: React.FC<IProps> = ({
	products
}) => {
	const wpPosPoe = products.find(element => element.contratationProductId == "64")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setWorkPermitPositivePOE,getWorkPermitPositivePOE } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
		if(member.inCanada==="no"){
		if (member.type === FamilyTypes.principal
			&& member.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral"
			&& member.wantsBP === "si"
			&& ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) {
			countwp++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	}
	})
	if (wpPosPoe !== undefined) {
		setWorkPermitPositivePOE({
			id: wpPosPoe.id,
			productContratationId: wpPosPoe.contratationProductId,
			description: wpPosPoe.description,
			totalFee: countwp * wpPosPoe.fee,
			name: wpPosPoe.name,
			quantity: countwp,
			fee: wpPosPoe.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getWorkPermitPositivePOE().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getWorkPermitPositivePOE().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getWorkPermitPositivePOE().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getWorkPermitPositivePOE().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getWorkPermitPositivePOE().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateWorkPermitPositivePOE)
