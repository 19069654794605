/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCitizenshipMinor: React.FC<IProps> = ({
	products
}) => {
	const product = products.find(element => element.contratationProductId == "106")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getCitizenshipMinor, setCitizenshipMinor} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
    const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if ( familyMembers[0].typeOfApplication === "Ciudadania" && member.type===FamilyTypes.children) {
			countCAQ++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}


	})
	if (product !== undefined) {
		setCitizenshipMinor({
			id: product.id,
			productContratationId: product.contratationProductId,
			description: product.description,
			totalFee: countCAQ * product.fee,
			name: product.name,
			quantity: countCAQ,
			fee: product.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}

	return (
		<>
			{getCitizenshipMinor().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCitizenshipMinor().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCitizenshipMinor().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCitizenshipMinor().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCitizenshipMinor().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCitizenshipMinor)
