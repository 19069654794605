/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateStudyPermitStream: React.FC<IProps> = ({
	products
}) => {
	const stream = products.find(element => element.contratationProductId == "5")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setCalculatestudyPermitAcademicStreamDetails,getCalculatestudyPermitAcademicStreamDetails } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countStream = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {

		if (member.type == FamilyTypes.principal && member.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)") {
			countStream++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}

	})
	if (stream !== undefined) {
		setCalculatestudyPermitAcademicStreamDetails({
			id: stream.id,
			productContratationId: stream.contratationProductId,
			description: stream.description,
			totalFee: countStream * stream.fee,
			name: stream.name,
			quantity: countStream,
			fee: stream.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getCalculatestudyPermitAcademicStreamDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculatestudyPermitAcademicStreamDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getCalculatestudyPermitAcademicStreamDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculatestudyPermitAcademicStreamDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculatestudyPermitAcademicStreamDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateStudyPermitStream)
