/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateCSQGovernmentApplicationFee: React.FC<IProps> = ({
	products
}) => {
	const CSQ = products.find(element => element.contratationProductId == "128")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setCSQApplicationFeeMainApplicant, principalUsed, getCSQApplicationFeeMainApplicant } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (member.csq===true) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
	if (member.csqPEQ===true) {
		countwpFee++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
}
	})
	if (CSQ !== undefined) {
		setCSQApplicationFeeMainApplicant({
			id: CSQ.id,
			productContratationId: CSQ.contratationProductId,
			description: CSQ.description,
			totalFee: countwpFee * CSQ.fee,
			name: CSQ.name,
			quantity: countwpFee,
			fee: CSQ.price,
			memberInfo: arrayOfMembers,
			type:'gf'
		})
	}
	return (
		<>
			{getCSQApplicationFeeMainApplicant().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCSQApplicationFeeMainApplicant().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCSQApplicationFeeMainApplicant().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getCSQApplicationFeeMainApplicant().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCSQApplicationFeeMainApplicant().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCSQGovernmentApplicationFee)
