
import {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from 'stores/rootStore'
import {TableCell} from 'Components'
import {observer} from 'mobx-react'


const CalculateFees = () => {
  const rootStore = useContext(RootStoreContext)
  const {
    getEducationAnswersFormSurvey,
    setTotalPoints,
    totalPrice,
  } = rootStore.educationStore

  const [price, setPrice] = useState(0)

  useEffect(() => {
    loopGroups()

    //setSurvey(JSON.stringify(getEducationAnswersFormSurvey()))
  }, [price])

  const loopGroups = () => {
    let countEta = 0
    if (
      getEducationAnswersFormSurvey().languagelevel === 'Intermedio avanzado' ||
      getEducationAnswersFormSurvey().languagelevel === 'Avanzado' ||
      getEducationAnswersFormSurvey().languagelevel === 'Nativo'
    ) {
      countEta++
    }
    if (getEducationAnswersFormSurvey().certifications === 'Si') {
      countEta++
    }
    if (
      getEducationAnswersFormSurvey().whenarrive === '6 meses' ||
      getEducationAnswersFormSurvey().whenarrive === '8 meses' ||
      getEducationAnswersFormSurvey().whenarrive === '12  meses'
    ) {
      countEta++
    }

    if (
      getEducationAnswersFormSurvey().money === ' 10000 CAD - 15000 CAD' ||
      getEducationAnswersFormSurvey().money === '15000 CAD - 20000CAD' ||
      getEducationAnswersFormSurvey().money === '20000CAD - 28000CAD' ||
      getEducationAnswersFormSurvey().money === 'mas de 28000 CAD'
    ) {
      countEta++
    }

    setPrice(countEta)

    setTotalPoints({
      product: 'points',
      description: [
        {
          members: 'points',
          price: countEta,
        },
      ],
      price: countEta,
    })
  }

  return (
    <>
      <>
        <TableCell
          style={{
            borderTop: '0.5px solid  gray',
            borderBottom: '0.5px solid  gray',
            fontFamily: 'Open Sans',
            fontSize: '15px',
            fontWeight: 'normal',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'black',
          }}
        >
          {totalPrice === 0 ? (
            <a
              style={{
                color: 'white',
                textAlign: 'center',
                border: '1px solid #BF3C3C',
                backgroundColor: '#BF3C3C',
                borderRadius: '15px',
                fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '550',
                textDecoration: 'none',
                display: 'inline-block',
                marginTop: ' 20px',
                width: '300px',
                justifyContent: 'center',
                height: '70px',
                paddingTop: '20px',
              }}
              href='https://calendar.app.google/r5X61rcw4yYULDbE8'
            >
              Link para agendar cita{' '}
            </a>
          ) : null}
          {totalPrice > 0 && totalPrice < 3 ? (
            <a
              href='https://calendar.app.google/naEGdrCTqjGwK3dg6'
              style={{
                color: 'white',
                textAlign: 'center',
                border: '1px solid #BF3C3C',
                backgroundColor: '#BF3C3C',
                borderRadius: '15px',
                fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '550',
                textDecoration: 'none',
                display: 'inline-block',
                marginTop: ' 20px',
                width: '300px',
                justifyContent: 'center',
                height: '70px',
                paddingTop: '20px',
              }}
            >
              Link para agendar cita{' '}
            </a>
          ) : null}
          {totalPrice > 2 ? (
            <a
              href='https://calendar.app.google/L7JfRpkZocziuxCk9'
              style={{
                color: 'white',
                textAlign: 'center',
                border: '1px solid #BF3C3C',
                backgroundColor: '#BF3C3C',
                borderRadius: '15px',
                fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '550',
                textDecoration: 'none',
                display: 'inline-block',
                marginTop: ' 20px',
                width: '300px',
                justifyContent: 'center',
                height: '70px',
                paddingTop: '20px',
              }}
            >
              Link para agendar cita{' '}
            </a>
          ) : null}
        </TableCell>
      </>
    </>
  )
}

export default observer(CalculateFees)
