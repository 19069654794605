/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateDependantChildExEntry: React.FC<IProps> = ({
	products
}) => {
	const child = products.find(element => element.contratationProductId === "137")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setdependantExEntry , principalUsed,getdependantExEntry  } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if ( principalUsed()?.phaseexpress === "fase2" && member.type===FamilyTypes.children) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (child !== undefined) {
		setdependantExEntry({
			id: child.id,
			productContratationId: child.contratationProductId,
			description: child.description,
			totalFee: countwpFee * child.fee,
			name: child.name,
			quantity: countwpFee,
			fee: child.price,
			memberInfo: arrayOfMembers,
			type:'pf'
		})
	}
	return (
		<>
			{getdependantExEntry().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getdependantExEntry().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getdependantExEntry().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getdependantExEntry().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getdependantExEntry().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantChildExEntry)
