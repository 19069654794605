/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
import './styles/style.css'

import { Table, TableBody, TableHead, TableRow } from 'Components'
import {  IFamilyGroup, IFamilys } from './types'
import React, { useContext, useEffect } from 'react'
import FamilyPanel from './FamilyPanel'
import { RootStoreContext } from '../../../stores/rootStore'
import { nanoid } from '@reduxjs/toolkit'
import { observer } from 'mobx-react'
import Swal from 'sweetalert2'


interface IProps {
  nodeId: string
  node: any
  data: any
}



const FamilyModule: React.FC<IProps> = ({}) => {
  const rootStore = useContext(RootStoreContext)
  const {  } =
    rootStore.formBuilderStore
  const { familyMembers, familygroup, setFamilyGroup, principalUsed } = rootStore.familyStore

 
  const [allValid, setAllValid] = React.useState(true)
  const [index, setIndex] = React.useState(0)
  //one time

  useEffect(() => {
    // valitdate valid in familyMembers array
    let allValid = true

    familyMembers.forEach((family: IFamilys, index: number) => {
      if (family.valid === undefined || family.valid === false) {
        allValid = false
      }
    })

    setAllValid(allValid)
  }, [familyMembers])

  useEffect(() => {
    if (!principalUsed()) {
      addRow()
    }
  }, [])

  const addRow = () => {
    setFamilyGroup([...familygroup, { id: nanoid(), index, family: [] }])
  }

  const deleteRow = (id: string) => {
    setFamilyGroup(familygroup.filter((family: IFamilyGroup) => family.id !== id))
  }

  const popupDisplay = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<b>Por favor verificar que esten completos los formularios de sus familiares para poder generar su cotización</b>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
    })
  }

  return (
    <Table
      aria-label='simple table'
      style={{
        borderSpacing: '0',
        border: '0px solid #ddd',
        fontStretch: 'ultra-condensed',
        fontSize: '8px',
      }}
    >
      <TableHead>
        <TableRow></TableRow>
      </TableHead>
      <TableBody>
        {familygroup.map((family: IFamilyGroup) => (
          <FamilyPanel familyGroup={family} deleteRow={deleteRow} />
        ))}
      </TableBody>
    </Table>
  )
}

export default observer(FamilyModule)
