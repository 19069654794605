/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateVisitorRecord: React.FC<IProps> = ({
	products
}) => {
	const visitorRecord = products.find(element => element.contratationProductId === "21")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setVisitorRecord, principalUsed, getVisitorRecord } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countVisitorVisas = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.typeOfApplication === "Visitor Visa Extension" &&
			member.type === FamilyTypes.principal) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Visitor Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Study Visa Extension" &&
			principalUsed()?.ApplyWithSpouse === "si" &&
			member.type === FamilyTypes.conyuge &&
			(principalUsed()?.languageRequired === "no" ||
				principalUsed()?.languageRequired === undefined)) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.typeOfApplication === "Visitor Visa Extension" &&
			member.type === FamilyTypes.children) {
			countVisitorVisas++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (visitorRecord !== undefined) {
		setVisitorRecord({
			id: visitorRecord.id,
			productContratationId: visitorRecord.contratationProductId,
			description: visitorRecord.description,
			totalFee: visitorRecord.fee * countVisitorVisas,
			name: visitorRecord.name,
			quantity: countVisitorVisas,
			fee: visitorRecord.price,
			memberInfo: arrayOfMembers
		})
	}

	return (
		<>
			{getVisitorRecord().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getVisitorRecord().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>
						<Description>{getVisitorRecord().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getVisitorRecord().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getVisitorRecord().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateVisitorRecord)
