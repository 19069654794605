/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateCAQMinorGovernmentFee: React.FC<IProps> = ({
	products
}) => {
	const caqMinorGovernmentFee = products.find(element => element.contratationProductId === "68")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, principalUsed, getCalculateCAQMinorGovermentFees, setCalculateCAQMinorGovermentFees } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countCAQ = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (principalUsed()?.inCanada === "no" &&
		principalUsed()?.purposeVisit !=="Visitar o transito" &&
		principalUsed()?.purposeVisit !== "Residencia Permanente" &&
		principalUsed()?.wayChooseOnStudy !== "StudyLessSix" &&
			member.type == FamilyTypes.children &&
			(principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)"
				|| principalUsed()?.province === 'Quebec') &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.province === 'Quebec' &&
			principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.children &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false &&
			member.wantsBP !== "si" &&
			principalUsed()?.typeOfApplication === "PGWP") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.province === 'Quebec' &&
		principalUsed()?.purposeVisit !== "Residencia Permanente" &&
		principalUsed()?.inCanada === "si" &&
		member.type === FamilyTypes.children &&
		member.ageForCAQMinor === true &&
		member.ageForVisitorDependant === false &&
		member.ageIndependentChildren === false &&
		/* member.wantsBP !== "si" && */
		(principalUsed()?.typeOfClosedWorkPermit	=== "ESTÁ EXENTA de una evaluación de impacto del mercado laboral" ||
		principalUsed()?.typeOfClosedWorkPermit	=== "REQUIERE de una evaluación de impacto del mercado laboral"	) ) {
		countCAQ++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
	}

		if (principalUsed()?.province === 'Quebec' &&
			principalUsed()?.purposeVisit !== "Residencia Permanente" &&
			principalUsed()?.inCanada === "si" &&
			member.type === FamilyTypes.children &&
			member.ageForCAQMinor === true &&
			member.ageForVisitorDependant === false &&
			member.ageIndependentChildren === false &&
			member.wantsBP !== "si" &&
			principalUsed()?.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.province === 'Quebec' &&
		principalUsed()?.purposeVisit !=="Visitar o transito" &&
		principalUsed()?.purposeVisit !== "Residencia Permanente" &&
		principalUsed()?.studyOption === "Deseo incluir CAQ (Certificado de aceptación de Quebec)" &&
		principalUsed()?.inCanada === "si" &&
		member.type == FamilyTypes.children &&
		member.ageForCAQMinor === true &&
		member.ageForVisitorDependant === false &&
		member.ageIndependentChildren === false) {
			countCAQ++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (caqMinorGovernmentFee !== undefined) {
		setCalculateCAQMinorGovermentFees({
			id: caqMinorGovernmentFee.id,
			productContratationId: caqMinorGovernmentFee.contratationProductId,
			description: caqMinorGovernmentFee.description,
			totalFee: countCAQ * caqMinorGovernmentFee.fee,
			name: caqMinorGovernmentFee.name,
			quantity: countCAQ,
			fee: caqMinorGovernmentFee.price,
			memberInfo: arrayOfMembers,
			type: 'gf'
		})
	}

	return (
		<>
			{getCalculateCAQMinorGovermentFees().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculateCAQMinorGovermentFees().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getCalculateCAQMinorGovermentFees().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculateCAQMinorGovermentFees().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculateCAQMinorGovermentFees().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateCAQMinorGovernmentFee)
