/* eslint-disable no-empty-pattern */
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {  Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import { current } from '@reduxjs/toolkit'


interface IProps {
	products: Product[]
}
const CalculateSpousePN: React.FC<IProps> = ({
	products
}) => {
	const expressEntryFee = products.find(element => element.contratationProductId == "144")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSpousePN, getSpousePN,principalUsed} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let phase = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (principalUsed()?.pn === true && member.type===FamilyTypes.conyuge) {
			phase++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	})
	if (expressEntryFee !== undefined) {
		setSpousePN({
			id: expressEntryFee.id,
			productContratationId: expressEntryFee.contratationProductId,
			description: expressEntryFee.description,
			totalFee: phase * expressEntryFee.fee,
			name: expressEntryFee.name,
			quantity: phase,
			fee: expressEntryFee.price,
			memberInfo:arrayOfMembers,
			type:'pf',
		})
	}

	return (
		<>
			{getSpousePN().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSpousePN().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSpousePN().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getSpousePN().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSpousePN().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSpousePN)
