/* eslint-disable no-empty-pattern */
import { FamilyTypes} from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const Calculaterpcsq: React.FC<IProps> = ({
	products
}) => {
	const OWPMAinAplicant = products.find(element => element.contratationProductId == "76")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setotherPRFederal, getotherPRFederal, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (member.rpcsq===true) {
				countwpFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
		if (member.rpcsqPEQ===true) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
	if (member.prendorsement===true) {
		console.log("llegue aqui")
		countwpFee++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
}
	})
	if (OWPMAinAplicant !== undefined) {
		setotherPRFederal({
			id: OWPMAinAplicant.id,
			productContratationId: OWPMAinAplicant.contratationProductId,
			description: OWPMAinAplicant.description,
			totalFee: countwpFee * OWPMAinAplicant.fee,
			name: OWPMAinAplicant.name,
			quantity: countwpFee,
			fee: OWPMAinAplicant.price,
			memberInfo:arrayOfMembers,
			type:'pf'

		})
	}
	return (
		<>
			{getotherPRFederal().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getotherPRFederal().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>{getotherPRFederal().description}
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span> <Description>{getotherPRFederal().quantity}</Description>
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getotherPRFederal().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(Calculaterpcsq)
