import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const API_URL = 'https://reviews.immiland.app'; 

interface Review {
  profile_photo_url: string;
  author_name: string;
  rating: number;
  text: string;
}


const GoogleBusinessReviews: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000, 
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(API_URL);
        console.log("Response data:", response.data);
        if (response.data && response.data.result && response.data.result.reviews) {
          setReviews(response.data.result.reviews);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        // Manejar el error aquí, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchReviews();
  }, []);

  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <svg
          key={i}
          className="star"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>
      );
    }
    return stars;
  };

  return (
    <div className="reviews-container">
      <Slider {...settings}>
        {reviews.map((review: Review, index: number) => (
          <div key={index} className="review-card">
            <div className="review-header">
              <img src={review.profile_photo_url} alt={review.author_name} className="review-author-avatar" />
              <div className="review-author-info">
                <p className="review-author-name">{review.author_name}</p>
                <div className="review-rating">
                  <p>Rating: </p>
                  {renderStars(review.rating)}
                </div>
              </div>
            </div>
            <div className="review-content">
              <p className="review-text">{review.text.slice(0, 350)}...</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};


export default GoogleBusinessReviews;
