import {Product} from './module'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FLOW_URL = `${API_URL}/products/all/sortbycontratationId`
const getAllProduct = (query:string): Promise<Product[]> => {
  return axios.get(`${FLOW_URL}?${query}`).then((response: AxiosResponse) => {
    return response.data.data as Product[]
  })
}

export {getAllProduct}
