/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}
const CalculateLMIAPosExON: React.FC<IProps> = ({
	products
}) => {
	const lmiaPOSExtentionPOE = products.find(element => element.contratationProductId == "26")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setLMIAPosExtentionON,getLMIAPosExtentionON } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let count= 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
		if(member.inCanada==="si"){
		if(member.type===FamilyTypes.principal && member.typeOfClosedWorkPermit==="REQUIERE de una evaluación de impacto del mercado laboral" && member.wantsBP==="si"){
            count++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
        }
	}
	})
	if (lmiaPOSExtentionPOE !== undefined) {
		setLMIAPosExtentionON({
			id: lmiaPOSExtentionPOE.id,
			productContratationId: lmiaPOSExtentionPOE.contratationProductId,
			description: lmiaPOSExtentionPOE.description,
			totalFee: count * lmiaPOSExtentionPOE.fee,
			name: lmiaPOSExtentionPOE.name,
			quantity: count,
			fee: lmiaPOSExtentionPOE.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getLMIAPosExtentionON().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getLMIAPosExtentionON().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getLMIAPosExtentionON().description}</Description>

					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>
						{getLMIAPosExtentionON().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getLMIAPosExtentionON().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateLMIAPosExON)
