import React,{ useEffect,useState } from 'react'

import { Select } from 'Components/Select'

interface IProps {
	label: string
	value: string
	handleChange: (event: any) => void
}

interface Icountires {
	name: string
	officialName: string
	region: string
	cca2: string
	ccn3: string
	cca3: string
	cioc: string

}

const CountrySelector: React.FC<IProps> = ({ value,label,handleChange }) => {
	const [countries,setCountries] = useState<Icountires[]>([])

	useEffect(() => {
		fetch('https://surveyjs.io/api/CountriesExample')
			.then((response) => response.json())
			.then((data) => setCountries(data))
	},[])

	return (
		<>
			<div>
				<Select
				className='custom-input'
					id={label}
					name={label}
					defaultValues={value}
					style={{ backgroundColor: 'white' }}
					labelId='demo-simple-select-label'
					value={value}
					label={label}
					onChange={handleChange}
				>
					<option value=''>Seleccione</option>
					{countries.map((country: Icountires) => (
						<option key={country.name} value={country.name}>
							{country.name}
						</option>
					))}
				</Select>
			</div>
		</>
	)
}

export { CountrySelector }
