import React, { Dispatch, SetStateAction, useEffect } from 'react'

interface IProps {
  country: string
  setTLC: Dispatch<SetStateAction<boolean>>
}

const TLCCountryChecker : React.FC<IProps> = ({ country, setTLC }) => {
  const countryList =
  [
    'Colombia',
    'Costa Rica',
    'Honduras',
    'Panama',
    'Peru',
    'United States',
    'Mexico'
  ]

  useEffect(() => {
    const isCountryValid = countryList.includes(country);
    setTLC(isCountryValid);
  }, [country, setTLC, countryList]);

  return null;
};

export default TLCCountryChecker
