/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateDependantStudylessFiveExtention: React.FC<IProps> = ({
	products
}) => {
	const dependantlessFive = products.find(element => element.contratationProductId === "19")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getdependantStudyPermitExtentionLessFiveDetails,setdependantStudyPermitExtentionLessFiveDetails ,principalUsed} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if(member.wantsBP==="si" || member.wantsBP==="no" ){member.wantsBP="no"}
        if(principalUsed()?.typeOfApplication==="Study Visa Extension" && member.type===FamilyTypes.children  && member.ageForVisitorDependant===true && member.ageIndependentChildren===false){
        counter++
		const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
        }

		if (principalUsed()?.inCanada==="si" && member.type===FamilyTypes.children && member.wantsBP!=="si"  && principalUsed()?.typeOfApplication==="PGWP"  && member.ageForVisitorDependant===true && member.ageIndependentChildren===false) {
			counter++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if(principalUsed()?.inCanada==="si"){
			if(member.type===FamilyTypes.children && principalUsed()?.typeOfApplication==="Work Visa Extension" && member.wantsBP!=="no"  && member.ageForVisitorDependant===true && member.ageIndependentChildren===false){
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

	})
	if (dependantlessFive !== undefined) {
		setdependantStudyPermitExtentionLessFiveDetails({
			id: dependantlessFive.id,
			productContratationId: dependantlessFive.contratationProductId,
			description: dependantlessFive.description,
			totalFee: counter * dependantlessFive.fee,
			name: dependantlessFive.name,
			quantity: counter,
			fee: dependantlessFive.price,
			memberInfo:arrayOfMembers
		})
	}

	return (
		<>
			{getdependantStudyPermitExtentionLessFiveDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getdependantStudyPermitExtentionLessFiveDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getdependantStudyPermitExtentionLessFiveDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getdependantStudyPermitExtentionLessFiveDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getdependantStudyPermitExtentionLessFiveDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantStudylessFiveExtention)
