
import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Select } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import './style/style.css'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableElement from './TableElement';
interface IProduct {
  id: string;
  name: string;
  price: number;
}
interface IProductGroupProps {
  groupName: any;
  products: any;
  finalSummary: any;
  setFinalSummary: React.Dispatch<React.SetStateAction<any[] | undefined>>
  setPricesDescriptionCombo: React.Dispatch<React.SetStateAction<any[]>>
  pricesDescriptionCombo: any
  category: any
  onRemove: () => void;
  productsAfterValidation:any[]
  numberOfCombos:any[]
  setProductsAfterValidation: React.Dispatch<React.SetStateAction<any[]>>
  setNumberOfCombos:React.Dispatch<React.SetStateAction<any[]>>,
  indexCombo:any
}

const comboInfo: React.FC<IProductGroupProps> = ({
  groupName,
  products,
  finalSummary,
  onRemove,
  setFinalSummary,
  setPricesDescriptionCombo,
  productsAfterValidation,
  numberOfCombos,
  category,
  setProductsAfterValidation,
  setNumberOfCombos,
  indexCombo
}) => {
  const categoryInformation = category
  const [isComboVisible, setIsComboVisible] = useState(false);
  const [productsByIndividualCategory, setProductsByIndividualCategory] = useState<any[]>([]);

  const openCombo = () => {
    setIsComboVisible(true);
  };

  // Función para cerrar el modal
  const closeCombo = () => {
    setIsComboVisible(false);
  };


  const [quantity, setQuantity] = useState(0);
  const incrementQuantity = () => {
    // Incrementa la cantidad y luego llama a updateSummary con la cantidad actualizada
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      console.log(newQuantity)
  /*     updateSummary(groupName, groupTotalPrice, newQuantity, products); */
      return newQuantity;
    });

  };
  const decrementQuantity = () => {
    if (quantity > 0) {
      // Decrementa la cantidad y luego llama a updateSummary con la cantidad actualizada
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
      /*   updateSummary(groupName, groupTotalPrice, newQuantity, products); */
        return newQuantity;
      });
    }
  };

 /*  const updateSummary = (name: any, price: any, quantity: any, products: any) => {
    console.log(quantity)
    // Create a copy of finalSummary
    let updatedSummary: any;

    if (Array.isArray(finalSummary)) {
      updatedSummary = [...finalSummary];
    } else {
      updatedSummary = [];
    }

    // Find the index of the group in finalSummary
    const groupIndex = updatedSummary.findIndex((group: any) => group.name === name);

    if (quantity > 0) {
      // If quantity is greater than 0, add or update the group in finalSummary
      if (groupIndex !== -1) {
        // If the group exists, update its properties
        updatedSummary[groupIndex].quantity = quantity;
        updatedSummary[groupIndex].price = price;
        updatedSummary[groupIndex].products = products;

        // Calculate categoriesTotals based on categories
        const categoriesTotals: { id: string, category: string, total: number, type: string, module: string }[] = [];

        products.forEach((product: any) => {
          product.categories.forEach((category: any) => {
            const categoryId = category.id; // Get the category id
            const categoryName = category.name;
            const categoryType = category.type; // Get the category type
            const categoryModule = category.module; // Get the category module
            const productPrice = product.price;
            const existingCategory = categoriesTotals.find((ct) => ct.id === categoryId);

            if (existingCategory) {
              // If the category already exists, update its total
              existingCategory.total += productPrice * quantity;
            } else {
              // If the category doesn't exist, add it
              categoriesTotals.push({
                id: categoryId,
                category: categoryName,
                total: productPrice * quantity,
                type: categoryType, // Add the category type
                module: categoryModule, // Add the category module
              });
            }
          });
        });

        updatedSummary[groupIndex].categoriesTotals = categoriesTotals;
      } else {
        // If the group doesn't exist, add it
        updatedSummary.push({ name, price, quantity, products });

        // Calculate categoriesTotals based on categories
        const categoriesTotals: { id: string, category: string, total: number, type: string, module: string }[] = [];

        products.forEach((product: any) => {
          product.categories.forEach((category: any) => {
            const categoryId = category.id; // Get the category id
            const categoryName = category.name;
            const categoryType = category.type; // Get the category type
            const categoryModule = category.module; // Get the category module
            const productPrice = product.price;
            const existingCategory = categoriesTotals.find((ct) => ct.id === categoryId);

            if (existingCategory) {
              // If the category already exists, update its total
              existingCategory.total += productPrice * quantity;
            } else {
              // If the category doesn't exist, add it
              categoriesTotals.push({
                id: categoryId,
                category: categoryName,
                total: productPrice * quantity,
                type: categoryType, // Add the category type
                module: categoryModule, // Add the category module
              });
            }
          });
        });

        updatedSummary[updatedSummary.length - 1].categoriesTotals = categoriesTotals;
      }
    } else {
      // If quantity is 0, remove the group from finalSummary
      if (groupIndex !== -1) {
        updatedSummary.splice(groupIndex, 1);
      }
    }

    // Call the function to group products by individual categories
    const pricesDescription = groupProductsByIndividualCategories(groupName, products, quantity);
    if (quantity === 0) {
      // If quantity becomes 0, remove the groupName from PricesDescriptionCombo
      setPricesDescriptionCombo((prevPrices: any) => {
        const updatedPrices = { ...prevPrices };
        delete updatedPrices[groupName];
        return updatedPrices;
      });
    } else {
      // Set the PricesDescriptionCombo for the current group using groupName as the key
      setPricesDescriptionCombo((prevPrices: any) => ({
        ...prevPrices,
        [groupName]: pricesDescription,
      }));
    }
    // Update finalSummary with the updatedSummary
    setFinalSummary(updatedSummary);
  }; */
  // Calcula el precio total de los productos en este grupo
  const groupTotalPrice = products.reduce(
    (total: any, product: any) => total + product.fee,
    0
  );

  const groupProductsByIndividualCategories = (groupName: string, products: any[], quantity: any) => {
    const groupedProductsByCategory: { categoryName: string, products: any[], quantity: number }[] = [];
    products.forEach((product: any) => {
      /*  if (category.type === 'individual') { */
      const categoryName = product.category.name;
      const existingCategory = groupedProductsByCategory.find((cat) => cat.categoryName === categoryName);
      if (existingCategory) {
        existingCategory.products.push(product);
      } else {
        groupedProductsByCategory.push({
          categoryName: categoryName,
          products: [product],
          quantity
        });
      }
      /*  } */
    });



    const groupedCategoriesWithTotalPrice = groupedProductsByCategory.map((category) => {
      // Calcular el precio total para la categoría actual
      const totalPrice = category.products.reduce((total, product) => {
        return total + product.fee * quantity; // Multiplicar el fee por la cantidad seleccionada
      }, 0);

      return {
        comboName: groupName,
        comboId: categoryInformation.id,
        categoryName: category.categoryName,
        products: category.products,
        quantity: category.quantity,
        totalPrice, // Agregar el precio total calculado
      };
    });

    return groupedCategoriesWithTotalPrice;
  };

  // Agrupar productos por categoría de tipo individual
  useEffect(() => {
    products.forEach((product: any) => {
      /* const productCategories = product.categories.filter((category: any) => category.type === 'individual'); */
      /*  product.category.forEach((category: any) => { */
      const categoryName = product.category.name;
      const existingCategory = productsByIndividualCategory.find((cat: any) => cat.name === categoryName);
      if (existingCategory) {
        existingCategory.products.push(product);
      } else {
        productsByIndividualCategory.push({
          name: categoryName,
          products: [product],
          quantity
        });
      }
      /* }); */
    });
  }, [products]);

  const getTextForCategory = (categoryName: string): string => {
    if (categoryName === 'International Experience Canada federal fee') {
      return 'Honorarios gubernamentales';
    } else if (categoryName === 'Labour Market Impact Assessment - exempt employer compliance federal fee') {
      return 'Honorarios gubernamentales';
    } else if (categoryName === 'International Experience Canada work permit ') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'International Experience Canada profile creation') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Labour Market Impact Assessment Quebec') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'CAQ worker') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'CAQ worker government application fee') {
      return 'Honorarios gubernamentales';
    } else if (categoryName === 'Labour Market Impact Assessment Federal ') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Labour Market Impact Assessment positive federal fee ') {
      return 'Honorarios gubernamentales';
    } else if (categoryName === 'MIFI review LMIA') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Labour Market Impact Assessment - exemption ') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Job ads x 2 ') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Work permit (includes extention) federal fee') {
      return 'Honorarios gubernamentales';
    } else if (categoryName === 'Closed work permit Labour Market Impact Assessment - positive (online) ') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Closed work permit  Labour Market Impact Assessment positive - Inside Canada (online)') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Closed work permit  Labour Market Impact Assessment - exempt (online)') {
      return 'Honorarios profesionales';
    } else if (categoryName === 'Closed work permit Labour Market Impact Assessment - exempt - Inside Canada (online application)') {
      return 'Honorarios profesionales';
    } else {
      return categoryName; // Default to the category name if not matched
    }
  };
  const getIconForCategory = (categoryName: string): string => {
    if (categoryName === 'QC Professional Fees') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Labour Market Impact Assessment - exempt employer compliance federal fee') {
      return '/icons/gov-fee.svg';
    } else if (categoryName === 'International Experience Canada federal fee') {
      return '/icons/gov-fee.svg';
    } else if (categoryName === 'International Experience Canada work permit ') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'International Experience Canada profile creation') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Labour Market Impact Assessment Quebec') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'CAQ worker') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'CAQ worker government application fee') {
      return '/icons/gov-fee.svg';
    } else if (categoryName === 'Labour Market Impact Assessment Federal ') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Labour Market Impact Assessment positive federal fee ') {
      return '/icons/gov-fee.svg';
    } else if (categoryName === 'MIFI review LMIA') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Labour Market Impact Assessment - exemption ') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Job ads x 2 ') {
      return '/icons/prof-fee.svg';
    } else if (categoryName === 'Work permit (includes extension) federal fee') {
        return '/icons/gov-fee.svg';
      } else if (categoryName === 'Closed work permit Labour Market Impact Assessment - positive (online) ') {
        return '/icons/prof-fee.svg';
      } else if (categoryName === 'Closed work permit  Labour Market Impact Assessment positive - Inside Canada (online)') {
        return '/icons/prof-fee.svg';
      } else if (categoryName === 'Closed work permit  Labour Market Impact Assessment - exempt (online)') {
        return '/icons/prof-fee.svg';
      } else if (categoryName === 'Closed work permit Labour Market Impact Assessment - exempt - Inside Canada (online application)') {
        return '/icons/prof-fee.svg';
      }  else if (categoryName === 'Closed work permit LMIA positive (online) ') {
          return '/icons/prof-fee.svg';
        }  else if (categoryName === 'Closed work permit LMIA positive - Inside Canada (online)') {
          return '/icons/prof-fee.svg';
        }  else if (categoryName === 'Labour Market Impact Assessment positive federal fee') {
          return '/icons/gov-fee.svg';
        }  else if (categoryName === 'Review Labour Market Impact Assessment Quebec provincial fee') {
          return '/icons/gov-fee.svg';
        }  else if (categoryName === 'CAQ worker Quebec') {
          return '/icons/prof-fee.svg';
        }  else if (categoryName === 'CAQ worker Quebec provincial fee') {
          return '/icons/gov-fee.svg';
        }  else if (categoryName === 'Closed work permit LMIA Exempt (online) ') {
          return '/icons/prof-fee.svg';
        }  else if (categoryName === 'Closed work permit LMIA exempt - Inside Canada (online)') {
          return '/icons/prof-fee.svg';
        }  else if (categoryName === 'Employer compliance (Labour Market Impact Assessment exempt) federal fee') {
          return '/icons/gov-fee.svg';
    } else {
      return ''; // You can provide a default icon or an empty string
    }
  };


  useEffect(() => {
   console.log("PAV",productsAfterValidation)
  }, [productsAfterValidation]);
  return (
    <div >
      <div className="product_group">
        <div >
          <div >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '30px', alignItems: 'center' }}>

              <div>



                {/*  <strong style={{color:'#0B76B7', fontSize:'14px', fontWeight:'700'}}>{groupName}</strong> */}
                <div >
                  {/*  <h1>{groupTotalPrice}</h1> */}

                  <div style={{ marginTop: '-10px', alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>  <strong style={{ color: '#0B76B7', fontSize: '14px', fontWeight: '700' }}>Cantidad</strong>
                    <InputNumber
                      min={1}
                      value={category.quantity?  category.quantity:0}
                      onChange={(value: number | null) => {
                        if (value !== null) {
                          setQuantity(value);
                        /*   updateSummary(groupName, groupTotalPrice, value, products); */
                          setProductsAfterValidation(prevProducts => {
                            return prevProducts.map((product, i) => {
                              if (i === indexCombo) {
                                console.log("entre aca",indexCombo)
                                // Actualizar el producto en el índice específico con la nueva propiedad 'quantity'
                                return { ...product, quantity:value };
                              }
                              return product; // Mantener los demás productos sin cambios
                            });
                          });
                        }
                      }}
                      style={{ marginLeft: '8px', width: '60px', height: '44px', display: 'flex', alignItems: 'center', borderRadius: '8px', marginTop: '6px' }}
                    />
                  </div>


                </div>

              </div>
              {/* <Select 
                  showSearch
                  value={quantity}
                  onChange={(value) => {
                    setQuantity(value);
                    updateSummary(groupName, groupTotalPrice, value, products);
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                    </div>
                  )}
                  style={{ width: 'auto', height: '2rem' }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select> */}
              <button style={{ marginTop: '18px' }} onClick={openCombo}>
                <a style={{ color: '#01A9DB', backgroundColor: 'white', padding: '7px 14px 7px 14px', borderRadius: '30px', boxShadow: '0px 5px 12px 0px #0000001A' }}>Ver Fees</a>
              </button>
            </div>
            {isComboVisible && (
              <div
                className={`slide-over ${isComboVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                data-controller="slide-over"
              >
                <div
                  className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 z-10 "
                  data-slide-over-target="backdrop"
                  data-action="click->slide-over#hide"  // Permite cerrar el slide-over haciendo clic en el fondo
                  data-transition-enter="ease-in-out duration-500"
                  data-transition-enter-start="opacity-0"
                  data-transition-enter-end="opacity-100"
                  data-transition-leave="ease-in-out duration-500"
                  data-transition-leave-start="opacity-100"
                  data-transition-leave-end="opacity-0"
                ></div>

                <div
                  className="fixed inset-0 overflow-hidden flex items-start justify-end z-10"
                  data-slide-over-target="panel"
                  data-transition-enter="transform transition ease-in-out duration-500 sm:duration-700"
                  data-transition-enter-start="-translate-x-full"
                  data-transition-enter-end="translate-x-0"
                  data-transition-leave="transform transition ease-in-out duration-500 sm:duration-700"
                  data-transition-leave-start="translate-x-0"
                  data-transition-leave-end="-translate-x-full"
                >
                  <div className="relative w-screen max-w-md pointer-events-auto h-100">


                    <div className="flex flex-col h-full py-6 overflow-y-auto bg-white shadow-xl">
                      <div className="sm:px-6 px-4 mt-5">
                        <Button className='back-button-form1' onClick={closeCombo}>

                          <img src="/icons/Vector.svg" alt="Home" />
                          <strong style={{ color: '#333333', fontSize: '16px', fontWeight: '700' }}>VOLVER</strong>
                        </Button>

                        <div style={{ backgroundColor: '#DFE4EA', height: '1px', marginTop: '20px', marginBottom: '20px' }}>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}> <strong style={{ fontSize: '18px', fontWeight: '700' }} >Fees de servicios</strong>
                          <a style={{ fontSize: '14px', fontWeight: '400' }}>Estos son los fees que contiene su servicio.</a></div>

                      </div>
                      <div className="sm:px-6 relative flex-1 px-4 mt-6">
                        <div className="sm:px-6 absolute inset-0 px-4">
                          <div style={{ backgroundColor: '#D0F0FD66', padding: '20px', borderRadius: '10px' }} className={` ${isComboVisible ? 'visible' : 'hidden'}`}>
                            <div >
                            {productsAfterValidation  && productsAfterValidation.map((cat: any) => (
    <>
        {cat.id===category.id && cat.products.map((pro: any) => (
           
            <div className="product_info" key={pro.name}>
               
                <div style={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <img
                            style={{ width: '20px', height: '20px' }}
                            src={getIconForCategory(pro.name)}
                            alt={`Icon for ${pro.name}`}
                        />
                        <a style={{ fontSize: '14px', fontWeight: '400', color: '#333333' }}>{pro.name}</a>
                    </div>
                
                    <div>
                        <a style={{ fontSize: '12px', fontWeight: '700', color: '#828282' }}>${pro.fee}</a>
                    </div>
                </div>
                <a style={{ fontSize: '12px', fontWeight: '400', color: '#4B5563', marginLeft: '33px' }}>{getTextForCategory(pro.name)}</a>
                <div style={{ backgroundColor: '#DFE4EA', height: '1px', marginTop: '20px', marginBottom: '20px' }}></div>
            </div>
        ))}
    </>
))}


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default comboInfo;