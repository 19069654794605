import './styleSecondPage.css'
import { FamilyTypes, IFamilys } from '../types'
import React from 'react'
import { Button } from 'Components'

import { UserOutlined } from '@ant-design/icons'

interface IProps {
	family: IFamilys
	deleteFamilyMember: (id: string, type: string) => void
	ifEditing: (familySelected: IFamilys) => void
}

const MembersCards: React.FC<IProps> = ({ family, deleteFamilyMember, ifEditing }) => {
	const editing = () => {
		ifEditing(family)
	}



	return (
		<>
			<div className='card' style={{ fontFamily: 'Lato, sans-serif' }}>
				<div className='header-card'>
					<UserOutlined className='mr-2' />
					<p>Nombre: &nbsp;</p>
					<p>{`${family.name} ${family.lastname}`}</p>
				</div>
				<div className='flex flex-col ml-3 gap-3'>
					<div className='flex'>
						<p>Tipo de solicitante:  &nbsp;</p>
						<p>{family.type}</p>
					</div>

						<div className='flex'>
							<p>Provincia de destino:  &nbsp;</p>
							<p>{family?.province}</p>
						</div>
					{family.type === FamilyTypes.principal ? <>
						<div className='flex'>
							<p>Tipo de solicitud:  &nbsp;</p>
							<p>{family.purposeVisit}</p>
						</div>
					</>
						: null}
					{family.type === FamilyTypes.principal ? <>
						<div className='flex'>
							<p>Email:  &nbsp;</p>
							<p>{family.email}</p>
						</div>
					</>
						: null}
					<div className='flex'>
						<p>País emisor de su pasaporte:  &nbsp;</p>
						<p>{family?.countryOfCitizenship}</p>
					</div>

					<div className='actions' style={{ color: '#bf3c3c' }}>
						<Button onClick={editing} className='editbutton' style={{}}>
							Editar
						</Button>
						{family.type === FamilyTypes.principal ? null : (
							<Button
								className='deletetbutton'
								onClick={() => {
									deleteFamilyMember(family.id, family.type)
								}}>
								Eliminar
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default MembersCards
