/* eslint-disable @typescript-eslint/no-empty-function */

import React,{ useContext,useEffect } from 'react'


import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'



let discountPreviousClient: boolean
const CalculateTotals = () => {
	const rootStore = useContext(RootStoreContext)
	const { totalPrice } = rootStore.familyStore
	const {
		familyMembers,

	} = rootStore.familyStore


	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	})
	useEffect(() => {
		loopGroups()
	},[])

	useEffect(() => {
	
	},[totalPrice])

	const loopGroups = () => {
		//summ all totalBiometricos ,  totalEta ,  totalFees ,  totalProfesional ,
		//setPrice(totalBiometricos?.price + totalEta.price + totalFees.price + totalProfesional.price)
		familyMembers.map((member) => {
			if (member.isPreviousClient === 'si') {
				discountPreviousClient = true
			}
		})
	}

	return (
		<>
			<div >
				{discountPreviousClient ? <> con descuento de Cliente Immiland</> : null}</div>
	
			<div className='total-price' >
				CAD {formatter.format(totalPrice)}
			</div>
		</>
	)
}

export default observer(CalculateTotals)
