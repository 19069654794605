/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateQCAddMember: React.FC<IProps> = ({
	products
}) => {
	const CalculateQCAddMember = products.find(element => element.contratationProductId == "124")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setQCAddMember, getQCAddMember, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if ((principalUsed()?.phase==='fase1' || principalUsed()?.phase==='ambas'   ) && principalUsed()?.province==="Quebec"  && member.type===FamilyTypes.conyuge && principalUsed()?.sponsorship==="parents") {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
		if ((principalUsed()?.phase==='fase1' ||principalUsed()?.phase==='ambas'  ) && principalUsed()?.province==="Quebec"  && member.type===FamilyTypes.children && principalUsed()?.sponsorship==="spouseOrPartner") {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
	})
	if (CalculateQCAddMember !== undefined) {
		setQCAddMember({
			id: CalculateQCAddMember.id,
			productContratationId: CalculateQCAddMember.contratationProductId,
			description: CalculateQCAddMember.description,
			totalFee: countwp * CalculateQCAddMember.fee,
			name: CalculateQCAddMember.name,
			quantity: countwp,
			fee: CalculateQCAddMember.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getQCAddMember().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getQCAddMember().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getQCAddMember().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getQCAddMember().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getQCAddMember().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateQCAddMember)
