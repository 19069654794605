/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Button } from 'Components'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const OutsideCanada: React.FC<IProps> = ({ edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			//it was visatype=before
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}
	const [selectedPurpose, setSelectedPurpose] = useState(null);
	const [isnextbuttonDisabled, setIsnextbuttonDisabled] = useState(true);
	const handleOptionChange = (value: any | React.SetStateAction<null>) => {
		setSelectedPurpose(value);
		setIsnextbuttonDisabled(value === null);
	};
	const handleContinuarClick = () => {
		// Use the selectedPurpose to determine the next step
		if (selectedPurpose === "Visitar o transito") {
			handleChange('purposeVisit', "Visitar o transito");
		} else if (selectedPurpose === "Estudios") {
			handleChange('purposeVisit', "Estudios");
		} else if (selectedPurpose === "Trabajo") {
			handleChange('purposeVisit', "Trabajo");
		} else if (selectedPurpose === "Residencia Permanente") {
			handleChange('purposeVisit', "Residencia Permanente");
		}
		// Set the step to 3
		setStep(3);
	};
	return (
		<div className='my-4 h-full bg-white'>
			<Box>
				<>
					<form className='w-full' id='fromEl'>
						<div className='flex flex-wrap mb-6'>
							{step === 2 && <> <div>
								<div className='titledivquestions'>
									<h1>¿Cuál es el propósito de su solicitud?</h1>
								</div>
								<div className='container-radio-select flex flex-wrap gap-8  flex-column items-center'>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Visitar o transito"
												checked={selectedPurpose === "Visitar o transito"}
												onChange={() => handleOptionChange("Visitar o transito")}
											/>
											<p>Visita o tránsito</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Estudios"
												checked={selectedPurpose === "Estudios"}
												onChange={() => handleOptionChange("Estudios")}
											/>
											<p>Estudios</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Trabajo"
												checked={selectedPurpose === "Trabajo"}
												onChange={() => handleOptionChange("Trabajo")}
											/>
											<p>Trabajo</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Residencia Permanente"
												checked={selectedPurpose === "Residencia Permanente"}
												onChange={() => handleOptionChange("Residencia Permanente")}
											/>
											<p>Residencia Permanente</p>
										</label>
									</div>

								</div>
								<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop:'2rem' }}>
									<Button onClick={() => setStep(1)} className='backbutton-disc2'>
										Atras
									</Button>
									<Button
									onClick={handleContinuarClick}
									className={`startbutton-disc2 ${isnextbuttonDisabled ? 'button-disabled' : ''}`}
									disabled={isnextbuttonDisabled}>
										Continuar
									</Button>
								</div>
							</div>
							</>}
						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default OutsideCanada
