/* eslint-disable react/prop-types */
import {useContext, useEffect} from 'react'

import {Button} from 'Components'
import {IIfs} from '../../../ReactFlow/PropertyPanel/IfPropertyEditor/types'
import {RootStoreContext} from '../../../stores/rootStore'
import {observer} from 'mobx-react'
import {toJS} from 'mobx'

interface IProps {
  nodeId: string
  node: any[]
  data?: any
  codeData: any
}

const IfModule: React.FC<IProps> = ({nodeId, node,  codeData}) => {
  const rootStore = useContext(RootStoreContext)

  const { updateDataNode, setIfResult, nodesStore, increaseEstep, ifResult} =
    rootStore.formBuilderStore

  useEffect(() => {
    let node_form = ''

    Object.keys(codeData).forEach((key) => {
      if (codeData[key].type === 'form') {
        if (toJS(codeData[key].data.id) === toJS(node[0].formId)) {
          node_form = codeData[key].id
        }
      }
    })
    //getNodeData(previousNode.id)
    getNodeData(node_form)
  }, [])

  const getNodeData = (nodeId: string) => {
    const node = nodesStore.find((node: any) => node.id === nodeId)

    validateIf({data: node.data})
    return {...node.data}
  }

  const validateIf = ({data}: any) => {
    let isValid = true

    node.forEach((element: IIfs) => {
      const {variable, value, operator} = element
      console.log(
        '🚀 ~ file: index.tsx ~ line 48 ~ node.forEach ~ variable, value, operator',
        variable,
        value,
        operator
      )

      Object.keys(data).forEach((key: string) => {
        if (key === variable) {
          if (operator === '=') {
            console.log(
              '🚀 ~ file: index.tsx ~ line 59 ~ Object.keys ~ data[key].toLowerCase()',
              data[key].toLowerCase()
            )
            if (data[key].toLowerCase() !== value.toLowerCase()) {
              isValid = false
            }
          } else if (operator === '!=') {
            if (data[key].toLowerCase() === value.toLowerCase()) {
              isValid = false
            }
          } else if (operator === 'in') {
            let contain = false
            value
              .toLowerCase()
              .split(',')
              .forEach((val: string) => {
                console.log(
                  '🚀 ~ file: index.tsx ~ line 48 ~ node.forEach ~ variable, value, operator',
                  variable,
                  value,
                  operator,
                  val
                )
                if (data[key].toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                  contain = true
                }
              })
            isValid = contain
          } else if (operator === 'not in') {
            if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
              isValid = false
            }
          } else if (operator === '>') {
            if (data[key] <= value) {
              isValid = false
            }
          } else if (operator === '<') {
            if (data[key] >= value) {
              isValid = false
            }
          } else if (operator === '>=') {
            if (data[key] < value) {
              isValid = false
            }
          } else if (operator === '<=') {
            if (data[key] > value) {
              isValid = false
            }
          } else if (operator === 'contains') {
            if (data[key].toLowerCase().indexOf(value.toLowerCase()) === -1) {
              isValid = false
            }
          } else if (operator === 'not contains') {
            if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
              isValid = false
            }
          }
        }
      })
    })

    setIfResult(isValid)

    updateDataNode(isValid, nodeId)
    increaseEstep()
  }

  return (
    <div>
      {nodeId}
      {ifResult ? 'true' : 'false'}
      <Button onClick={() => increaseEstep()}>Next</Button>
    </div>
  )
}

export default observer(IfModule)
