import { Table, TableBody, TableCell, TableHead, TableRow} from 'Components'

import {Product} from 'modules/products/_models'
import React from 'react'


interface IListProductProps {
  product: Array<Product>
  onProductClick: (product: Product) => void
  defaultProduct: Product | null
}

const ListProduct: React.FC<IListProductProps> = ({product, defaultProduct, onProductClick}) => {
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(defaultProduct)

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product)
    onProductClick(product)
  }

  return (
    <div>
      <>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {product.map((product) => (
              <TableRow
                selected={selectedProduct?.id === product.id || defaultProduct?.id === product.id}
                key={product.id}
                hover={true}
                onClick={() => handleProductClick(product)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>
                  {selectedProduct?.id === product.id || defaultProduct?.id === product.id ? (
                    <>CheckIcon</>
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    </div>
  )
}

export default ListProduct
