/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculateStudyPermitLang: React.FC<IProps> = ({
	products
}) => {
	const lang = products.find(element => element.contratationProductId === "16")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setCalculatestudyPermitLangDetails,getCalculatestudyPermitLangDetails } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countLang = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {

		if (member.inCanada ==='no' &&
		member.type == FamilyTypes.principal &&
		member.typeOfStudyPermit === "Permiso de estudios programas de idiomas (menos de 6 meses)") {
			countLang++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

	})
	if (lang !== undefined) {
		setCalculatestudyPermitLangDetails({
			id: lang.id,
			productContratationId: lang.contratationProductId,
			description: lang.description,
			totalFee: countLang * lang.fee,
			name: lang.name,
			quantity: countLang,
			fee: lang.price,
			memberInfo:arrayOfMembers

		})
	}

	return (
		<>
			{getCalculatestudyPermitLangDetails().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getCalculatestudyPermitLangDetails().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getCalculatestudyPermitLangDetails().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getCalculatestudyPermitLangDetails().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getCalculatestudyPermitLangDetails().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateStudyPermitLang)
