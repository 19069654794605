import React, { Dispatch, SetStateAction, useEffect } from 'react'

interface IProps {
  country: string
  setWH: Dispatch<SetStateAction<boolean>>
}

const WHCountryChecker: React.FC<IProps> = ({ country, setWH }) => {
  const countryList =
    [
      'Australia',
      'Austria',
      'Belgium',
      'Chile',
      'Costa Rica',
      'Croatia',
      'Czech Republic',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hong Kong',
      'Ireland',
      'Italy',
      'Japan',
      'South Korea',
      'Latvia',
      'Lithuania',
      'Netherlands',
      'New Zealand',
      'Norway',
      'Poland',
      'Portugal',
      'San Marino',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
      'Switzerland',
      'Taiwan',
      'Ukraine',
      'United Kingdom',
      'Andorra',
    ]

    useEffect(() => {
      const isCountryValid = countryList.includes(country);
      setWH(isCountryValid);
    }, [country, setWH, countryList]);

    return null;
  };

export default WHCountryChecker
