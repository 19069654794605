interface ICountry {
  country: string
}
export const ETA: ICountry[] = [
  {country: 'Andorra'},
  {country: 'Australia'},
  {country: 'Austria'},
  {country: 'Bahamas'},
  {country: 'Belgium'},
  {country: 'United Kingdom'},
  /* {country: 'Brazil'}, */
  {country: 'Brunei'},
  {country: 'Bulgaria'},
  {country: 'Chile'},
  {country: 'Croatia'},
  {country: 'Cyprus'},
  {country: 'Czechia'},
  {country: 'Denmark'},
  {country: 'Estonia'},
  {country: 'Finland'},
  {country: 'France'},
  {country: 'Germany'},
  {country: 'Greece'},
  {country: 'Hungary'},
  {country: 'Iceland'},
  {country: 'Ireland'},
  {country: 'Israel'},
  {country: 'Italy'},
  {country: 'Japan'},
  {country: 'South Korea'},
  {country: 'Latvia'},
  {country: 'Liechtenstein'},
  {country: 'Lithuania'},
  {country: 'Luxembourg'},
  {country: 'Malta'},
  /* {country: 'Mexico'}, */
  {country: 'Monaco'},
  {country: 'Netherlands'},
  {country: 'New Zealand'},
  {country: 'Norway'},
  {country: 'Papua New Guinea'},
  {country: 'Poland'},
  {country: 'Portugal'},
  {country: 'Romania'},
  {country: 'Samoa'},
  {country: 'San Marino'},
  {country: 'Singapore'},
  {country: 'Slovakia'},
  {country: 'Slovenia'},
  {country: 'Solomon Islands'},
  {country: 'Spain'},
  {country: 'Sweden'},
  {country: 'Switzerland'},
  {country: 'United Arab Emirates'},
  {country: 'Vatican City'},
]
