/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { WorkingHoliday } from 'FormBuilder/components/FamilyModule/Data/WorkingHolidayCountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee: React.FC<IProps> = ({
	products
}) => {
	const workingHoliday = products.find(element => element.contratationProductId === "93")
	const rootStore = useContext(RootStoreContext)
	const { familyMembers, setLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee, getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee, principalUsed } = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (principalUsed()?.purposeVisit === "Trabajo" &&
			principalUsed()?.labourMarketImpactAssessment === "si" &&
			principalUsed()?.typeOfClosedWorkPermit === "International Experience Canada work permit"
			&& (principalUsed()?.typeOfWorkPermit === "Postulación a Young Professionals" ||
				principalUsed()?.typeOfWorkPermit === "Postulación a International CoOp")
		) {
			if ((member.type === FamilyTypes.principal
				&& WorkingHoliday.find(
					(holidays) => holidays.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
			)) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
		if (member.type == FamilyTypes.principal &&
			member.wantValidationLmiaExemptJobOffer === "si") {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (workingHoliday !== undefined) {
		setLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee({
			id: workingHoliday.id,
			productContratationId: workingHoliday.contratationProductId,
			description: workingHoliday.description,
			totalFee: countwp * workingHoliday.fee,
			name: workingHoliday.name,
			quantity: countwp,
			fee: workingHoliday.price,
			memberInfo: arrayOfMembers
		})
	}
	return (
		<>
			{getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee)
