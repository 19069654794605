import tw from 'tailwind-styled-components'

const Box = tw.div`
    flex
    flex-col
    w-full
    h-full
    justify-between
    bg-white
`

export {Box}
