import { Span, TableCell } from 'Components'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculatExpiredPermit: React.FC<IProps> = ({ products }) => {
	const rootStore = useContext(RootStoreContext)
	const ExpiredPermit = products?.find(element => element.contratationProductId === "150")
	
	const { familyMembers, setExpiredPermit, getExpiredPermit, principalUsed } = rootStore.familyStore
	const auxPrice = 400

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []

	familyMembers.map((member) => {

	
		if (principalUsed()?.inCanada === "si" && 
		(member.ExpiredPermit === "Expired Permit" || member.ExpiredPermit90 === "Expired Permit90") &&
		member.purposeVisit !== "Visa Renovation") {
		 {
				
	
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca david")
				arrayOfMembers.push(memberInfo)
			}
		}
		if (principalUsed()?.inCanada === "si" && member.ExpiredPermit === "Expired Permit" || member.ExpiredPermit90 === "Expired Permit90" && member.purposeVisit !== "Visa Renovation"  ) {
			if (ExpiredPermit !== undefined ) {
			
					setExpiredPermit({
						id: ExpiredPermit.id,
						productContratationId: ExpiredPermit.contratationProductId,
						description: ExpiredPermit.description,
						totalFee: auxPrice,
						name: ExpiredPermit.name,
						quantity: 1,
						fee: ExpiredPermit.fee,
						memberInfo: arrayOfMembers,
						type: 'gf'
					})
					console.log("pasa por aca david")
				}
			}
			
	})

	
	

	return (
		<>
			{getExpiredPermit().quantity > 0 && (<>
				<TableCell style={{}}>
					<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
					{getExpiredPermit().name}
				</TableCell>
				<TableCell style={{ display: 'flex' }}>
					<Span>Descripcion</Span>{' '}<Description>{getExpiredPermit().description}</Description>
				</TableCell>
				<TableCell style={{}}>
					{' '}
					<Span>Cantidad </Span>
					{getExpiredPermit().quantity}
				</TableCell>
				<TableCell className='border-b border-indigo-500'>
					{' '}
					<Span>Precio </Span>
					CAD {formatter.format(getExpiredPermit().totalFee)}
				</TableCell>
				{console.log("pasa por aca david")}
			</>)}
		</>
	)
}

export default observer(CalculatExpiredPermit)