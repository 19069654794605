import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'

import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculatePartnerOpenWorkSpouse: React.FC<IProps> = ({
	products
}) => {
	const spouseWorkPermit = products.find(element => element.contratationProductId === "13")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setPartnerOpenWorkSpouse, getPartnerOpenWorkSpouse, principalUsed } =
		rootStore.familyStore

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {
		if (member.wantsBP === "si" || member.wantsBP === "no") { member.wantsBP = "no" }
		if (principalUsed()?.inCanada === 'no' &&
			member.type === FamilyTypes.principal &&
			principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' &&
			(principalUsed()?.wantsBP === 'si' ||
				principalUsed()?.wantsBP === 'no' ||
				principalUsed()?.wantsBP === undefined)) {
			counter++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
			principalUsed()?.languageRequired === 'no' &&
			principalUsed()?.scenery === 'Escenario 1') ||
			(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
				principalUsed()?.languageRequired === 'no' &&
				principalUsed()?.scenery === 'Escenario 1')) {
			if (member.type == FamilyTypes.conyuge) {
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if ((principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía tradicional" &&
		principalUsed()?.languageRequired === 'si') ||
		(principalUsed()?.typeOfApplicationOfStudyPermit === "Aplicar por la vía express (Student Direct Stream)" &&
		principalUsed()?.languageRequired === 'si')) {
			if (member.type == FamilyTypes.conyuge) {
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}

		if (principalUsed()?.purposeVisit === "Trabajo") {
			if ((member.type === FamilyTypes.conyuge
				/* && (ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())) === undefined)
				||
				((member.type === FamilyTypes.conyuge
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
					&& member.wantsBP === "no"))
				||
				(((member.type === FamilyTypes.conyuge
					&& ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === member.countryOfCitizenship?.toLocaleLowerCase())
					&& (ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === principalUsed()?.countryOfCitizenship?.toLocaleLowerCase())) === undefined))
				 */)) {
				counter++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
			}
		}
	})
	if (spouseWorkPermit !== undefined) {
		setPartnerOpenWorkSpouse({
			id: spouseWorkPermit.id,
			productContratationId: spouseWorkPermit.contratationProductId,
			description: spouseWorkPermit.description,
			totalFee: counter * spouseWorkPermit.fee,
			name: spouseWorkPermit.name,
			quantity: counter,
			fee: spouseWorkPermit.price,
			memberInfo: arrayOfMembers
		})
	}

	return (
		<>
			{getPartnerOpenWorkSpouse().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPartnerOpenWorkSpouse().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getPartnerOpenWorkSpouse().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getPartnerOpenWorkSpouse().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPartnerOpenWorkSpouse().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePartnerOpenWorkSpouse)
