/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateSponsorshipApplicationIn: React.FC<IProps> = ({
	products
}) => {
	const Sponsorship = products.find(element => element.contratationProductId == "70")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSponsorshipApplicationIn, getSponsorshipApplicationIn, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		/* if (principalUsed()?.inCanada === "si" && member.type===FamilyTypes.principal && (principalUsed()?.sponsorship === 'spouseOrPartner' || principalUsed()?.sponsorship === 'spousePartnerShildren' )) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}

		if (principalUsed()?.inCanada === "si" && member.type===FamilyTypes.principal && principalUsed()?.province === 'Quebec' && (principalUsed()?.sponsorship === 'spouseOrPartner' || principalUsed()?.sponsorship === 'spousePartnerShildren' ) && principalUsed()?.addCSQ === 'si'   ) {
			countwp++
			const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
} */

if (((principalUsed()?.phase==='fase2' && principalUsed()?.province==='Quebec') || principalUsed()?.phase==='ambas'|| principalUsed()?.province!=='Quebec' )&& member.inCanada==="si"  && member.type===FamilyTypes.principal  && principalUsed()?.purposeVisit === "Residencia Permanente"  && principalUsed()?.typeOfApplication==="Patrocinio familiar") {
	countwp++
	const memberInfo = {
		"memberName": member.name + " " + member.lastname,
		"memberType": member.type
	}
	arrayOfMembers.push(memberInfo)
}
if (((principalUsed()?.phase==='fase2' && principalUsed()?.province==='Quebec') || principalUsed()?.phase==='ambas' || principalUsed()?.province!=='Quebec') && member.type===FamilyTypes.conyuge  && principalUsed()?.inCanada==="si" && principalUsed()?.purposeVisit === "Residencia Permanente" && principalUsed()?.typeOfApplication==="Patrocinio familiar") {
countwp++
const memberInfo = {
	"memberName": member.name + " " + member.lastname,
	"memberType": member.type
}
arrayOfMembers.push(memberInfo)
}
	})

	
	if (Sponsorship !== undefined) {
		setSponsorshipApplicationIn({
			id: Sponsorship.id,
			productContratationId: Sponsorship.contratationProductId,
			description: Sponsorship.description,
			totalFee: countwp * Sponsorship.fee,
			name: Sponsorship.name,
			quantity: countwp,
			fee: Sponsorship.price,
			memberInfo:arrayOfMembers
		})
	}
	return (
		<>
			{getSponsorshipApplicationIn().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSponsorshipApplicationIn().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSponsorshipApplicationIn().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getSponsorshipApplicationIn().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSponsorshipApplicationIn().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSponsorshipApplicationIn)
