/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateDependantChildIndependentSponsor: React.FC<IProps> = ({
	products
}) => {
	const dependantChild = products.find(element => element.contratationProductId == "104")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setdependantChildIndependentSponsor, getdependantChildIndependentSponsor, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member,index) => {
		if (((principalUsed()?.phase==='fase2' && principalUsed()?.province==='Quebec') || principalUsed()?.phase==='ambas'|| principalUsed()?.province!=='Quebec' )  && member.type===FamilyTypes.principal  && principalUsed()?.purposeVisit === "Residencia Permanente" &&  member.sponsorship === 'minorchildren') {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		}
	})
	if (dependantChild !== undefined) {
		setdependantChildIndependentSponsor({
			id: dependantChild.id,
			productContratationId: dependantChild.contratationProductId,
			description: dependantChild.description,
			totalFee: countwp * dependantChild.fee,
			name: dependantChild.name,
			quantity: countwp,
			fee: dependantChild.price,
			memberInfo:arrayOfMembers,
            type:'gf'
		})
	}
	return (
		<>
			{getdependantChildIndependentSponsor().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getdependantChildIndependentSponsor().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getdependantChildIndependentSponsor().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getdependantChildIndependentSponsor().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getdependantChildIndependentSponsor().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependantChildIndependentSponsor)
