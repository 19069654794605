/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateSponsorshipApplicationOut: React.FC<IProps> = ({
	products
}) => {
	const Sponsorship = products.find(element => element.contratationProductId == "71")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setSponsorshipApplicationOut, getSponsorshipApplicationOut, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwp = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
	/* 	if (principalUsed()?.inCanada === "no" && member.type===FamilyTypes.principal && (principalUsed()?.sponsorship === 'spouseOrPartner' || principalUsed()?.sponsorship === 'spousePartnerShildren' )) {
				countwp++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
		} */

		if ((member.phase==='fase2'  || member.province!=="Quebec" ||  member.phase==='ambas') &&  member.inCanada==="no" && member.typeOfApplication === 'Patrocinio familiar') {
			countwp++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
	}
	    if  ((principalUsed()?.phase==='fase2'  || principalUsed()?.province!=="Quebec" ||  principalUsed()?.phase==='ambas') &&   principalUsed()?.inCanada==="no" &&  principalUsed()?.inCanada==="no" && member.type===FamilyTypes.conyuge &&   principalUsed()?.typeOfApplication === 'Patrocinio familiar' && principalUsed()?.sponsorship === 'parents') {
		countwp++
		const memberInfo = {
			"memberName": member.name + " " + member.lastname,
			"memberType": member.type
		}
		arrayOfMembers.push(memberInfo)
    }
	})
	if (Sponsorship !== undefined) {
		setSponsorshipApplicationOut({
			id: Sponsorship.id,
			productContratationId: Sponsorship.contratationProductId,
			description: Sponsorship.description,
			totalFee: countwp * Sponsorship.fee,
			name: Sponsorship.name,
			quantity: countwp,
			fee: Sponsorship.price,
			memberInfo:arrayOfMembers

		})
	}
	return (
		<>
			{getSponsorshipApplicationOut().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getSponsorshipApplicationOut().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getSponsorshipApplicationOut().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getSponsorshipApplicationOut().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getSponsorshipApplicationOut().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateSponsorshipApplicationOut)
