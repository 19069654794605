/* eslint-disable no-empty-pattern */
import { FamilyTypes} from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculatePermanentResidenceSponsorshipFederalFeeDepentantChildIncluded : React.FC<IProps> = ({
	products
}) => {
	const dependant = products.find(element => element.contratationProductId === "103")

	const rootStore = useContext(RootStoreContext)

	const { familyMembers,setPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded,getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded ,principalUsed} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let counter = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
        if(principalUsed()?.sponsorship==='spouseOrPartner' && member.type===FamilyTypes.children &&( (principalUsed()?.phase==='fase2' ||principalUsed()?.phase==='ambas')  || principalUsed()?.province!=='Quebec')){
        counter++
		const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo)
        }

	})
	if (dependant !== undefined) {
		setPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded({
			id: dependant.id,
			productContratationId: dependant.contratationProductId,
			description: dependant.description,
			totalFee: counter * dependant.fee,
			name: dependant.name,
			quantity: counter,
			fee: dependant.price,
			memberInfo:arrayOfMembers,
			type:'gf'
		})
	}

	return (
		<>
			{getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePermanentResidenceSponsorshipFederalFeeDepentantChildIncluded)
