interface ICountry {
  country: string
}
export const YoungProfessionals: ICountry[] = [
  {country: 'Australia'},
  {country: 'Austria'},
  {country: 'Chile'},
  {country: 'Costa Rica'},
  {country: 'Croatia'},
  {country: 'Czechia'},
  {country: 'Estonia'},
  {country: 'France'},
  {country: 'Germany'},
  {country: 'Greece'},
  {country: 'Ireland'},
  {country: 'Italy'},
  {country: 'Latvia'},
  {country: 'Lithuania'},
  {country: 'Luxembourg'},
  {country: 'Mexico'},
  {country: 'New Zealand'},
  {country: 'Norway'},
  {country: 'Poland'},
  {country: 'Portugal'},
  {country: 'Slovakia'},
  {country: 'Slovenia'},
  {country: 'Spain'},
  {country: 'Ukraine'},
  {country: 'Sweden'},
  {country: 'Swiss'},
]
